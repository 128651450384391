import React, { useState, useEffect } from "react";

//import componentes y vistas
import { Form, Row, Col, Button } from "react-bootstrap";

//import elmentos de react
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

import ModalGeneral from "../../components/modalGeneral/modalGeneral";

// import axios from 'axios';
import { POST_AXIOS, GET_AXIOS } from "./../../components/funtionsREST";

//import imagenes
import "./formFinalSelfManagement.scss";
import Pdf from "react-to-pdf";

export interface Props {
  //   history: any;
  //   rol: any;
  refToPrint: React.RefObject<HTMLDivElement>;
}

export default function FormFinalSelfManagement(props: Props) {
  const initialFetchedDataState = {
    teacher_name: "",
    teacher_national_id: "",
    grade_id: "",
    grade_name: "",
    knowledge_area_id: "",
    knowledge_area_name: "",
    conclusion: "",
    accompaniment_description: "",
    accompaniment_approach: "",
    headquarter_id: "",
    headquarter_name: "",
    institution_id: "",
    inst_name: "",
    accompaniment_type_id: "",
    accompaniment_type_name: "",
    serial_code: "",
    town_id: "",
    role_id: "",
    role_name: "",
    town_name: "",
    achievements: "",
    improvement_opportunities: "",
    status: 0,
  };
  //  variable to validate form
  const [validated, setValidated] = useState(false);
  const [code, setCode] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [buttonState, setbuttonState] = useState(0);

  //FormData
  const [fetchedData, setfetchedData] = useState(initialFetchedDataState);

  //Modal
  const [show, setShow] = useState(false);
  const [tittleModal, setTittleModal] = useState("");
  const [textModal, setTextModal] = useState("");

  const sendData = async (status: number) => {
    console.log(status)
    if (status === 3) {
      await GET_AXIOS("users/opCoords");
    }
    const dataToPost = { ...fetchedData };
    dataToPost.status = status;
    const res = await POST_AXIOS("selfManagement/new", dataToPost);
    if (res) {
      setShow(true);
      if (status === 3) {
        setTittleModal("Enviado");
        setTextModal("Información enviada con éxito");
      } else {
        setTittleModal("Guardado");
        setTextModal("Información guardada con éxito");
      }
    } else {
      setShow(true);
      setTittleModal("Error");
      setTextModal(
        "Hubo un error al enviar la información, por favor comunicarse con soporte"
      );
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const form = event.currentTarget;
    console.log(validated);
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      if (buttonState == 3) {
        console.log("entre al if");
        window.print();
        await sendData(5);
      } else {
        sendData(4);
      }
    }

    setValidated(true);
  };

  const handleChange = (event: any) => {
    if (event.target && event.target.id === "code_form") {
      setCode(event.target.value);
    }
  };

  const enableButtons = (event: any) => {
    setbuttonState(event.target.value);
  };

  const handleCodeChange = async (event: any) => {
    const code = event.target.value;
    if (code.length >= 5) {
      const res = await GET_AXIOS("selfManagement/getCurrentInfo/" + code);
      if (res.data) {
        await setfetchedData(res.data);
      } else {
        if (!res) {
          setTittleModal("Información no encontrada");
          setTextModal(
            "No se encuentra información con el código ingresado, es posible que el código no exista o que ya se haya enviado."
          );
          setShow(true);
        }
      }
    }
  };

  const handleConclutionChange = (event: any) => {
    const conclusion = event.target.value;
    const tmpFetchedData = { ...fetchedData };
    tmpFetchedData.conclusion = conclusion;
    setfetchedData(tmpFetchedData);
  };

  const SendNprint = async (printF: any) => {
    console.log(validated);
    if (validated === true) {
      console.log("entre al if");
      window.print();
      await sendData(5);
    }
    setValidated(true);
  };

  const handleModalClose = () => {
    setShow(false);
    setTittleModal("");
    setTextModal("");
    setfetchedData(initialFetchedDataState);
  };

  return (
    <Row className="justify-content-center align-self-center text-center">
      <Col md={10}>
        <Form
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
          className="my-4 af-formSelfManagement"
        >
          <Row className="mb-2">
            {/* Date */}
            <Col
              md={4}
              className="ml-auto d-md-flex justify-content-end pr-md-1 mb-2 mb-md-0"
            >
              <div className="d-flex flex-column align-items-start">
                <b>Fecha y Hora</b>
                <DatePicker
                  selected={startDate}
                  // onChange={(e: any) => handleChange(e)}
                  onChange={(date: any) => handleChange(date)}
                  showTimeSelect
                  timeFormat="HH:mm"
                  id="datetime"
                  timeIntervals={15}
                  className="af-inputs px-3"
                  timeCaption="time"
                  dateFormat="yyyy/MM/dd h:mm aa"
                  disabled={true}
                />
              </div>
            </Col>
            <Col
              md={4}
              className="mr-auto d-flex justify-content-center justify-content-md-start pl-md-1 flex-column align-items-start"
            >
              {/* Code */}
              <b>Código</b>
              <Form.Control
                id="code"
                className="af-inputs"
                placeholder="Código"
                type="text"
                onChange={(e: any) => handleCodeChange(e)}
                style={{ width: "214px" }}
                maxLength={5}
                required
              />
              <Form.Control.Feedback type="invalid">
                Debe llenar el campo
              </Form.Control.Feedback>
            </Col>
          </Row>

          <Row className="mb-2">
            <Col md={6} className="pr-md-1 mb-2 mb-md-0 text-left">
              {/* Municipio */}
              <b>Municipio</b>
              <Form.Control
                id="town"
                className="af-inputs"
                placeholder="Municipio"
                disabled
                type="text"
                value={fetchedData.town_name || ""}
              />
            </Col>
            <Col md={6} className="pl-md-1 text-left">
              {/* Institución */}
              <b>Institución</b>
              <Form.Control
                id="institution"
                className="af-inputs"
                placeholder="Institución"
                disabled
                type="text"
                value={fetchedData.inst_name || ""}
              />
            </Col>
          </Row>

          <Row className="mb-2">
            <Col md={6} className="pr-md-1 mb-2 mb-md-0 text-left">
              {/* Nombre */}
              <b>Nombre Completo</b>
              <Form.Control
                id="nombre"
                className="af-inputs af-mayusName"
                placeholder="Nombre completo"
                disabled
                type="text"
                value={fetchedData.teacher_name || ""}
              />
            </Col>
            <Col md={6} className="pl-md-1 text-left">
              <b>Documento de identidad</b>
              <Form.Control
                id="doc_teacher"
                className="af-inputs"
                placeholder="Documento de identidad"
                type="text"
                disabled
                value={fetchedData.teacher_national_id || ""}
              />
            </Col>
          </Row>



          <Row className="mb-2">
            <Col md={6} className="pr-md-1 text-left">
              {/* Rol */}
              <b>Rol</b>
              <Form.Control
                id="rol"
                className="af-inputs"
                placeholder="Rol"
                disabled
                type="text"
                value={fetchedData.role_name || ""}
              />
            </Col>
            <Col md={6} className="pl-md-1 text-left mb-2">
              <b>Tipo de acompañamiento</b>
              <Form.Control
                id="accompaniment_type"
                className="af-inputs af-12cols"
                placeholder="Tipo de acompañamiento"
                disabled
                type="text"
                value={fetchedData.accompaniment_type_name}
              />
            </Col>
          </Row>
          <Row className="mb-2">

            {fetchedData.grade_name &&
              <Col md={6} className="pr-md-1 text-left">
                <b>Ciclo</b>
                <Form.Control
                  id="ciclo"
                  className="af-inputs"
                  placeholder="Ciclo del estudiante"
                  type="text"
                  disabled
                  value={fetchedData.grade_name || ""}
                />
              </Col>
            }

          </Row>
          <Form.Group className="text-left">
            <b>Descripción de la necesidad de acompañamiento</b>
            <Form.Control
              id="desc"
              className="af-inputs py-3 my-2"
              placeholder="Descripción de la necesidad de acompañamiento"
              disabled
              as="textarea"
              rows={3}
              value={fetchedData.accompaniment_description || ""}
            />
            <b>
              Abordaje de la necesidad de acompañamiento (Incluir fechas y
              acciones realizadas)
            </b>
            <Form.Control
              id="need"
              className="af-inputs py-3 my-2"
              placeholder="Abordaje de la necesidad de acompañamiento (Incluir fechas y acciones realizadas)"
              disabled
              as="textarea"
              rows={3}
              value={fetchedData.accompaniment_approach || ""}
            />
            <b>Logros</b>
            <Form.Control
              id="achievements"
              className="af-inputs py-3 my-2"
              placeholder="Logros"
              disabled
              as="textarea"
              rows={3}
              value={fetchedData.achievements || ""}
            />
            <b>Oportunidades de mejora</b>
            <Form.Control
              id="improvement"
              className="af-inputs py-3 my-2"
              placeholder="Oportunidades de mejora"
              disabled
              as="textarea"
              rows={3}
              value={fetchedData.improvement_opportunities || ""}
            />
            <b>Validación del proceso adelantado - Abordaje de la necesidad de acompañamiento</b>
            <Form.Control
              id="conclusion"
              className="af-inputs py-3 my-2"
              placeholder="Validación del proceso adelantado - Abordaje de la necesidad de acompañamiento"
              onChange={(e: any) => handleConclutionChange(e)}
              required
              as="textarea"
              rows={3}
              value={fetchedData.conclusion || ""}
            />
          </Form.Group>

          <Row>
            <Col md={6}>
              <Form.Control
                custom
                id="state"
                className="af-inputs af-select"
                onChange={(e: any) => enableButtons(e)}
                required
                as="select"
              >
                <option value="" disabled selected>
                  Seleccione un Estado
                </option>
                <option value="2">En trámite</option>
                <option value="3">Finalizado</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Debe seleccionar un campo
              </Form.Control.Feedback>
            </Col>
          </Row>

          <Form.Row className="right-0 justify-content-center align-self-center text-center mt-4">
            <Button
              className="af-buttonCcenterG mx-2"
              type="submit"
              style={{ backgroundColor: "#2665B4" }}
              // eslint-disable-next-line eqeqeq
              disabled={buttonState != 2}
            >
              <h4 className="m-0">Guardar</h4>
            </Button>

            {/*
                 // @ts-ignore */}
            <Pdf targetRef={props.refToPrint} filename="div-blue.pdf">
              {/*
                 // @ts-ignore */}
              {({ toPdf }) => (
                <Button
                  className="af-buttonCcenter mx-2"
                  type="submit"
                  // onClick={() => SendNprint(toPdf)}
                  // eslint-disable-next-line eqeqeq
                  disabled={buttonState != 3}
                >
                  <h4 className="m-0"> Enviar y generar informe</h4>
                </Button>
              )}
            </Pdf>
          </Form.Row>
        </Form>
      </Col>
      {show && (
        <ModalGeneral
          show={show}
          tittle={tittleModal}
          text={textModal}
          handleClose={handleModalClose}
          acceptModal={handleModalClose}
        />
      )}
    </Row>
  );
}
