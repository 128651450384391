import React from "react";
import { InputGroup, FormControl, Button } from "react-bootstrap";

export default function SearchInput() {
  return (
    <InputGroup className="mb-3 rounded-0">
      <FormControl
        placeholder=""
        aria-label=""
        aria-describedby="basic-addon2"
      />
      <InputGroup.Append>
        <Button variant="outline-secondary">Buscar</Button>
      </InputGroup.Append>
    </InputGroup>
  );
}
