import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";

export default function ModalUsers({
  textButton,
  buttonColor,
  titleCard,
  data,
  putData,
}: {
  textButton: string;
  titleCard: string;
  data: any;
  putData: any;
  buttonColor:
    | "link"
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "dark"
    | "light"
    | "outline-primary"
    | "outline-secondary"
    | "outline-success"
    | "outline-danger";
}) {
  const [show, setShow] = useState(false);

  const [dataUser, setDataUser] = useState({
    id: 0,
    resource_name: "titulo",
    resource_url: "url",
    resource_description: "texto",
    thumbnail: "img",
    resource_type_id: 1 /* tipo */,
    knowledge_area_id: 1 /* asignatura */,
    grade_id: 1 /* grado */,
    n: 1,
  });

  /* funciones */
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function sendPutData(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    putData(dataUser);
    handleClose();
  }

  function handleInputChange(event: any) {
    const target = event.target;
    const name = target.name;
    setDataUser({ ...dataUser, [name]: target.value });
  }

  /* -------- */
  useEffect(() => {
    setDataUser(data);
  }, [data]);
  return (
    <>
      <Button className="mb-2" variant={buttonColor} onClick={handleShow} block>
        {textButton}
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{titleCard}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={sendPutData}>
            <Form.Group>
              <Form.Label>Titulo</Form.Label>
              <Form.Control
                type="text"
                placeholder="Titulo..."
                value={dataUser.resource_name}
                name="resource_name"
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group controlId="formTextarea">
              <Form.Label>Texto</Form.Label>
              <Form.Control
                as="textarea"
                rows="3"
                value={dataUser.resource_description}
                name="resource_description"
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group controlId="formFile">
              <Form.Label>Imagen</Form.Label>
              <Form.Control
                type="url"
                placeholder="Url imagen..."
                value={dataUser.thumbnail}
                name="thumbnail"
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group controlId="formUrl">
              <Form.Label>Url</Form.Label>
              <Form.Control
                type="url"
                placeholder="Url..."
                value={dataUser.resource_url}
                name="resource_url"
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group controlId="formAssig">
              <Form.Label>Asignatura</Form.Label>
              <Form.Control
                as="select"
                value={dataUser.knowledge_area_id}
                name="knowledge_area_id"
                onChange={handleInputChange}
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formTipe">
              <Form.Label>Tipo</Form.Label>
              <Form.Control
                as="select"
                value={dataUser.resource_type_id}
                name="resource_type_id"
                onChange={handleInputChange}
              >
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formGrade">
              <Form.Label>Grado</Form.Label>
              <Form.Control
                as="select"
                value={dataUser.grade_id}
                name="grade_id"
                onChange={handleInputChange}
              >
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </Form.Control>
            </Form.Group>

            <Row>
              <Col xs={12} sm={12} md={6} lg={6}>
                <Button className="mb-2" variant="success" type="submit" block>
                  Guardar
                </Button>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <Button
                  className="mb-2"
                  variant="danger"
                  type="button"
                  onClick={handleClose}
                  block
                >
                  Cancelar
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
