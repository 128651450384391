import React from "react";
import "./challengeLearn.scss";

//impport elementos de react
import { Row, Col, Image, Button } from "react-bootstrap";
import { TiHomeOutline } from "react-icons/ti";
import { Link } from "react-router-dom";
import { IoMdArrowDropright } from "react-icons/io";

//import imagenes
import { ReactComponent as AulaIcon } from "./../../img/iconSvg/logoListaLec.svg";
import { ReactComponent as ReportIcon } from "./../../img/iconSvg/logoListaRepor.svg";

import imgLearn_1 from "./../../img/imgLearn_1.png";
import imgLearnIz from "./../../img/imgLearnIz.png";
//import comoponentes y vistas

export interface Props {
  idGrade: any;
  rol: any;
  cc: any;
  grade: any;
  textDescSubject: string;
  userName: any;
}

export default class ChallengeLearn extends React.Component<
  Props,
  { textReport: string; seeButton: boolean; textPage: any }
  > {
  constructor(props: any) {
    super(props);
    this.state = {
      textPage:
        "Recuerde ingresar con su usuario de Universo Educaticvo a Desafíos para la Inclusión.",
      seeButton: true,
      textReport: "",
    };
  }

  componentDidMount() {
    this.textRol(this.props.rol);
    this.textReport(this.props.rol);
  }

  textRol(rol: any) {
    console.log(rol);
    if (rol === "est") {
      this.setState({
        textPage: `Recuerda ingresar con tu documento de identidad ${this.props.cc} a Desafíos para la Inclusión.`,
        seeButton: false,
      });
    }
    if (rol === "acu" || rol === "doce") {
      this.setState({ seeButton: false });
    }
  }

  textReport(rol: any) {
    console.log(rol);

    let newTextReport;
    switch (rol) {
      case "rec":
        newTextReport =
          "Para visualizar el estado y progreso de su institución, en relación con el acceso y uso de los contenidos y recursos, seleccione el ciclo, grupo y área que desea consultar.";
        break;
      case "sec_ed":
        newTextReport =
          "Para visualizar el estado y progreso de las Instituciones Educativas, en relación con el acceso y uso de los contenidos y recursos disponibles en la plataforma, seleccione el municipio, la institución educativa, el grado y el área que desea consultar.";
        break;
      case "doce":
        newTextReport =
          "Para visualizar el estado y progreso de sus estudiantes, en relación con el acceso y uso de los contenidos y recursos, seleccione el ciclo, el grupo y el área que desea consultar.";
        break;
      case "dir_ge":
        newTextReport =
          "Para visualizar el estado y progreso de las Instituciones Educativas, en relación con el acceso y uso de los contenidos y recursos disponibles en la plataforma, seleccione el municipio, la institución educativa, el grado y el área que desea consultar.";
        break;
      case "coor_m":
        newTextReport =
          "A continuación, podrás visualizar los reportes generados por los Gestores TIC.";
        break;
      case "inter_aco":
        newTextReport =
          "Para visualizar el estado y progreso de las Instituciones Educativas, en relación con el acceso y uso de los contenidos y recursos disponibles en la plataforma, seleccione la institución educativa, el grado y el área que desea consultar.";
        break;
      default:
        newTextReport =
          "Para visualizar el estado y progreso de su institución, en relación con el acceso y uso de los contenidos y recursos, seleccione el grado, grupo y área que desea consultar.";
    }

    this.setState({ textReport: newTextReport });
  }

  render() {
    // const {idGrade}=this.props;
    const { seeButton, textPage } = this.state;
    return (
      <Row className="w-100 af-main m-0 pb-5">
        <Col className="d-none d-md-block pl-0 mb-5" md={3}>
          <div className="mt-5">
            <Link
              className="af-buttonHome d-flex justify-content-center p-2"
              to="/chooseContent"
            >
              <TiHomeOutline />
            </Link>
          </div>
          <div className="af-tittle p-2 mt-4 d-flex justify-content-center align-self-center align-middle text-center">
            <p className="m-0">
              Recurso Digital Interactivo {this.props.grade}
            </p>
          </div>
          <p className="af-text p-2">{this.props.textDescSubject}</p>

          {/* muestra el boton de reporte si el usuario no es estudiante ni acudiente */}
          {seeButton && (
            <div>
              {/* ----------texto entre botones del menu ------*/}
              <Col className="text-left w-100 p-1 mt-2" md={12}>
                <h4 className="af-tittleProgress pl-2">Progreso</h4>
                <p className="af-text w-100 text-justify">
                  {this.state.textReport}
                </p>
              </Col>
              {/*------------ Boton de reporte ------------------*/}
              <Link to="/report">
                <Button className="af-list p-2 mt-1 d-flex justify-content-start align-self-start ">
                  <IoMdArrowDropright className="mt-2" />
                  <div className="af-iconReport pr-1">
                    <ReportIcon className="af-list af-icon" />
                  </div>
                  <p className="m-0">Reportes</p>
                </Button>
              </Link>
            </div>
          )}

          {/* muestra una imagen para la interfaz de competencias transversales si el usuario es estudinate  */}
          {/* {!seeButton && (
            <Col>
              <Image className="w-100" src={imgLearnIz}></Image>
            </Col>
          )} */}
        </Col>
        {/* Parte donde se muestra la imagen y el boton */}
        <Col className="w-100 p-0" md={9}>
          <Row className="w-100 m-0 justify-content-center align-self-center align-middle text-center">
            <Col md={9} sm={12}>
              <Col className="w-100 p-0" md={12}>
                <div>
                  <Row className="w-100 m-0 h-100 pt-5">
                    <Col
                      className="justify-content-center align-self-center text-center p-3"
                      md={4}
                    >
                      <div className="af-lineOrange"></div>
                    </Col>
                    <Col md={4}>
                      <h4 className="af-tittleLesson">
                        Desafios para la Inclusión (DPI)
                      </h4>
                    </Col>

                    <Col
                      className="justify-content-center align-self-center text-center p-3"
                      md={4}
                    >
                      <div className="af-lineOrange"></div>
                    </Col>
                  </Row>
                  <Row className="justify-content-center align-self-center text-center">
                    <p className=" p-4">{textPage}</p>
                    <Col md={12}>
                      <Image className="w-50" src={imgLearn_1} />
                    </Col>
                    <p className="af-textComponent p-4">
                      Recuerda ingresar con tu usuario de Universo
                      Educaticvo a Desafíos para la Inclusión.
                    </p>
                    <Col md={9}>
                      <Button
                        className="af-buttonLear w-100"
                        href={
                          process.env.REACT_APP_DPA
                        }
                      >
                        <AulaIcon className="af-imgButtonAula mr-5" />
                        Haz clic aquí para ingresar a DPI.
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Col>
          </Row>
        </Col>
        {/* Cierre de parte donde se muestra el logo y el texto*/}
      </Row>
    );
  }
}
