import React from 'react';

//impport elementos de react
import { Row, Col, Image } from 'react-bootstrap';

//import imagenes
import logoApp from "../../img/logoApp.png";

//import comoponentes y vistas



export default class PageInit extends React.Component< {}> {

    render() {
        //---------------------------------------------------------------------------------
        return (
            <div >
                <Row className="w-100 m-0 h-100 pt-5">
                    <Col className="justify-content-center align-self-center text-center p-3" md={4}>
                        <div className="af-lineOrange"></div>
                    </Col>
                    <Col className=" m-0 p-0" md={4}>
                        <h2 className="af-tittleLesson">Bienvenido</h2>
                    </Col>

                    <Col className="justify-content-center align-self-center text-center p-3" md={4}>
                        <div className="af-lineOrange"></div>
                    </Col>
                </Row>
                <Row className="justify-content-center align-self-center text-center mt-3">
                <Col md={12}>
                    <Image className="w-100" src={logoApp}></Image>
                    </Col>
                    <h3 className="p-0">Por favor seleccione una opción del menú lateral </h3>
                    

                </Row>
            </div>
        )
    }


}