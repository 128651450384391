import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";

import queryString from "query-string";

import LoginPage from "./views/loginPage/loginPage";
import HomePage from "./views/HomePage/homePage";
import IntroductionPage from "./views/introductionPage/introductionPage";
import RegisterPage from "./views/register/register";
import DetailPage from "./views/detailPage/detailPage";
import CompleteSignupPage from "./views/completeSignupPage/completeSignupPage";
import UserAdmin from "./views/UserAdmin/UserAdmin";
import Report from "./components/report/report";
import ChooseContent from "./views/chooseContent/chooseContent";
import ChooseAgent from "./views/chooseAgent/chooseAgent";
import { NavBar } from "./components/navBar/navBar";
import ChangePass from "./views/changePass/changePass";
import RecoverPass from "./views/recoverPass/recoverPass";
import RecoverPassEmail from "./views/recoverPassEmail/recoverPassEmail";
import CallCenter from "./views/callCenter/callCenter";
import CoordinadorForm from "./views/coordinadorForm/coordinadorForm";
import CoordinadorReport from "./views/coordinadorReport/coordinadorReport";
import CallCenterReport from "./views/callCenterReport/callCenterReport";
import Accompaniment from "./views/accompaniment/accompaniment";
import SelfManagement from "./views/selfManagement/selfManagement";
import CoordMunReport from "./views/coordMunReport/coordMunReport";
import CoordOpReport from "./views/coordOpReport/coordOpReport";
import SelectReport from "./views/selectReport/selectReport";
import ReportTeacher from "./components/reportTeacher/reportTeacher";
import Tutorials from "./views/tutorials/tutorials";
import DownloadExcel from "./views/dowloadExcel/dowloadExcel";
//import imagenes
import logoNavBar from "./img/logoNavBar.png";

//impport elementos de react
import { Image } from "react-bootstrap";
import ChooseCoordiOp from "./views/chooseCoordiOp/chooseCoordiOp";
import SelfManagementReport from "./views/selfManagementReport/selfManagementReport";
import FinalSelfManagement from "./views/finalSelfManagement/finalSelfManagement";
import FinalSelfManagementReport from "./views/finalSelfManagementReport/finalSelfManagementReport";

function RouterPage() {
  const initialState = {
    national_id: undefined,
    id: undefined,
    role: "",
  };
  const [userData, setuserData] = useState(initialState);

  let location = useLocation();
  let HISTORY = useHistory();

  const [role, setRole] = useState("teacher");
  const [json, setJson] = useState({
    first_name: "",
    second_name: "",
    first_surname: "",
    second_surname: "",
    national_id: "",
    username: "",
    email: "",
    phone_contact: "",
    password: "",
    role_id: "",
    headquarter_id: "",
    grades: [],
  });

  const getDataUser = (e: any) => {
    setuserData(e);
  };

  const resetDataUser = () => {
    setuserData(initialState);
  };

  const needsRedirect: any = () => {
    let valueQuery = queryString.parse(location.search);
    if (valueQuery.alitc) {
      return <Redirect to="/loginPage" />;
    } else if (valueQuery.forgot) {
      return <Redirect to="/recoverPassEmail" />;
    } else if (valueQuery.token) {
      return <Redirect to="/recoverPass" />;
    } else if (valueQuery.register) {
      return <Redirect to="/register" />;
    }
  };

  const PrivateRoute = ({ Component, path }: { Component: any; path: any }) => {
    console.log("PrivateRoute -> props", path);
    return (
      <Route
        exact
        {...path}
        render={(props) =>
          userData.id !== undefined ? (
            <Component {...props} />
          ) : (
              <Redirect to="/" />
            )
        }
      />
    );
  };
  return (
    <Router>
      {needsRedirect()}
      <div className="App">
        <Image className="logo-print" src={logoNavBar} />

        <NavBar user={userData} isLogout={resetDataUser} />
        <Switch>
          <PrivateRoute path="/detailPage" Component={DetailPage} />
          <PrivateRoute
            path="/introductionPage"
            Component={(props: any) => (
              <IntroductionPage
                {...props}
                rol={userData.role !== "" ? userData.role : ""}
                user={userData}
                cc={userData.national_id !== "" ? userData.national_id : ""}
              />
            )}
          />
          <PrivateRoute
            path="/chooseContent"
            Component={(props: any) => (
              <ChooseContent
                {...props}
                rol={userData.role !== "" ? userData.role : ""}
              />
            )}
          />
          <PrivateRoute
            path="/chooseAgent"
            Component={(props: any) => (
              <ChooseAgent
                {...props}
                rol={userData.role !== "" ? userData.role : ""}
              />
            )}
          />
          <PrivateRoute
            path="/accompaniment"
            Component={(props: any) => (
              <Accompaniment
                {...props}
                rol={userData.role !== "" ? userData.role : ""}
              />
            )}
          />
          <PrivateRoute
            path="/selfmanagment"
            Component={(props: any) => (
              <SelfManagement
                {...props}
                rol={userData.role !== "" ? userData.role : ""}
              />
            )}
          />
          <PrivateRoute
            path="/coordinatorForm"
            Component={(props: any) => (
              <CoordinadorForm
                {...props}
                rol={userData.role !== "" ? userData.role : ""}
              />
            )}
          />
          <PrivateRoute
            path="/coordinatorOp"
            Component={(props: any) => (
              <ChooseCoordiOp
                {...props}
                rol={userData.role !== "" ? userData.role : ""}
              />
            )}
          />

          <PrivateRoute
            path="/finalSelfManagementForm"
            Component={(props: any) => (
              <FinalSelfManagement
                {...props}
                rol={userData.role !== "" ? userData.role : ""}
              />
            )}
          />
          <Route exact path="/finalSelfManagementReport">
            <FinalSelfManagementReport />
          </Route>

          <Route exact path="/tutorial">
            <Tutorials />
          </Route>

          <Route path="/register">
            <RegisterPage setRole={setRole} setJson={setJson} />
          </Route>
          <Route exact path="/loginPage">
            <LoginPage getDataUser={getDataUser} />
          </Route>

          {/* <PrivateRoute
            path="/callcenter"
            Component={(props: any) => (
              <CallCenter
                {...props}
                rol={userData.role !== "" ? userData.role : ""}
              />
            )}
          />
          /> */}

          <Route exact path="/callcenter">
            <CallCenter />
          </Route>

          {/* <PrivateRoute
            path="/coordinadorReport"
            Component={(props: any) => (
              <CoordinadorReport
                {...props}
                rol="DOCENTE"
              />
            )}
          /> */}
          <Route exact path="/coordinadorReport">
            <CoordinadorReport />
          </Route>
          <Route exact path="/callcenterReport">
            <CallCenterReport />
          </Route>
          <Route exact path="/coordinadorMunReport">
            <CoordMunReport />
          </Route>
          <Route exact path="/coordinadorOpReport">
            <CoordOpReport />
          </Route>

          <Route exact path="/selfManagementReport">
            <SelfManagementReport />
          </Route>

          <Route path="/completeSignup">
            <CompleteSignupPage role={"teacher"} json={json} />
          </Route>
          <PrivateRoute
            path="/report"
            Component={(props: any) => (
              <Report
                {...props}
                rol={userData.role !== "" ? userData.role : ""}
              />
            )}
          />
          <PrivateRoute
            path="/reportteacher"
            Component={(props: any) => (
              <ReportTeacher
                {...props}
                rol={userData.role !== "" ? userData.role : ""}
              />
            )}
          />
          <Route path="/changePass">
            <ChangePass />
          </Route>
          <Route path="/recoverPass">
            <RecoverPass
              token={("" + queryString.parse(location.search).token).replace(
                " ",
                "+"
              )}
            />
          </Route>
          <PrivateRoute
            path="/selectreports"
            Component={(props: any) => (
              <SelectReport
                {...props}
                rol={userData.role !== "" ? userData.role : ""}
              />
            )}
          />
          {/* <Route path="/selectreports">
            <SelectReport />
          </Route> */}
          <Route path="/recoverPassEmail">
            <RecoverPassEmail />
          </Route>
          <Route path="/user">
            <UserAdmin />
          </Route>
          <Route path="/downloadExcel">
            <DownloadExcel />
          </Route>
          <Route path="/">
            <HomePage />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default RouterPage;
