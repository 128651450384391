import React from "react";
import "./homePage.scss";

//Import elementos de react
import ReactPlayer from "react-player";
import { Button, Form, Image, Row, Col, Carousel } from "react-bootstrap";

//import imagenes
import imgResource from "./../../img/imgResource.png";
import decorationBacground from "../../img/decorationBacground.png";
import decorationBacgroundUpper from "../../img/decorationBacgroundUpper.png"
import pointA from "../../img/pointA.png";
import pointB from "../../img/pointB.png";
import pointC from "../../img/pointC.png";
import logoContact1 from "../../img/logoContact1.png";
import logoContact2 from "../../img/logoContact2.png";
// import logoContact3 from '../../img/logoContact3.png'
import logoContact4 from "../../img/logoContact4.png";
// import imgRanking from "../../img/imgRanking.png";

//import componentes y vistas
import CardNews from "../../components/CardNews/CardNews";
import FootPages from "../../components/footPage/footPage";
import { GET_WORDPRESS, GET_AXIOS } from "../../components/funtionsREST";
import LoadingPage from "../../components/spinner/loadingPage";

//news
// import new1 from "../../img/news/Testimonio1.png";
// import new2 from "../../img/news/Testimonio2.png";
// import new3 from "../../img/news/Testimonio3.png";
// import new4 from "../../img/news/Testimonio4.png";
// import new5 from "../../img/news/Testimonio5.png";
// import new6 from "../../img/news/Testimonio6.png";
// import new7 from "../../img/news/Testimonio7.png";
// import new8 from "../../img/news/Testimonio8.png";
// import new9 from "../../img/news/Testimonio9.png";
// import new10 from "../../img/news/Testimonio10.png";
// import new11 from "../../img/news/Testimonio11.png";
// import new12 from "../../img/news/Testimonio12.png";
// import new13 from "../../img/news/Testimonio13.png";
// import new14 from "../../img/news/Testimonio14.png";
// import new15 from "../../img/news/Testimonio15.png";
// import new16 from "../../img/news/Testimonio16.png";
// import new17 from "../../img/news/Testimonio17.png";
import new15 from "../../img/news/banner1.png";
import new16 from "../../img/news/banner2.png";
import new17 from "../../img/news/banner3.png";

import bannerD1 from "../../img/Desafios/BannerD1.png";
import bannerD2 from "../../img/Desafios/BannerD2.png";
import bannerD3 from "../../img/Desafios/BannerD3.png";
import bannerD4 from "../../img/Desafios/BannerD4.png";
import bannerD5 from "../../img/Desafios/BannerD5.png";
import bannerD6 from "../../img/Desafios/BannerD6.png";
import bannerD7 from "../../img/Desafios/BannerD7.png";
import bannerD8 from "../../img/Desafios/BannerD8.png";
import bannerD9 from "../../img/Desafios/BannerD9.png";
import bannerD10 from "../../img/Desafios/BannerD10.png";
import bannerD11 from "../../img/Desafios/BannerD11.png";
import bannerD12 from "../../img/Desafios/BannerD12.png";
import bannerD13 from "../../img/Desafios/BannerD13.png";
import bannerD14 from "../../img/Desafios/BannerD14.png";
import bannerD15 from "../../img/Desafios/BannerD15.png";

export default class HomePage extends React.Component<
  {},
  { news: any; resources: any, desafios: any }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      news: [
        {
          desc:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsam alias ex quas magnam molestias esse laboriosam veritatis?",
          link: "Videos",
          img: "https://i.blogs.es/cedc3b/istock-179247622/450_1000.jpg",
        },
        {
          desc:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsam alias ex quas magnam molestias esse laboriosam veritatis?",
          link: "Videos",
          img: "https://i.blogs.es/cedc3b/istock-179247622/450_1000.jpg",
        },
        {
          desc:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsam alias ex quas magnam molestias esse laboriosam veritatis?",
          link: "Videos",
          img: "https://i.blogs.es/cedc3b/istock-179247622/450_1000.jpg",
        },
        {
          desc:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsam alias ex quas magnam molestias esse laboriosam veritatis?",
          link: "Videos",
          img: "https://i.blogs.es/cedc3b/istock-179247622/450_1000.jpg",
        },
        {
          desc:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsam alias ex quas magnam molestias esse laboriosam veritatis?",
          link: "Videos",
          img: "https://i.blogs.es/cedc3b/istock-179247622/450_1000.jpg",
        },
        {
          desc:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsam alias ex quas magnam molestias esse laboriosam veritatis?",
          link: "Videos",
          img: "https://i.blogs.es/cedc3b/istock-179247622/450_1000.jpg",
        },
        {
          desc:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsam alias ex quas magnam molestias esse laboriosam veritatis?",
          link: "Videos",
          img: "https://i.blogs.es/cedc3b/istock-179247622/450_1000.jpg",
        },
      ],
      resources: [
        // {
        //   featured_image: { large: new1, medium: new1 },
        // },
        // {
        //   featured_image: { large: new2, medium: new2 },
        // },
        // {
        //   featured_image: { large: new3, medium: new3 },
        // },
        // {
        //   featured_image: { large: new4, medium: new4 },
        // },
        // {
        //   featured_image: { large: new1, medium: new5 },
        // },
        // {
        //   featured_image: { large: new2, medium: new6 },
        // },
        // {
        //   featured_image: { large: new3, medium: new7 },
        // },
        // {
        //   featured_image: { large: new4, medium: new8 },
        // },
        // {
        //   featured_image: { large: new1, medium: new9 },
        // },
        // {
        //   featured_image: { large: new2, medium: new10 },
        // },
        // {
        //   featured_image: { large: new3, medium: new11 },
        // },
        // {
        //   featured_image: { large: new4, medium: new12 },
        // }, {
        //   featured_image: { large: new1, medium: new13 },
        // },
        // {
        //   featured_image: { large: new2, medium: new14 },
        // }
        {
          featured_image: { large: new15, medium: new15 },
        }, {
          featured_image: { large: new16, medium: new16 },
        },
        {
          featured_image: { large: new17, medium: new17 },
        }
      ],
      desafios: [
        {
          title: "Desafío 1",
          link: "#",
          featured_image: { large: bannerD1, medium: bannerD1 },
          data: "Información adicional sobre Desafío 1"
        },
        {
          title: "Desafío 2",
          link: "#",
          featured_image: { large: bannerD2, medium: bannerD2 },
          data: "Información adicional sobre Desafío 2"
        },
        {
          title: "Desafío 3",
          link: "#",
          featured_image: { large: bannerD3, medium: bannerD3 },
          data: "Información adicional sobre Desafío 3"
        },
        {
          title: "Desafío 4",
          link: "#",
          featured_image: { large: bannerD4, medium: bannerD4 },
          data: "Información adicional sobre Desafío 4"
        },
        {
          title: "Desafío 5",
          link: "#",
          featured_image: { large: bannerD5, medium: bannerD5 },
          data: "Información adicional sobre Desafío 5"
        },
        {
          title: "Desafío 6",
          link: "#",
          featured_image: { large: bannerD6, medium: bannerD6 },
          data: "Información adicional sobre Desafío 6"
        },
        {
          title: "Desafío 7",
          link: "#",
          featured_image: { large: bannerD7, medium: bannerD7 },
          data: "Información adicional sobre Desafío 7"
        },
        {
          title: "Desafío 8",
          link: "#",
          featured_image: { large: bannerD8, medium: bannerD8 },
          data: "Información adicional sobre Desafío 8"
        },
        {
          title: "Desafío 9",
          link: "#",
          featured_image: { large: bannerD9, medium: bannerD9 },
          data: "Información adicional sobre Desafío 9"
        },
        {
          title: "Desafío 10",
          link: "#",
          featured_image: { large: bannerD10, medium: bannerD10 },
          data: "Información adicional sobre Desafío 10"
        },
        {
          title: "Desafío 11",
          link: "#",
          featured_image: { large: bannerD11, medium: bannerD11 },
          data: "Información adicional sobre Desafío 11"
        },
        {
          title: "Desafío 12",
          link: "#",
          featured_image: { large: bannerD12, medium: bannerD12 },
          data: "Información adicional sobre Desafío 12"
        },
        {
          title: "Desafío 13",
          link: "#",
          featured_image: { large: bannerD13, medium: bannerD13 },
          data: "Información adicional sobre Desafío 13"
        },
        {
          title: "Desafío 14",
          link: "#",
          featured_image: { large: bannerD14, medium: bannerD14 },
          data: "Información adicional sobre Desafío 14"
        },
        {
          title: "Desafío 15",
          link: "#",
          featured_image: { large: bannerD15, medium: bannerD15 },
          data: "Información adicional sobre Desafío 15"
        },
      ],
    };
  }



  async login() {
    const login_resp = await GET_AXIOS('loginsamlify');

    if (login_resp && login_resp.data) {  // Verifica si login_resp y login_resp.data existen
      window.location = login_resp.data;
    } else {
      console.error("Error: La respuesta no contiene la propiedad 'data'.");
      // Puedes agregar aquí cualquier manejo de error adicional si es necesario
    }
  }

  async componentDidMount() {
    // const data = await GET_WORDPRESS(2);
    // console.log(data);
    // this.setState({ resources: Object.values(data) });
  }

  render() {
    return (
      <div>
        {/* primera seccion  noticias */}
        {/* <NavBar url="/loginPage" name="Ingresar" activeKey="4"></NavBar> */}
        <div className="principal-page-home">
          <Row className="af-header af-background" id="news">
            <Image className="af-backImageUpper" src={decorationBacgroundUpper} />
            <Col
              className="justify-content-center align-self-center text-center py-3"
              md={4}
            >
              <div className="af-lineOrange2"></div>
            </Col>
            <Col
              className="justify-content-center align-self-center text-center pt-5"
              md={4}
            >
              <h3 className="af-titleRedOrange">
                <strong>Noticias de Universo  <br /> EducaTIC-vo</strong>
              </h3>
            </Col>
            <Col
              className="justify-content-center align-self-center text-center py-3"
              md={4}
            >
              <div className="af-lineOrange2"></div>
            </Col>
            <Row className="justify-content-center align-self-center text-center p-0 m-0" >
              <Col className="justify-content-center align-self-center text-center p-0 m-0" md={9}>
                <p>En este espacio encontrarás disponibles noticias relacionadas con el desarrollo de las acciones formativas propias, de la atención educativa a la población de jóvenes extraedad y adultos beneficiarios, del acompañamiento a la implementación del Modelo Educativo Flexible Talentum, en el Valle del Cauca.</p>
              </Col>
            </Row>

            <Col
              className="px-5 justify-content-center align-self-center text-center"
              md={12}
            >
              <Carousel>
                {this.state.resources.map((value: any, i: number) => (
                  <Carousel.Item>
                    <Row className="mb-5">
                      <LoadingPage>
                        <>
                          <Col key={`cardNews${i}`} md={12}>
                            <CardNews
                              title={value.title}
                              link={value.link}
                              img={value.featured_image.medium}
                              data={value}
                            />
                          </Col>
                        </>
                      </LoadingPage>
                    </Row>
                  </Carousel.Item>
                ))}
                {/* <Carousel.Item>
                  <Row className="mb-5">
                    <LoadingPage>
                      <>
                        {this.state.resources
                          .slice(0, 3)
                          .map((value: any, i: number) => (
                            <Col
                              key={`cardNews${i}`}
                              xs={10}
                              sm={8}
                              md={4}
                              lg={4}
                            >
                              <CardNews
                                title={value.title}
                                link={value.link}
                                img={value.featured_image.medium}
                                data={value}
                              />
                            </Col>
                          ))}
                      </>
                    </LoadingPage>
                  </Row>
                </Carousel.Item>
                {this.state.resources.length >= 4 && (
                  <Carousel.Item>
                    <Row className="mb-5">
                      {this.state.resources
                        .slice(3, 6)
                        .map((value: any, i: number) => (
                          <Col
                            key={`cardNews${i}`}
                            xs={10}
                            sm={8}
                            md={4}
                            lg={4}
                          >
                            <CardNews
                              title={value.title}
                              link={value.link}
                              img={value.featured_image.medium}
                              data={value}
                            />
                          </Col>
                        ))}
                    </Row>
                  </Carousel.Item>
                )}
                {this.state.resources.length >= 7 && (
                  <Carousel.Item>
                    <Row className="mb-5">
                      {this.state.resources
                        .slice(6, 9)
                        .map((value: any, i: number) => (
                          <Col
                            key={`cardNews${i}`}
                            xs={10}
                            sm={8}
                            md={4}
                            lg={4}
                          >
                            <CardNews
                              title={value.title}
                              link={value.link}
                              img={value.featured_image.medium}
                              data={value}
                            />
                          </Col>
                        ))}
                    </Row>
                  </Carousel.Item>
                )}
                {this.state.resources.length >= 10 && (
                  <Carousel.Item>
                    <Row className="mb-5">
                      {this.state.resources
                        .slice(9, 12)
                        .map((value: any, i: number) => (
                          <Col
                            key={`cardNews${i}`}
                            xs={10}
                            sm={8}
                            md={4}
                            lg={4}
                          >
                            <CardNews
                              title={value.title}
                              link={value.link}
                              img={value.featured_image.medium}
                              data={value}
                            />
                          </Col>
                        ))}
                    </Row>
                  </Carousel.Item>
                )}
                   {this.state.resources.length >= 10 && (
                  <Carousel.Item>
                    <Row className="mb-5">
                      {this.state.resources
                        .slice(12, 15)
                        .map((value: any, i: number) => (
                          <Col
                            key={`cardNews${i}`}
                            xs={10}
                            sm={8}
                            md={4}
                            lg={4}
                          >
                            <CardNews
                              title={value.title}
                              link={value.link}
                              img={value.featured_image.medium}
                              data={value}
                            />
                          </Col>
                        ))}
                    </Row>
                  </Carousel.Item>
                )} */}
              </Carousel>
            </Col>
          </Row>

          {/* segunda seccion que es alternatic */}
          <Row className="af-body af-background justify-content-center align-self-center text-center py-5"
          >

            <Col
              className="justify-content-center align-self-center text-center py-3"
              md={4}

            >
              <div className="af-line"></div>
            </Col>
            <Col
              className="justify-content-center align-self-center text-center py-3"
              md={4}
            >
              <h3 className="af-tittleHome">
                <strong>¿Qué es Universo <br /> EducaTIC-vo?</strong>
              </h3>
            </Col>
            <Col
              className="justify-content-center align-self-center text-center py-3"
              md={4}
            >
              <div className="af-line"></div>
            </Col>
            <Col
              className="justify-content-center align-self-center my-2"
              md={6}
            >
              <ReactPlayer
                width="100%"
                url="https://youtu.be/R5fGT_qyrxI"
              />
            </Col>
            <Col
              className="justify-content-center align-self-center text-center"
              md={4}
            >
              <p>
                {" "}
                Es una plataforma que ofrece contenidos y recursos educativos,
                tales como videos, actividades interactivas, espacios de
                aprendizaje y en general, una diversidad de materiales
                educativos para la enseñanza y el aprendizaje, articulados a una estrategia de acompañamiento y
                monitoreo que permite reconocer a través de reportes
                individuales, grupales o institucionales, los niveles de acceso
                y uso de la plataforma, por parte de los miembros de la
                comunidad educativa.
              </p>
            </Col>
          </Row>

          {/* tercera seccion */}
          <Row className="justify-content-center align-self-center text-center af-background mb-5">
            <Col
              className="justify-content-center align-self-center text-center py-3"
              md={4}
            >
              <div className="af-lineOrange2"></div>
            </Col>
            <Col
              className="justify-content-center align-self-center text-center pt-5"
              md={4}
            >
              <h3 className="af-titleRedOrange">
                <strong>Nuestro Universo  <br /> EducaTIC-vo</strong>
              </h3>
            </Col>
            <Col
              className="justify-content-center align-self-center text-center py-3"
              md={4}
            >
              <div className="af-lineOrange2"></div>
            </Col>
            <Col
              className="justify-content-center align-self-center text-center"
              md={4}
            >
              <p>
                Está dirigido a docentes, estudiantes y directivos docentes de
                las Instituciones Educativas Oficiales de los 34 municipios no
                certificados del Valle del Cauca, con población de estudiantes
                jóvenes extraedad y adultos beneficiarios, del acompañamiento a
                la implementación del Modelo Educativo Flexible Talentum, en el Valle del Cauca.
              </p>
              <Button
                className="af-buttonres w-50"
                variant="primary"
                onClick={this.login}
              >
                Ingresar
              </Button>
            </Col>
            <Col className="justify-content-center align-self-center" md={6}>

              <Carousel className="vertical-carousel">
                {this.state.desafios.map((value: any, i: number) => (
                  <Carousel.Item key={i}>
                    <Row className="mb-5">
                      <Col
                        md={12}
                        className="d-flex justify-content-center align-items-center"
                      >
                        <img
                          className="d-block"
                          src={value.featured_image.medium}
                          alt={`Slide ${i}`}
                        />
                      </Col>
                    </Row>
                  </Carousel.Item>
                ))}
              </Carousel>
            </Col>
          </Row>

          {/* cuarta seccion benefit*/}
          <Row className="af-benefity af-background py-5">
            <Col
              className="justify-content-center align-self-center text-center py-3"
              md={4}
              style={{ paddingTop: '5vh', paddingBottom: '20vh' }}
            >
              <div className="af-line"></div>
            </Col>
            <Col
              className="justify-content-center align-self-center text-center p-0"
              md={4}
            >
              <h2 className="af-tittleHome">
                <strong>¿Qué aporta Universo <br /> EducaTIC-vo?</strong>
              </h2>
            </Col>
            <Col
              className="justify-content-center align-self-center text-center py-3"
              md={4}
            >
              <div className="af-line"></div>
            </Col>
            <Col
              className="col-md-4 justify-content-center align-self-center"
              md={4}
            >
              <Image className="w-25 img-fluid" src={pointA} />
              <p className="mt-3">
                La posibilidad de complementar los procesos de enseñanza de los
                docentes y los procesos de aprendizaje de los estudiantes, a
                través de la disponibilidad de contenidos y recursos educativos organizados en las categorías de
                Videos, Actividades Interactivas, espacios de aprendizaje (aula
                virtual) y recursos del Portal Educativo
                Colombia Aprende del Ministerio de Educación Nacional,
                articulados a la plataforma.

                Adicionalmente, ofrece el acceso a las siete (7) cartillas “Desafíos para Aprender”, materiales educativos del Modelo Educativo Flexible Talentum.
              </p>
            </Col>
            <Col
              className="col-md-4 justify-content-center align-self-center"
              md={4}
            >
              <Image className="w-25 img-fluid" src={pointB} />
              <p className="mt-3">
                Acompañamiento y soporte tecnopedagógico, posibilitando el
                seguimiento, monitoreo y evaluación de impacto de las
                actividades académicas planteadas por el docente y las
                desarrolladas de manera autónoma por los estudiantes.
              </p>
            </Col>
            <Col
              className="col-md-4 justify-content-center align-self-center"
              md={4}
            >
              <Image className="w-25 img-fluid" src={pointC} />
              <p className="mt-3">
                Diferentes medios y canales de contacto para el acompañamiento y
                soporte a la comunidad educativa.
              </p>
            </Col>
          </Row>

          {/* quinta seccion contact*/}
          <Row className="af-background justify-content-center align-self-center text-center" id="contact">
            <Col
              className="justify-content-center align-self-center text-center py-3"
              md={4}
            >
              <div className="af-lineOrange2"></div>
            </Col>
            <Col
              className="justify-content-center align-self-center text-center pt-5"
              md={4}
            >
              <h3 className="af-titleRedOrange">
                <strong>Nuestros Canales de Comunicación</strong>
              </h3>
            </Col>
            <Col
              className="justify-content-center align-self-center text-center py-3"
              md={4}
            >
              <div className="af-lineOrange2"></div>
            </Col>
            <Row className="justify-content-center align-self-center text-center w-100 p-3">
              <Col md={4} xs={12} style={{ paddingTop: '5vh', paddingBottom: '5vh' }}>
                <Image className="w-25" src={logoContact1} />
                <h6 className="w-100">universoeducaticvo@talentum.edu.co</h6>
                {/* <p className="m-1 af-text text-center">Horario de atención:</p>
                <p className="m-1 af-text text-center">
                  Lunes a sábado de 8 am a 8pm 
                </p>
                <p className="m-1 af-text text-center">Jornada continua</p> */}
              </Col>

              {/* <Col md={4} xs={12}>
                <Image className="w-25" src={logoContact2} />
                <h6 className="m-1">Línea gratuita: 018000196051</h6>
                <p className="m-1 af-text text-center">Horario de atención:</p>
                <p className="m-1 af-text text-center">
                  Lunes a sábado de 8 am a 8pm 
                </p>
                <p className="m-1 af-text text-center">Jornada continua</p>
              </Col> */}

              <Col md={4} xs={12}>
                <Image className="w-25" src={logoContact4} />
                <h6 className="m-1">Fan Page</h6>
                {/* <p className="m-1 af-text text-center">Horario de atención:</p>
                <p className="m-1 af-text text-center">
                  Lunes a sábado de 8 am a 8pm 
                </p>
                <p className="m-1 af-text text-center">Jornada continua</p> */}
              </Col>
            </Row>
            <Row className="af-backContact justify-content-center align-self-center af-background p-5">
              <Col
                className="justify-content-center align-self-center text-center py-3"
                md={4}
              >
                <div className="af-line"></div>
              </Col>
              <Col
                className="justify-content-center align-self-center text-center py-3"
                md={4}
              >
                <h3 className="af-tittleHome">
                  <strong>Escríbenos</strong>
                </h3>
              </Col>
              <Col
                className="justify-content-center align-self-center text-center py-3"
                md={4}
              >
                <div className="af-line"></div>
              </Col>
              <Row className="justify-content-center">
                <Col
                  className="justify-content-center align-self-center text-center mt-0"
                  md={4}
                  style={{ paddingTop: '5vh', paddingBottom: '8vh' }}
                >
                  <div className="af-textContact1 wp-50 mt-lg-0">
                    <p>
                      Tus inquietudes, sugerencias y comentarios son muy
                      importantes para nosotros.
                      <br />
                      <br />
                      Por favor diligencia tus datos y escribe tus comentarios
                      en el siguiente formato.
                      <br />
                      <br />
                      Recibirás noticias nuestras en un plazo máximo de 48
                      horas. <br />
                      <br />
                      Al correo electrónico que digites te enviaremos la
                      respuesta. Por lo tanto, verifica que la dirección esté
                      escrita correctamente antes de enviar tus comentarios.
                    </p>
                  </div>
                </Col>
                <Col
                  className="col-md-6 justify-content-center align-self-center"
                  md={6}

                >
                  <Form>
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Control
                        className="af-form"
                        type="text"
                        placeholder="Escribe tu nombre"
                      />
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlInput2">
                      <Form.Control
                        className="af-form"
                        type="email"
                        placeholder="Escribe tu correo"
                      />
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlInput3">
                      <Form.Control
                        className="af-form"
                        type="text"
                        placeholder="Escribe tu celular"
                      />
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlInput4">
                      <Form.Control
                        className="af-form"
                        type="text"
                        placeholder="Escoge tu rol"
                      />
                    </Form.Group>

                    <Form.Group controlId="exampleForm.ControlTextarea5">
                      <Form.Control
                        className="af-form2"
                        placeholder="Escribe tu mensaje"
                        as="textarea"
                        rows="3"
                      />
                    </Form.Group>
                    <p>* todos los campos son obligatorios</p>
                    <Button className="af-buttonres w-50 p3" variant="primary" >
                      Enviar
                    </Button>
                  </Form>
                </Col>
              </Row>
            </Row>
          </Row>
        </div>
        <FootPages></FootPages>
      </div>
    );
  }
}
