import React from "react";
import { Card, Button, Row, Col } from "react-bootstrap";
import "./CardUsers.scss";

import ModalUser from "../ModalUsers/ModalUsers";

export default function CardUsers({
  title,
  data,
  putData,
}: {
  title: string;
  data: any;
  putData: any;
}) {
  const asignature = ["cero", "uno", "dos"];

  return (
    <Card className="text-center rounded-0 mb-4">
      <Card.Header className="head-Card pt-1 pb-1 pl-4 pr-4">
        <Card.Title>{title}</Card.Title>
        <Card.Subtitle className="text-light mb-0">
          {asignature[data.knowledge_area_id]}
        </Card.Subtitle>
      </Card.Header>
      <Card.Body className="p-0 rounded-0">
        <img
          src="https://via.placeholder.com/180x140"
          className="prueba-imagen"
          alt="cosas"
        />
      </Card.Body>
      <Card.Footer className="text-muted ">
        <p className="text-justify">{data.resource_description}</p>
        <Row className="justify-content-center">
          <Col xs={12} sm={12} md={6} lg={6}>
            <Button className="mb-2" variant="danger" block>
              Eliminar
            </Button>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <ModalUser
              textButton="Editar"
              buttonColor="success"
              titleCard="Editar"
              data={data}
              putData={putData}
            ></ModalUser>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
}
