import React from 'react';
import './activity.scss';

//impport elementos de react
import { Row, Col, Pagination } from 'react-bootstrap';

//import comoponentes y vistas
import SubComponent from '../subComponent/subComponent';
import ZoomResource from '../zoomResources/zoomResources';

//import Imagenes
import activityAzul from '../../img/imgResources/activityAzul.png';
import activityAmar from '../../img/imgResources/activityAmar.png';
import activityAzulO from '../../img/imgResources/activityAzulO.png';
import activityNaran from '../../img/imgResources/activityNaran.png';
import activityVer from '../../img/imgResources/activityVer.png';

export interface Props { Arrayresources: any, grade: any, subject: any }


export default class Activity extends React.Component<Props, { resource_id: any, grade: any, subject: any, url: any, desc: any, dba: any, zoomResources: boolean, imagResourcesActivity: any, resources: any, currentPage: any, elementPage: any }> {


    constructor(props: any) {
        super(props);
        this.state = {
            imagResourcesActivity: [activityAzul, activityAmar, activityAzulO, activityNaran, activityVer],
            resources: this.props.Arrayresources,
            zoomResources: false,
            currentPage: 1,
            elementPage: 6,
            resource_id: -1,
            url: '',
            desc: '',
            dba: '',
            grade: '',
            subject: '',

        };
        this.handleClick = this.handleClick.bind(this);
        this.updateZoomResource = this.updateZoomResource.bind(this);

    }
    componentDidMount() { }

    handleClick(event: any) {
        event.preventDefault();
        this.setState({ currentPage: Number(event.target.id) })
    }

    updateZoomResource(event: any, resource_id: any, url: any, desc: any, dba: any, grade: any, subject: any) {
        console.log(url);
        event.preventDefault();
        this.setState({ zoomResources: !this.state.zoomResources, resource_id: resource_id, url: url, desc: desc, dba: dba, grade: grade, subject: subject })

    }

    render() {
        const { resources, currentPage, elementPage, imagResourcesActivity, resource_id, url, desc, dba, grade, subject } = this.state;

        //-----------------------------Paginación-----------------------------------
        const indexOfLastTodo = currentPage * elementPage;
        const indexOfFirstTodo = indexOfLastTodo - elementPage;
        const currentResources = resources.slice(indexOfFirstTodo, indexOfLastTodo);

        let active = currentPage;
        let numberPages = Math.ceil(this.state.resources.length / this.state.elementPage);
        let items = [];
        for (let number = 1; number <= numberPages; number++) {
            items.push(
                <Pagination.Item key={number} id={number} active={number === active} onClick={this.handleClick}>
                    {number}
                </Pagination.Item>,
            );
        }

        const paginationBasic = (
            <div> <Pagination>{items}</Pagination><br /></div>
        );
        //---------------------------------------------------------------------------------
        return (
            <div >
                <Row className="w-100 m-0 h-100 pt-5">
                    <Col className="justify-content-center align-self-center text-center p-3" md={4}>
                        <div className="af-lineOrange"></div>
                    </Col>
                    <Col className=" m-0 p-0" md={4}>
                        <h2 className="af-tittleLesson">Actividades Interactivas</h2>
                    </Col>

                    <Col className="justify-content-center align-self-center text-center p-3" md={4}>
                        <div className="af-lineOrange"></div>
                    </Col>
                </Row>
                {!this.state.zoomResources ?

                    <Row>
                        <p className="af-textComponent p-4">Son recursos educativos que promueven el intercambio entre el estudiante y las herramientas de aprendizaje, posibilitando la representación significativa del conocimiento. </p>

                        {currentResources.map((resour: any, i: any) => {
                            let indexImg = i >= 5 ? i - 5 : i;
                            return (
                                <Col key={i} md={4}>
                                    <div onClick={(e: any) => this.updateZoomResource(e, resour.id, resour.resource_url, resour.resource_description, resour.dba_name, this.props.grade, this.props.subject)}>
                                        {/* se adapta dependiendo del JSON de la base de datos para: url:resource_url, titulo:resource_name,  desc:dba_name para src:src */}
                                        <SubComponent url={resour.resource_url} tittle={`AI${(i+1)+indexOfFirstTodo}.`} src={imagResourcesActivity[indexImg]} desc={resour.dba_name}></SubComponent>
                                    </div>

                                </Col>
                            )
                        })}

                        {resources.length > 6 &&
                            < Row className="w-100 justify-content-center align-self-center text-center">
                                {paginationBasic}
                            </Row>
                        }

                    </Row>
                    :
                    <div>
                        <ZoomResource updateZoomResource={this.updateZoomResource} resource_id={resource_id} url={url} desc={desc} dba={dba} grade={grade} subject={subject} />
                    </div>

                }
            </div>
        )
    }
}