import React, { useState, useEffect } from "react";
import "./register.scss";

import {
  Form,
  Button,
  Container,
  Row,
  Col,
  FormControl,
  Modal,
  Image,
} from "react-bootstrap";

import FootPages from "../../components/footPage/footPage";
import { AiFillRightCircle } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { POST_AXIOS, GET_AXIOS } from "../../components/funtionsREST";
// import LoadingPage from "../../components/spinner/loadingPage";

import LocationIcon from "./../../img/location_icon.png";
import UserIdIcon from "./../../img/user_id_icon.png";
import BuildingIcon from "./../../img/building_icon.png";
import GradeIcon from "./../../img/grade_icon.png";
import UserIcon from "./../../img/user_icon.png";
import EmailIcon from "./../../img/mail_icon.png";
import PhoneIcon from "./../../img/phone_icon.png";
import PassCheckIcon from "./../../img/pass_check_icon.png";
import PassQuestIcon from "./../../img/pass_question_icon.png";

// import * as passIcon from '../../../public/password.svg';
// const passIcon = require("../../../public/password.svg") as string;

interface Props {
  setRole: any;
  setJson: any;
}

const RegisterPage = ({ setRole, setJson }: Props) => {
  const history = useHistory();
  const [modalShow, setModalShow] = React.useState(false);
  const [idRole, setIdRole] = useState(3); //cambiar a 0 si se vuelve agregar opcion de acudiente
  const [idHeadquarter, setIdHeadquarter] = useState(0);
  const [townId, setTownId] = useState(0);
  const [instId, setInstId] = useState(0);
  const [towns, setTowns] = useState([
    {
      id: 0,
      town_name: "",
    },
  ]);
  const [institutions, setInstitutions] = useState([
    {
      id: 0,
      inst_name: "",
      dane_code: "",
    },
  ]);
  const [headQuarters, setHeadQuarters] = useState([
    {
      id: 0,
      hq_name: "",
      dane_code: "",
      town_id: 0,
      institution_id: 0,
    },
  ]);
  const [grades, setGrades] = useState([
    {
      id: 0,
      grade_name: ""
    }
  ]);
  const [gradesSelected, setGradesSelected] = useState([]);
  // const [json, setJson] = useState({});

  const handleChange = (e: any) => {
    // console.log(e.currentTarget.value)
    setInstitutions([]);
    setHeadQuarters([]);
    setTownId(0);
    setInstId(0);
    setIdHeadquarter(0);
    if (e.currentTarget.value === "Docente") {
      setIdRole(3);
    } else if (e.currentTarget.value === "Acudiente") {
      setIdRole(2);
    }
  };

  const handleChange2 = async (e: any) => {
    let id = e.currentTarget.id;
    let idValue = e.currentTarget.value;
    let response;

    if (id === "institution") {
      response = await getFuntion("headquarters/byInstId/" + idValue);
      setInstId(idValue);
      setHeadQuarters([]);
      setIdHeadquarter(0);
      setHeadQuarters(capitalizeWords(response.data, "hq_name"));
    } else if (id === "instDaneCode") {
      setHeadQuarters([]);
      if (idValue.trim().length === 12) {
        response = await getFuntion(
          "headquarters/byInstitution/" + idValue.trim()
        );
        setIdHeadquarter(0);
        setHeadQuarters(capitalizeWords(response.data, "hq_name"));
      }
    } else if (id === "headq") {
      setIdHeadquarter(idValue);
    } else if (id === "town") {
      response = await getFuntion("institutions/byTown/" + idValue);
      setTownId(idValue);
      setInstitutions([]);
      setInstId(0);
      setIdHeadquarter(0);
      setHeadQuarters([]);
      setIdHeadquarter(0);
      setInstitutions(capitalizeWords(response.data, "inst_name"));
    } else if (id.indexOf("-grades") !== -1) {
      let checked = e.currentTarget.checked;
      let arr: any = gradesSelected;
      if (checked) {
        arr.push(idValue);
      } else {
        const _index = arr.findIndex((el: any) => el === idValue);
        arr.splice(_index, 1);
        setGradesSelected(arr);
      }
    }
  };

  //debe traer los municipios
  useEffect(() => {
    let response = getFuntion("towns/byDepartment/1");
    response.then((res) => {
      setTowns(capitalizeWords(res.data, "town_name"));
    });
    getFuntion("grades").then((res) => {
      const allGrades = capitalizeWords(res.data, "grade_name");
      setGrades(allGrades.slice(0, 12));
    });
  }, []);

  const capitalizeWords = (arr: Array<any>, keyInObj: String) => {
    const toReturn: any = [];
    if (arr) {
      arr.map((el: any) => {
        let word: string = "";
        el[`${keyInObj}`].split(" ").map((wr: any) => {
          if (wr) {
            word += wr[0].toUpperCase() + wr.slice(1) + " ";
          }
        });
        word = word.trim();
        toReturn.push({ id: el.id, [`${keyInObj}`]: word });
      });
    }
    return toReturn;
  };

  const getFuntion = async (url: String) => {
    return await GET_AXIOS(url);
  };

  const postData = async (post: any) => {
    console.log("register", post);
    let response = await POST_AXIOS("users/validateDuplicated", post);
    if (response !== false) {
      return response.data;
    } else {
      return response;
    }
  };

  const submit = async (e: any) => {
    let counter = 0;
    e.preventDefault();
    let elements = e.currentTarget.elements;

    for (const key in elements) {
      if (
        elements[key].value === "" &&
        elements[key].className !== "btn btn-primary"
      ) {
        //console.log(elements[key])
        alert("Complete todos los campos");
        counter = 0;
        break;
      } else if (
        elements.roleInput.value !== "Docente" &&
        elements.roleInput.value !== "Acudiente"
      ) {
        alert("Elija si es docente o acudiente");
        counter = 0;
        break;
      } else if (elements.roleInput.value === "Docente") {
        if (elements.password.value !== elements.checkPassword.value) {
          alert("La contraseña no coincide");
          counter = 0;
          break;
        } else if (counter === elements.length) {
          elements.roleInput.value === "Docente"
            ? setRole("teacher")
            : setRole("attending");
          let names = elements.names.value.trim().toLowerCase();
          let lastnames = elements.surnames.value.trim().toLowerCase();
          let idH = +idHeadquarter;

          let json = {
            first_name:
              names.indexOf(" ") !== -1
                ? names.slice(0, names.indexOf(" "))
                : names,
            second_name:
              names.indexOf(" ") !== -1
                ? names.slice(names.indexOf(" ")).trim()
                : "",
            first_surname:
              lastnames.indexOf(" ") !== -1
                ? lastnames.slice(0, lastnames.indexOf(" "))
                : lastnames,
            second_surname:
              lastnames.indexOf(" ") !== -1
                ? lastnames.slice(lastnames.indexOf(" ")).trim()
                : "",
            national_id: elements.nationalId.value,
            username: elements.username.value.toLowerCase(),
            email: elements.email.value,
            phone_contact: elements.phone.value,
            password: elements.password.value,
            role_id: idRole,
            headquarter_id: idH,
          };

          let validateJson = {
            national_id: elements.nationalId.value,
            username: elements.username.value.toLowerCase(),
            email: elements.username.value,
            phone_contact: elements.phone.value,
          };

          setJson(json);
          // history.push("/completesignup")
          let data = await postData(validateJson);
          console.log(data);
          if (data !== false) {
            history.push("/completesignup");
          } else {
            setModalShow(true);
          }
        }
      } else if (elements.roleInput.value === "Acudiente") {
        if (elements.password.value !== elements.checkPassword.value) {
          alert("La contraseña no coincide");
          counter = 0;
          break;
        } else if (gradesSelected.length <= 0) {
          alert("Selecciona el(los) grado(s) de(los) estudiante(s)");
        } else if (counter === elements.length) {
          elements.roleInput.value === "Docente"
            ? setRole("teacher")
            : setRole("attending");

          let names = elements.names.value.trim().toLowerCase();
          let lastnames = elements.surnames.value.trim().toLowerCase();
          let idH = +idHeadquarter;

          let json = {
            first_name:
              names.indexOf(" ") !== -1
                ? names.slice(0, names.indexOf(" "))
                : names,
            second_name:
              names.indexOf(" ") !== -1
                ? names.slice(names.indexOf(" ")).trim()
                : "",
            first_surname:
              lastnames.indexOf(" ") !== -1
                ? lastnames.slice(0, lastnames.indexOf(" "))
                : lastnames,
            second_surname:
              lastnames.indexOf(" ") !== -1
                ? lastnames.slice(lastnames.indexOf(" ")).trim()
                : "",
            national_id: elements.nationalId.value,
            username: elements.username.value,
            email: elements.email.value,
            phone_contact: elements.phone.value,
            password: elements.password.value,
            role_id: idRole,
            headquarter_id: idH,
            grades: gradesSelected,
          };

          let validateJson = {
            national_id: elements.nationalId.value,
            username: elements.username.value.toLowerCase(),
            email: elements.username.value,
            phone_contact: elements.phone.value,
          };

          setJson(json);
          // history.push("/completesignup")

          let data = await postData(validateJson);
          // console.log(data)

          if (data !== false) {
            history.push("/completesignup");
          } else {
            setModalShow(true);
          }
        }
      }
      counter++;
    }
  };

  const handleModal = () => {
    setModalShow(false);
  };

  return (
    <div className="af-registerContainer af-container">
      {/* <NavBar url="/loginPage" activeKey="4" name="Ingresar"></NavBar> */}
      <Container className="principal-page" fluid>
        {/* <LoadingPage> */}
        <Form onSubmit={submit}>
          <div className="af-formContent">
            <Row>
              <Col xs>
                <h2><strong>Bienvenido, complete su registro</strong></h2>
                <p className="af-subtitle">
                  Regístrate en la Plataforma Universo Educatic-vo y accede a los contenidos y recursos para la enseñanza y el aprendizaje, organizados en las áreas básicas, y a los reportes institucionales del acceso y uso de la plataforma por parte de la población de jóvenes extraedad y adultos beneficiarios, del acompañamiento a la implementación del Modelo Educativo Flexible Talentum, en el Valle del Cauca.
                  Para registrarte, diligencia los siguientes datos y crea tu usuario y contraseña:
                </p>
              </Col>
            </Row>
            <Row className="justify-content-md-center d-none">
              <Col xs lg="4" className="d-flex justify-content-center">
                <div className="af-inputIcon">
                  <Image src={UserIdIcon} />
                </div>
                <FormControl
                  onChange={handleChange}
                  as="select"
                  className="af-inputs af-select"
                  name="roleInput"
                  value="Docente"
                >
                  <option selected disabled>
                    Docente
                  </option>
                  <option>Docente</option>
                  {/* <option>Acudiente</option> */}
                </FormControl>
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              <Col xs lg="4" className="d-flex justify-content-center">
                <div className="af-inputIcon">
                  <Image src={LocationIcon} />
                </div>
                <FormControl
                  id="town"
                  onChange={handleChange2}
                  as="select"
                  className="af-inputs af-select"
                  value={townId}
                  name="town"
                >
                  <option selected disabled value="0">
                    Escoja el municipio
                  </option>
                  {towns.map((tw) => (
                    <option value={`${tw.id}`}>{tw.town_name}</option>
                  ))}
                </FormControl>
              </Col>
            </Row>
            {idRole === 2 && ( // acudiente
              <Row className="justify-content-md-center">
                <Col xs lg="4" className="d-flex justify-content-center">
                  <div className="af-inputIcon">
                    <Image src={BuildingIcon} />
                  </div>
                  <FormControl
                    id="institution"
                    onChange={handleChange2}
                    as="select"
                    className="af-inputs af-select"
                    value={instId}
                    name="institutionId"
                  >
                    <option selected disabled value="0">
                      Escoja la Institución Educativa
                    </option>
                    {institutions.map((inst) => (
                      <option value={`${inst.id}`}>{inst.inst_name}</option>
                    ))}
                  </FormControl>
                </Col>
              </Row>
            )}
            {idRole === 3 && ( // profesor
              <Row className="justify-content-md-center">
                <Col xs lg="4" className="d-flex justify-content-center">
                  <div className="af-inputIcon">
                    <Image src={GradeIcon} />
                  </div>
                  <FormControl
                    type="text"
                    id="instDaneCode"
                    placeholder="Escriba el código DANE de su institución"
                    className="af-inputs"
                    onChange={handleChange2}
                    name="institutionDaneCode"
                  />
                </Col>
              </Row>
            )}
            <Row className="justify-content-md-center">
              <Col xs lg="4" className="d-flex justify-content-center">
                <div className="af-inputIcon">
                  <Image src={BuildingIcon} />
                </div>
                <FormControl
                  id="headq"
                  onChange={handleChange2}
                  as="select"
                  className="af-inputs af-select"
                  value={idHeadquarter}
                  name="hqId"
                >
                  <option selected disabled value="0">
                    Escoja la sede
                  </option>
                  {headQuarters.map((hq) => (
                    <option value={`${hq.id}`}>{hq.hq_name}</option>
                  ))}
                </FormControl>
              </Col>
            </Row>
            {idRole === 2 && ( // acudiente
              <Row className="justify-content-md-center">
                <Col xs lg="4" className="d-flex justify-content-center">
                  <div className="af-inputIcon">
                    <Image src={GradeIcon} />
                  </div>
                  <div className="af-gradesBox">
                    <p>Escoja el(los) grado(s) del estudiante(s):</p>
                    <div className="af-gradesInputs">
                      {grades.map((gr: any, i: any) => (
                        <Col xs={4} key={i}>
                          <Form.Check
                            custom
                            className="sectionCheckbox"
                            type="checkbox"
                            name={`grades-${i}`}
                            value={gr.id}
                            id={`${gr.id}-grades`}
                            label={`${gr.grade_name}`}
                            onChange={handleChange2}
                          />
                        </Col>
                      ))}
                    </div>
                  </div>
                </Col>
              </Row>
            )}
            <Row className="justify-content-md-center">
              <Col xs lg="4" className="d-flex justify-content-center">
                <div className="af-inputIcon">
                  <Image src={UserIcon} />
                </div>
                <FormControl
                  type="text"
                  placeholder="Escriba sus nombres"
                  className="af-inputs"
                  name="names"
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              <Col xs lg="4" className="d-flex justify-content-center">
                <div className="af-inputIcon">
                  <Image src={UserIcon} />
                </div>
                <FormControl
                  type="text"
                  placeholder="Escriba sus apellidos"
                  className="af-inputs"
                  name="surnames"
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              <Col xs lg="4" className="d-flex justify-content-center">
                <div className="af-inputIcon">
                  <Image src={UserIcon} />
                </div>
                <FormControl
                  type="number"
                  placeholder="Escriba su número de identificación"
                  className="af-inputs"
                  name="nationalId"
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              <Col xs lg="4" className="d-flex justify-content-center">
                <div className="af-inputIcon">
                  <Image src={EmailIcon} />
                </div>
                <FormControl
                  type="email"
                  placeholder="Escriba su correo electrónico"
                  className="af-inputs"
                  name="email"
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              <Col xs lg="4" className="d-flex justify-content-center">
                <div className="af-inputIcon">
                  <Image src={PhoneIcon} />
                </div>
                <FormControl
                  type="number"
                  placeholder="Escriba su número de celular"
                  className="af-inputs"
                  name="phone"
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              <Col xs lg="4" className="d-flex justify-content-center">
                <div className="af-inputIcon">
                  <Image src={UserIdIcon} />
                </div>
                <FormControl
                  type="text"
                  placeholder="Cree su nombre de usuario"
                  className="af-inputs"
                  name="username"
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              <Col xs lg="4" className="d-flex justify-content-center">
                <div className="af-inputIcon">
                  <Image src={PassQuestIcon} />
                </div>
                <FormControl
                  type="password"
                  placeholder="Cree su contraseña"
                  className="af-inputs"
                  name="password"
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              <Col xs lg="4" className="d-flex justify-content-center">
                <div className="af-inputIcon">
                  <Image src={PassCheckIcon} />
                </div>
                <FormControl
                  type="password"
                  placeholder="Confirme su contraseña"
                  className="af-inputs"
                  name="checkPassword"
                />
              </Col>
            </Row>
            <Modal
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={modalShow}
              onHide={handleModal}
            >
              <Modal.Body>
                <h4 className="text-center m-4">
                  La información ingresada, ya ha sido registrada
                </h4>
              </Modal.Body>
              <Modal.Footer>
                <div>
                  <Button
                    style={{
                      backgroundColor: "#f26945",
                      borderColor: "#f26945",
                    }}
                    onClick={handleModal}
                  >
                    Cerrar
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>
          </div>
          <div className="af-btnContinue">
            <Button type="submit">
              <h5>Continuar</h5>
              <AiFillRightCircle size="2em" />
            </Button>
          </div>
        </Form>
        {/* </LoadingPage> */}
      </Container>
      <FootPages></FootPages>
    </div>
  );
};

export default RegisterPage;
