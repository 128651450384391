import React from 'react';
import './videos.scss';

//impport elementos de react
import { Row, Col, Pagination } from 'react-bootstrap';

//import Imagenes
import videoAzul from '../../img/imgResources/videoAzul.png';
import videoAmar from '../../img/imgResources/videoAmar.png';
import videoAzulO from '../../img/imgResources/videoAzulO.png';
import videoNaran from '../../img/imgResources/videoNaran.png';
import videoVer from '../../img/imgResources/videoVer.png';

//import comoponentes y vistas
import SubComponent from '../subComponent/subComponent';
import ZoomVideo from '../zoomVideo/zoomVideo';


export interface Props { Arrayresources: any, grade: any, subject: any, rol: any }


export default class Videos extends React.Component<Props, { number: any, desc: string, resource_id: number, url: string, resources: any, imagResourcesVideo: any, currentPage: any, elementPage: any, zoomVideo: boolean, grade: string, subject: string }> {


    constructor(props: any) {
        super(props);
        this.state = {
            resources: this.props.Arrayresources,
            imagResourcesVideo: [videoAzul, videoAmar, videoAzulO, videoNaran, videoVer],
            currentPage: 1,
            elementPage: 6,
            zoomVideo: false,
            resource_id: -1,
            url: '',
            desc: '',
            grade: '',
            subject: '',
            number: '',

        };
        this.handleClick = this.handleClick.bind(this);
        this.updateZoomVideo = this.updateZoomVideo.bind(this);

    }
    componentDidMount() {
        console.log('entre  aa component Didmount')
        console.log(this.props.Arrayresources)
    }

    handleClick(event: any) {
        event.preventDefault();
        this.setState({ currentPage: Number(event.target.id) })
    }

    updateZoomVideo(event: any, resource_id: any, url: any, desc: any, grade: any, subject: any, i: any) {
        console.log(url);
        event.preventDefault();
        this.setState({ zoomVideo: !this.state.zoomVideo, resource_id: resource_id, url: url, desc: desc, grade: grade, subject: subject, number: i })
    }

    render() {
        const { resources, number, currentPage, elementPage, resource_id, url, imagResourcesVideo, desc, grade, subject } = this.state;

        //-----------------------------Paginación-----------------------------------
        const indexOfLastTodo = currentPage * elementPage;
        const indexOfFirstTodo = indexOfLastTodo - elementPage;
        const currentResources = resources.slice(indexOfFirstTodo, indexOfLastTodo);

        let active = currentPage;
        let numberPages = Math.ceil(this.state.resources.length / this.state.elementPage);
        let items:any = [];
        for (let number = 1; number <= numberPages; number++) {
            items.push(
                <Pagination.Item key={number} id={number} active={number === active} onClick={this.handleClick}>
                    {number}
                </Pagination.Item>,
            );
        }

        const paginationBasic = (
            <div> <Pagination>{items}</Pagination><br /></div>
        );

        //---------------------------------------------------------------------------------
        return (
            <div >
                <Row className="w-100 m-0 h-100 pt-5">
                    <Col className="justify-content-center align-self-center text-center p-3" md={4}>
                        <div className="af-lineOrange"></div>
                    </Col>
                    <Col md={4}>
                        <h2 className="af-tittleLesson">Videos</h2>
                    </Col>

                    <Col className="justify-content-center align-self-center text-center p-3" md={4}>
                        <div className="af-lineOrange"></div>
                    </Col>
                </Row>
                {!this.state.zoomVideo ?
                    <Row>
                        <p className="af-textComponent p-4">Son recursos educativos que facilitan la construcción del conocimiento de manera significativa, haciendo uso del potencial comunicativo de las imágenes, los sonidos y las palabras, transmitiendo una serie de experiencias que estimulan los sentidos y los distintos estilos de aprendizaje en los estudiantes.</p>

                        {currentResources.map((resour: any, i: any) => {
                            let indexImg = i >= 5 ? i - 5 : i;
                        
                            return (
                                <Col key={i} md={4}>
                                    {/* se adapta dependiendo del JSON de la base de datos para: url:resource_url, titulo:resource_name, desc:dba_name para src:src */}
                                    <div onClick={(e: any) => this.updateZoomVideo(e, resour.id, resour.resource_url, resour.dba_name, this.props.grade, this.props.subject, i)}>
                                        <SubComponent tittle={`V${(i+1)+indexOfFirstTodo}.`} url='' src={imagResourcesVideo[indexImg]} desc={resour.dba_name}
                                        ></SubComponent>
                                    </div>
                                </Col>
                            )
                        })}
                        {resources.length > 6 &&
                            < Row className="w-100 justify-content-center align-self-center text-center">
                                {paginationBasic}
                            </Row>
                        }

                    </Row>
                    :
                    <div>
                        <ZoomVideo resource_id={resource_id} updateZoomVideo={this.updateZoomVideo} url={url} desc={desc} grade={grade} subject={subject} tittle={`VIDEO ${number + 1}`}></ZoomVideo>
                    </div>
                }
            </div >
        )
    }
}