import React, { useState, useEffect } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { AiFillRightCircle } from "react-icons/ai";
import FootPages from "../../components/footPage/footPage";
import CompleteSignupSection from "../../components/completeSignupSection/completeSignupSection";
import InputDataPersonal from "../../components/inputsDataPersonal/inputsDataPersonal";
import { useLocation, useHistory } from "react-router-dom";
import "./completeSignupPage.scss";
import {
  POST_AXIOS,
  GET_AXIOS,
  PUT_AXIOS,
} from "../../components/funtionsREST";
import CardNews from "../../components/CardNews/CardNews";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

interface Props {
  role: string;
  json: any;
}

const yesNo = [
  { id: 1, name: "Sí" },
  { id: 0, name: "No" },
];

const CompleteSignupPage = ({ role, json }: Props) => {
  const history = useHistory();
  const [grades, setgrades] = useState([]);
  const [cycles, setCycles] = useState([]);
  const [areas, setAreas] = useState([]);
  const [otherAreas, setOtherAreas] = useState([])
  const [select, setSelect] = useState();
  const [selectCiclo, setSelectCiclo]: any = useState([]);
  // const [gradesUserDb, setGradesUserDb] = useState([])
  // const [cyclesUserDb, setCyclesUserDb] = useState([])
  // const [knowledgeAreasUserDb, setKnowledgeAreasUserDb] = useState([])
  // const [accompanimentUserDb, setAccompanimentUserDb] = useState('')

  useEffect(() => {
    getFuntion("grades").then((res) => {
      const allGrades = capitalizeWords(res.data, "grade_name");
      const _grades = allGrades.slice(0, 12).map((grade: any) => {
        return { id: grade.id, name: grade.grade_name };
      });
      const _cycles = allGrades.slice(12).map((grade: any) => {
        return { id: grade.id, name: grade.grade_name };
      });
      setgrades(_grades);
      setCycles(_cycles);
    });
    getFuntion("knowledgeAreas/base").then((res) => {
      const _kas = res.data.map((ka: any) => {
        return { id: ka.id, name: ka.knowledge_area_name };
      });
      setAreas(capitalizeWords(_kas, "name"));
    });

    getFuntion("knowledgeAreas/others").then((res) => {
      const _kas = res.data.map((ka: any) => {
        return { id: ka.id, name: ka.knowledge_area_name };
      });
      setOtherAreas(capitalizeWords(_kas, "name"));
    });

    // json.email = "1";
    if (json.email !== "") {
      getFuntion("users/grades").then((res) => {
        console.log(res.data);
      });
      getFuntion("users/knowledgeAreas").then((res) => {
        console.log(res.data);
      });
      getFuntion("users/profile").then((res) => {
        console.log(res.data);
      });
    }
  }, []);

  const getFuntion = async (url: String) => {
    return await GET_AXIOS(url);
  };

  const capitalizeWords = (arr: Array<any>, keyInObj: String) => {
    const toReturn: any = [];
    arr.map((el: any) => {
      let word: string = "";
      el[`${keyInObj}`].split(" ").map((wr: any) => {
        if (wr) {
          word += wr[0].toUpperCase() + wr.slice(1) + " ";
        }
      });
      word = word.trim();
      toReturn.push({ id: el.id, [`${keyInObj}`]: word });
    });
    return toReturn;
  };

  const submit = async (event: any) => {
    event.preventDefault();
    const finalGrades: Array<number> = [];
    const finalAreas: Array<number> = [];
    const wantInfo: number = event.currentTarget.elements.info.value;
    let obj: any = {}

    if (role === "teacher") {
      grades.map((gr: any) => {
        let checked = event.currentTarget.elements[`grade-${gr.id}`].checked;
        checked && finalGrades.push(gr.id);
        console.log("1", typeof finalGrades[0]);
      });

      selectCiclo.map((valueG: any) => {
        areas.map((ar: any) => {
          let checked = event.currentTarget.elements[`area${valueG}-${ar.id}`].checked;
          checked && finalAreas.push(ar.id);
          if (checked) {
            if (!obj[valueG]) obj[valueG] = [];
            obj[valueG].push(ar.id);
          }
        });
        otherAreas.map((ar: any) => {
          const exist = event.currentTarget.elements[`area${valueG}-${ar.id}`]
          if (exist) {
            if (!obj[valueG]) obj[valueG] = [];
            obj[valueG].push(ar.id);
          }
        })
      });
      console.log(obj)

      console.log(finalAreas);
      // cycles.map((cycle:any) => {
      //   let checked = event.currentTarget.elements[`grade-${cycle.id}`].checked;
      //   checked && finalGrades.push(cycle.id);
      // });
    }

    console.log(typeof finalGrades[0], finalAreas);
    let jsonToCompleteRegister: any = {
      grades: obj,
      knowledge_areas: finalAreas,
      accompaniment: wantInfo,
    };
    // jsonToCompleteRegister.grades = json.grades

    console.log("jsons", json, jsonToCompleteRegister);
    let data = json.email !== "" ? await postData("users/newUser", json) : "";
    console.log(data);
    let dataCompl = await putData("users/completeRegister", jsonToCompleteRegister);
    console.log(dataCompl);

    if (dataCompl !== false) {
      console.log("entre a el if")
      history.push("/loginPage");
    } else {
    }
    console.log(data)
  };

  const selectCiclos = (e: any) => {
    // console.log(e);
    let arrayciclos: any = selectCiclo;
    let index_ = arrayciclos.findIndex((element: any) => element === e);
    if (index_ === -1) {
      arrayciclos.push(e);
    } else {
      arrayciclos.splice(index_, 1);
    }
    arrayciclos.sort();
    setSelectCiclo([...arrayciclos]);

    console.log(selectCiclo);
    console.log(arrayciclos);
  };

  const handleChange = (e: any) => {
    console.log("e", e.target.value);
    setSelect(e.target.value);
  };

  const postData = async (url: any, post: any) => {
    console.log("register", post);
    let response = await POST_AXIOS(url, post);
    if (response !== false) {
      return response.data;
    } else {
      return response;
    }
  };

  const putData = async (url: any, post: any) => {
    console.log("register", post);
    let response = await PUT_AXIOS(url, post);
    if (response !== false) {
      return response.data;
    } else {
      return response;
    }
  };

  const inputs = <InputDataPersonal role={role} />;

  return (
    <div
      className={`af-completeSignupPageContainer af-${
        role === "principal" && "principal"
        }`}
    >
      {/* <NavBar url="/IntroductionPage" activeKey="4" name="Perfil"></NavBar> */}
      <Container className="principal-page" fluid>
        <Form onSubmit={submit}>
          <div className="af-formContent">
            <Row>
              <Col>
                <Col xs>
                  <h2>
                    <strong>Bienvenido, completa tu registro</strong>
                  </h2>
                  <p>
                    A continuación, indícanos los ciclos y áreas a tu cargo,
                    para darte acceso a los contenidos y recursos para la
                    enseñanza y el aprendizaje, organizados en las áreas
                    básicas, y a los reportes institucionales del acceso y uso
                    de la plataforma por parte de tus estudiantes; jóvenes
                    extraedad y adultos beneficiarios, del acompañamiento a la
                    implementación del Modelo Educativo Flexible Talentum, en el Valle del Cauca.
                  </p>
                </Col>
                {role === "teacher" && (
                  <div>
                    <CompleteSignupSection
                      title={"Escoja sus ciclos a cargo"}
                      options={grades}
                      type="checkbox"
                      id="grade"
                      select={selectCiclos}
                    />
                    {/* <CompleteSignupSection
                      title={
                        "Escoge tus ciclos a cargo: (educación para adultos)"
                      }
                      options={cycles}
                      type="checkbox"
                      id="grade"
                      select={null}
                    /> */}

                    {selectCiclo.map((value: any, key: any) => (
                      <div key={key}>
                        <CompleteSignupSection
                          // @ts-ignore
                          title={`Área(s) a cargo ${(grades.filter(x => x.id == value)[0].name)}`}
                          options={areas}
                          others={otherAreas}
                          type="checkbox"
                          id={`area${value}`}
                          select={null}
                        />
                      </div>
                    ))}

                    <CompleteSignupSection
                      title="¿Quieres recibir acompañamiento e información actualizada para tu gestión pedagógica?"
                      options={yesNo}
                      type="radio"
                      id="info"
                      select={setSelect}
                    />
                  </div>
                )}
              </Col>
            </Row>
            {(role === "principal" ||
              role === "student" ||
              role === "attending") && (
                <Row>
                  <Col md={{ span: 6, offset: 3 }} className="af-onlyPrincipal">
                    <div className="af-princText">
                      <h3>
                        ¿Quieres recibir acompañamiento e información actualizada
                        para tu gestión pedagógica?
                    </h3>
                    </div>
                    <div className="af-options">
                      {yesNo.map((opt) => (
                        <Col md={6}>
                          <Form.Check
                            custom
                            className="sectionCheckbox"
                            type="radio"
                            name="info"
                            value={opt.id}
                            id={`${opt.id}-info`}
                            label={`${opt.name}`}
                            onClick={handleChange}
                          />
                        </Col>
                      ))}
                    </div>
                  </Col>
                </Row>
              )}

            {role === "student" ? inputs : null}

            {/* {
              role === "attending" ? <a onClick={handleClick}><h3>Agregar estudiante</h3></a> : null
            } */}
            {/* {
              students.map( (student:any) => (
                <InputStudent />
              ))
            } */}

            {/* { (role === "attending" && select === 1) &&
              <InputDataPersonal role={role}/>
            } */}
          </div>
          <div className="af-btnContinue mb-5 mb-md-2">
            <Button type="submit"
            >
              <h5>Continuar</h5>
              <AiFillRightCircle size="2em" />
            </Button>
          </div>
        </Form>
      </Container>
      <FootPages />
    </div>
  );
};

export default CompleteSignupPage;
