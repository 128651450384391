import React, { useState, useEffect } from "react";
import { Container, Row, Col, Pagination } from "react-bootstrap";

/* Importacion de componentes */
import { NavBar } from "../../components/navBar/navBar";
import FootPages from "../../components/footPage/footPage";
import SearchInput from "../../components/AdminUsers/SearchInput/SearchInput";
import CardUser from "../../components/AdminUsers/CardUsers/CardUsers";
import Title from "../../components/AdminUsers/Title/Title";

/* Importacion de servicios */
import { GET_AXIOS, PUT_AXIOS } from "../../components/funtionsREST";
import LoadingPage from "../../components/spinner/loadingPage";

export default function UserAdmin() {
  const [currentPage, setCurrentPage] = useState(1);
  const [elementPage, setElementPage] = useState(4);
  const [resources, setresources] = useState([
    {
      id: 0,
      resource_name: "titulo",
      resource_url: "url",
      resource_description: "texto",
      thumbnail: "img",
      resource_type_id: 1 /* tipo */,
      knowledge_area_id: 1 /* asignatura */,
      grade_id: 1 /* grado */,
      n: 1,
    },
  ]);
  /* --Funciones-- */
  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const getData = await GET_AXIOS("resources");
    console.log(getData);

    if (getData) setresources(getData.data);
  }

  function putData(data: any) {
    console.log("funciono perra");
    let auxResources = [...resources];
    const isIdElement = (element: any, id: number) => element.id === id;
    const idElement = auxResources.findIndex(isIdElement);

    if (idElement !== -1) {
      auxResources[idElement] = data;
    }

    setresources(auxResources);
    //PUT_AXIOS("resources/update", data);
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setCurrentPage(Number(event.currentTarget.id));
  };
  /* ------Fin de Funciones------ */

  /* --Paginacion-- */
  const indexOfLastTodo = currentPage * elementPage;
  const indexOfFirstTodo = indexOfLastTodo - elementPage;
  const currentResources = resources.slice(indexOfFirstTodo, indexOfLastTodo);

  let active = currentPage;
  let numberPages = Math.ceil(resources.length / elementPage);
  let items = [];
  for (let number = 1; number <= numberPages; number++) {
    items.push(
      <Pagination.Item
        key={number}
        id={number}
        active={number === active}
        onClick={handleClick}
      >
        {number}
      </Pagination.Item>
    );
  }

  const paginationBasic = (
    <div>
      {" "}
      <Pagination>{items}</Pagination>
      <br />
    </div>
  );
  /* -------------- */
  return (
    <div>
      {/* <NavBar url="/loginPage" activeKey="4" name="Ingresar"></NavBar> */}
      <div className="principal-page">
        <Container fluid>
          <Row>
            <Col>
              <Title>titulo op</Title>
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <SearchInput />
        </Container>
        <Container fluid>
          <LoadingPage>
            <>
              <Row className="justify-content-md-start justify-content-center">
                {currentResources.map((value: any, i: number) => (
                  <Col key={`cardUser${i}`} xs={10} sm={8} md={4} lg={3}>
                    <CardUser
                      title={value.resource_name}
                      data={value}
                      putData={putData}
                    />
                  </Col>
                ))}
              </Row>
              {paginationBasic}
            </>
          </LoadingPage>
        </Container>
      </div>
      <FootPages />
    </div>
  );
}
