import React from "react";
import ReactDOM from "react-dom";
import "./navBar.scss";

//impport elementos de react
import {
  Navbar,
  Nav,
  Image,
  Button,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { useHistory, NavLink, Link } from "react-router-dom";

// import { NavHashLink } from "react-router-hash-link";

//import comoponentes y vistas

//import imagenes
import logoNavBar from "../../img/logoNavBar.png";

// import Global funtions
import { POST_AXIOS, GET_AXIOS } from "../funtionsREST";
import { FiLogOut } from "react-icons/fi";
import ModalLogout from "../modalLogout/ModalLogout";

export interface Props {
  //   url: string;
  //   name: string;
  user: any;
  isLogout: any;
  //   activeKey: any;
}

// Menu superior de las paginas
export class NavBar extends React.Component<
  Props,
  { show: boolean; activate: boolean }
  > {
  constructor(props: any) {
    super(props);
    this.state = {
      show: false,
      activate: false,
      //   activedKey: this.props.activeKey,
    };
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClickLogin = this.handleClickLogin.bind(this);
  }

  handleShow = () => this.setState({ show: true });
  handleClose = () => this.setState({ show: false });
  handleClick = () => {
    this.setState({ activate: !this.state.activate });
  };

  componentDidMount() {
    console.log(this.props.user);
    console.log(this.props.user.role);
  }
  //   handleNavBar = (eventKey: any) => this.setState({ activedKey: eventKey });

  handleClickLogin = async () => {
    let response = await GET_AXIOS("loginsamlify");
    console.log("asynchandleClickLogin -> response", response);
    window.location = response.data;
  };

  ModaLogout = () => {
    const HISTORY = useHistory();
    const setLogout = async () => {
      let response = await POST_AXIOS("logoutsamlify", {});
      console.log("NavBar -> setLogout -> response", response);
      if (response !== false) {
        HISTORY.replace("/");
        this.props.isLogout();
        this.handleClose();
        window.location = response.data;
      }
    };

    return (
      <ModalLogout
        show={this.state.show}
        handleClose={this.handleClose}
        isLogout={this.props.isLogout}
      />
    );
  };

  render() {
    return (
      <Navbar
        sticky="top"
        collapseOnSelect
        expand="lg"
        className="af-backgroundNav"
      >
        <Link to="/">
          <Image className="af-logoNav img-fluid" src={logoNavBar} />
        </Link>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav variant="pills" className="ml-auto">
            <a
              href="/"
              className="nav-link mx-5"
              target="_blank"
              rel="noopener noreferrer"
            >
              Noticias
            </a>
            <NavLink exact to="/" className="nav-link mx-5">
              Home
            </NavLink>
            <NavLink exact to="/tutorial" className="nav-link mx-5">
              Aprende a Navegar
            </NavLink>
            {this.props.user.id !== undefined ? (
              <>
                <OverlayTrigger
                  trigger="click"
                  key={"bottom"}
                  placement={"bottom"}
                  overlay={
                    <Popover
                      id={`popover-positioned-bottom`}
                      className="popover-profile-body p-1"
                    >
                      <Popover.Title className="popover-profile-title">
                        {" "}
                        {this.props.user.first_name}{" "}
                        {this.props.user.first_surname}
                      </Popover.Title>
                      <Popover.Content className={"px-1"}>
                        {/* <Nav.Link>Modificar perfil</Nav.Link> */}
                        {this.props.user.role !== "est" &&
                          <Nav.Link href="/changePass">
                            Cambiar contraseña
                          </Nav.Link>
                        }

                        <Button
                          onClick={this.handleShow}
                          className="button-logout"
                        >
                          Cerrar sesión
                        </Button>
                      </Popover.Content>
                    </Popover>
                  }
                >
                  <button
                    onClick={this.handleClick}
                    className={`mx-5 ${
                      this.state.activate === true
                        ? "btn button-poopover"
                        : "btn button-poopover-desabil"
                      }`}
                  >
                    Perfil
                  </button>
                </OverlayTrigger>{" "}
              </>
            ) : (
                // <NavLink to="/loginPage" className="nav-link mx-5">
                //   Ingresar
                // </NavLink>
                <button
                  onClick={this.handleClickLogin}
                  className={`mx-5 ${
                    this.state.activate == true
                      ? "btn button-poopover"
                      : "btn button-poopover-desabil"
                    }`}
                >
                  Ingresar
                </button>
              )}
          </Nav>
        </Navbar.Collapse>
        {ReactDOM.createPortal(
          <this.ModaLogout />,
          document.createElement("div")
        )}
      </Navbar>
    );
  }
}
