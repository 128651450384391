import React, { useState, useEffect } from "react";
import "./callCenterReport.scss";
import { Row, Col, Button } from "react-bootstrap";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import filterFactory, {
  textFilter,
  selectFilter,
  Comparator,
} from "react-bootstrap-table2-filter";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import ToolkitProvider, { ColumnToggle } from "react-bootstrap-table2-toolkit";
import { TiHomeOutline } from "react-icons/ti";
import { Link } from "react-router-dom";
import {
  POST_AXIOS,
  GET_AXIOS,
  PUT_AXIOS,
} from "../../components/funtionsREST";

export default function CallCenterReport() {
  const { ToggleList } = ColumnToggle;
  const [inqOptions, setInqOptions]: any = useState([
    { value: 1, label: "TÉCNICO" },
    { value: 2, label: "CONTENIDO DE LA PLATAFORMA" },
    { value: 3, label: "INCONFORMIDAD GENERAL" },
    { value: 4, label: "GENERALIDADES DEL PROYECTO" },
  ]);
  const [roleOptions, setRoleOptions]: any = useState([
    { value: 1, label: "ESTUDIANTE" },
    { value: 2, label: "ACUDIENTE" },
    { value: 3, label: "DOCENTE" },
    { value: 4, label: "RECTOR" },
    { value: 5, label: "SECRETARÍA DE EDUCACIÓN" },
    { value: 6, label: "ADMINISTRADOR" },
    { value: 7, label: "CALL CENTER" },
  ]);
  const [townOptions, setTownOptions]: any = useState([
    { value: 0, label: "CALI" },
  ]);
  const [allInstitutions, setAllInstitutions]: any = useState([
    { value: 0, label: "IE ARTURO GOMEZ JARAMILLO" },
    { value: 1, label: "IE SAN JOSE" },
    { value: 3, label: "IE AGRICOLA CAMPOALEGRE" },
  ]);
  const [institutionOptions, setInstitutionOptions]: any = useState([]);
  const [phoneTypeOptions, setPhoneTypeOptions]: any = useState([
    { value: 0, label: "PROPIO" },
    { value: 1, label: "FAMILIAR" },
    { value: 3, label: "VECINO" },
    { value: 4, label: "OTRO" },
  ]);

  const [statusOptions, setStatusOptions]: any = useState([
    { value: 1, label: "NO TRATADO" },
    { value: 2, label: "EN TRÁMITE" },
    { value: 3, label: "FINALIZADO" },
    { value: 4, label: "FINALIZADO" },
    { value: 5, label: "FINALIZADO" },
  ]);
  
  const [agentOptions, setAgentOptions]: any = useState([
    { value: 0, label: "None" },
  ]);
  const [products, setProducts]: any = useState([
    {
      serialCode: "000",
      dateTime: "01/01/00 12:00 AM",
      caller_name: "Usuario",
      request_id: 1,
      desc_request: "No hay datos disponibles en este momento.",
      role_id: 1,
      town_id: 0,
      institution_id: 1,
      phone: "310",
      phone_type_id: 1,
      observations: "No hay datos disponibles en este momento.",
      agent_id: 0,
      status:0,
    },
  ]);
  const [page, setPage]: any = useState(1);
  const [sizePerPage, setSizePerPage]: any = useState(10);
  const [totalSize, setTotalSize]: any = useState(products.length);
  const [hiddenColumns, setHiddenColumns]: any = useState([
    true,
    false,
    false,
    false,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    false,
  ]);

  let columns = [
    {
      dataField: "status",
      text: "Estado",
      hidden: hiddenColumns[0],
      sort: true,
      formatter: (cell: number) =>
        statusOptions.find((opt: any) => opt.value === cell).label || "",
        filter: selectFilter({
          options: statusOptions.slice(0, 3),
        }),
    },
    {
      dataField: "serial_code",
      text: "Código serial",
      hidden: hiddenColumns[1],
      sort: true,
    },
    {
      dataField: "dateTime",
      text: "Fecha y hora",
      hidden: hiddenColumns[2],
      sort: true,
    },
    {
      dataField: "caller_name",
      text: "Nombre completo",
      hidden: hiddenColumns[3],
      sort: true,
    },
    {
      dataField: "request_id",
      text: "Inquietud",
      hidden: hiddenColumns[4],
      sort: true,
      formatter: (cell: number) =>
        inqOptions.find((opt: any) => opt.value === cell).label || "",
      filter: selectFilter({
        options: inqOptions,
      }),
    },
    {
      dataField: "desc_request",
      text: "Descripción",
      hidden: hiddenColumns[5],
      sort: true,
    },
    {
      dataField: "role_id",
      text: "Rol",
      hidden: hiddenColumns[6],
      sort: true,
      formatter: (cell: number) =>
        roleOptions.find((opt: any) => opt.value === cell).label || "",
      filter: selectFilter({
        options: roleOptions,
      }),
    },
    {
      dataField: "town_id",
      text: "Municipio",
      hidden: hiddenColumns[7],
      sort: true,
      formatter: (cell: number) =>
        townOptions.find((opt: any) => opt.value === cell).label || "",
      filter: selectFilter({
        options: townOptions,
      }),
    },
    {
      dataField: "institution_id",
      text: "Institución",
      hidden: hiddenColumns[8],
      sort: true,
      formatter: (cell: number) =>
        allInstitutions.find((opt: any) => opt.value === cell).label || "",
      filter: selectFilter({
        options: institutionOptions,
      }),
    },
    {
      dataField: "phone",
      text: "Teléfono",
      hidden: hiddenColumns[9],
      sort: true,
    },
    {
      dataField: "phone_type_id",
      text: "Tipo teléfono",
      hidden: hiddenColumns[10],
      sort: true,
      formatter: (cell: number) =>
        phoneTypeOptions.find((opt: any) => opt.value === cell).label || "",
      filter: selectFilter({
        options: phoneTypeOptions,
      }),
    },
    {
      dataField: "observations",
      text: "Observaciones",
      hidden: hiddenColumns[11],
    },
    {
      dataField: "agent_id",
      text: "Gestor TIC",
      hidden: hiddenColumns[12],
      sort: true,
      formatter: (cell: number) =>
        agentOptions.find((opt: any) => opt.value === cell).label || "",
      filter: selectFilter({
        options: agentOptions,
      }),
    },
  ];

  useEffect(() => {
    async function callData(){
      await GET_AXIOS("requests").then((res) => {
        setInqOptions(formatWords(res.data, "name"));
      });
      await GET_AXIOS("roles").then((res) => {
        setRoleOptions(formatWords(res.data, "name"));
      });
      await GET_AXIOS("accompaniments/towns").then((res) => {
        setTownOptions(formatWords(res.data, "town_name"));
      });
      await GET_AXIOS("accompaniments/institutions").then((res) => {
        setAllInstitutions(formatWords(res.data, "inst_name"));
      });
      await GET_AXIOS("phoneTypes").then((res) => {
        const _data = formatWords(res.data, "name");
        _data.push({value: 5, label: null});
        setPhoneTypeOptions(_data);
      });
      await GET_AXIOS("accompaniments/users/agent").then((res) => {
        let data_ = res.data.filter((elem:any, index:any, self:any) => {
          return self.findIndex((t:any) => {
            return (t.id === elem.id && t.name === elem.name)
          }) === index});
        setAgentOptions(formatWords(data_, "name"));
      });
      await GET_AXIOS(`accompaniments/1?perPage=${sizePerPage}`).then((res) => {
        const data = res.data.data.map((el: any) => {
          let dateEle = new Date(el.created_at);
          el.dateTime =
            dateEle.getFullYear().toString().substr(2) +
            "/" +
            dateEle.getMonth() +
            "/" +
            dateEle.getDate() +
            " " +
            dateEle.toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            });
          el.caller_name = el.caller_name.toUpperCase();
          el.desc_request =
            el.desc_request &&
            el.desc_request.charAt(0).toUpperCase() + el.desc_request.slice(1);
          el.observations =
            el.observations &&
            el.observations.charAt(0).toUpperCase() + el.observations.slice(1);
          if (!el.phone_type_id) el.phone_type_id = 5;
          return el;
        });
        console.log(data);
        setProducts(data);
        setTotalSize(res.data.total);
      });
    }
    callData();
  }, []);

  const formatWords = (arr: Array<any>, keyInObj: String) => {
    const toReturn: any = [];
    arr.map((el: any) => {
      let word: string = "";
      el[`${keyInObj}`].split(" ").map((wr: any) => {
        if (wr) {
          word += wr.toUpperCase() + " ";
        }
      });
      word = word.trim();
      toReturn.push({ value: el.id, label: word });
    });
    return toReturn;
  };

  const handleTableChange = (
    type: any,
    { page, sizePerPage, filters, sortField, sortOrder = "asc" }: any
  ) => {
    const {
      request_id,
      role_id,
      town_id,
      institution_id,
      phone_type_id,
      agent_id,
      status,
    } = filters;
    console.log(status);
    if (town_id) {
      GET_AXIOS(`institutions/byTown/${town_id.filterVal}`).then((res) => {
        setInstitutionOptions(formatWords(res.data, "inst_name"));
      });
    } else {
      setInstitutionOptions([]);
    }

    const jsonPOST = {
      request_id: request_id ? request_id.filterVal : false,
      role_id: role_id ? role_id.filterVal : false,
      town_id: town_id ? town_id.filterVal : false,
      institution_id: institution_id ? institution_id.filterVal : false,
      phone_type_id: phone_type_id ? phone_type_id.filterVal : false,
      agent_id: agent_id ? agent_id.filterVal : false,
      page,
      quantPerPage: sizePerPage,
      columnOrder: sortField == "dateTime" ? "created_at" : "serial_code",
      orderType: sortOrder || "asc",
      status: status ? status.filterVal : false,
      initialStatus:1,
    };

    POST_AXIOS("accompaniments/filterAndSort", jsonPOST).then((res) => {
      const data = res.data.data.map((el: any) => {
        let dateEle = new Date(el.created_at);
        el.dateTime =
          dateEle.getFullYear().toString().substr(2) +
          "/" +
          dateEle.getMonth() +
          "/" +
          dateEle.getDate() +
          " " +
          dateEle.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          });
        el.caller_name = el.caller_name.toUpperCase();
        el.desc_request =
          el.desc_request &&
          el.desc_request.charAt(0).toUpperCase() + el.desc_request.slice(1);
        el.observations =
          el.observations &&
          el.observations.charAt(0).toUpperCase() + el.observations.slice(1);
        if (!el.phone_type_id) el.phone_type_id = 5;
        return el;
      });
      setProducts(data);
      setPage(page);
      setTotalSize(res.data.total);
      setSizePerPage(sizePerPage);
    });
  };

  //Metodo de boton de descarga 
  const downloadbuttonReport = ()=>{
    console.log("entre")
    let typeRol = '1'; 
    let link = `${process.env.REACT_APP_API_ENDPOINT}accompaniments/download/${typeRol}`;
    window.open(link, "_blank");
  }
  //fin metodo boton de descarga

  const handleSeeColumns = (field: any) => {
    const indexInColumns = columns.findIndex(
      (el: any) => el.dataField == field
    );
    const hiddenCols = hiddenColumns;
    let currentHidden = hiddenCols[indexInColumns];
    hiddenCols[indexInColumns] = !currentHidden;
    setHiddenColumns([...hiddenCols]);
  };

  const CustomToggleList = ({ columns, onColumnToggle, toggles }: any) => (
    <div
      className="list-altic mb-4 mt-2 btn-group btn-group-toggle w-100 mt-3"
      data-toggle="buttons"
    >
      {columns
        .map((column: any) => ({
          ...column,
          toggle: toggles[column.dataField],
        }))
        .map((column: any) => (
          <button
            type="button"
            key={column.dataField}
            className={`list-btn-custom-class btn btn-success ${
              column.toggle ? "active" : ""
            }`}
            data-toggle="button"
            aria-pressed={column.toggle ? "true" : "false"}
            onClick={() => handleSeeColumns(column.dataField)}
          >
            {column.text}
          </button>
        ))}
    </div>
  );

  return (
    <div>
        <div className="mt-2" style={{ position: "absolute", top: "82px" }}>
        <Link
          className="af-buttonHome d-flex justify-content-center p-2"
          to="/chooseContent"
        >
          <TiHomeOutline />
        </Link>
      </div>
      <div  className="container-fluid px-4">
      <Row className="mt-5 pt-2">
        <Col className="justify-content-center align-self-center text-center py-3">
          <div className="af-lineOrange"></div>
        </Col>
        <Col
          className="justify-content-center align-self-center text-center"
          md={3}
        >
          <h3 className="af-titleOrange m-0">
            <strong>Reporte Gestor TIC</strong>
          </h3>
        </Col>
        <Col className="justify-content-center align-self-center text-center py-3">
          <div className="af-lineOrange"></div>
        </Col>
      </Row>
    
      {/* <BootstrapTable keyField='id' data={products} columns={columns} filter={filterFactory()} /> */}
      <ToolkitProvider
        keyField="id"
        data={products}
        columns={columns}
        // @ts-ignore: Unreachable code error
        columnToggle
      >
        {(props) => (
          <div>
            <span className="my-2">
              A continuación, podrás visualizar los reportes generados por los
              Gestores TIC.
            </span>
            <br></br>
            <span>Selecciona las columnas que deseas consultar: </span>
            {/* <ToggleList
                            contextual="success"
                            className="list-altic mb-4 mt-2"
                            btnClassName="list-btn-custom-class"
                            {...props.columnToggleProps}
                            onColumnToggle={() => handleSeeColumns(props.columnToggleProps)}
                        /> */}
            <CustomToggleList
              contextual="success"
              {...props.columnToggleProps}
            />
            <hr />
            <BootstrapTable
              remote
              {...props.baseProps}
              filter={filterFactory()}
              // @ts-ignore: Unreachable code error
              noDataIndication="No hay Datos Aún"
              classes="table-altic"
              pagination={paginationFactory({ page, sizePerPage, totalSize })}
              onTableChange={handleTableChange}
            />
          </div>
        )}
      </ToolkitProvider>
      <div>
        <Button onClick={downloadbuttonReport} className="w-100 buttonDownloadReports">Descargar</Button>
      </div>
      </div>
    </div>
  );
}
