import React from "react";
import "../report.scss";

//impport elementos de react
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Title from "../../AdminUsers/Title/Title";
import DBA from "../../DBAReport/DBAReport";
import { GET_AXIOS } from "./../../funtionsREST";

//import imagenes
import IconInteractive from "../../../img/icon-interactive.svg";
import IconVideo from "../../../img/icon-video.svg";
import IconMen from "../../../img/icon-Men.svg";
import IconVirtual from "../../../img/icon-virtual.svg";
import IconButtonSend from "../../../img/icon-button-send.svg";
import IconMateEdu from "../../../img/icon-mateEdu.svg";

//import components and views
import LoadingPage from "../../spinner/loadingPage";

export interface Props {
  graphics: Array<string>;
  capitalize: any;
}

interface IState {
  grado: string;
  grupo: string;
  area: string;
  sede: string;
  grado2: string;
  grupo2: string;
  area2: string;
  sede2: string;
  showReportButton: boolean;
  showDiseableReportButton: boolean;
  showReport: boolean;
  showReportError: boolean;
  showNotGroup: boolean;
  hqs: Array<object>;
  grades: Array<object>;
  groups: Array<object>;
  knowledgeAreas: Array<object>;
  reports: Array<report>;
  disabledGrade: boolean;
  disableGroup: boolean;
  disableArea: boolean;
}

interface report {
  id: number;
  resource_type_name: string;
  totals: Array<object>;
}

export default class ReportRect extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    // No llames this.setState() aquí!
    this.state = {
      grado: "-1",
      grupo: "-1",
      area: "-1",
      sede: "-1",
      grado2: "-1",
      grupo2: "-1",
      area2: "-1",
      sede2: "-1",
      showReport: false,
      showReportError: false,
      showDiseableReportButton: false,
      showReportButton: false,
      showNotGroup: false,
      hqs: [],
      grades: [],
      groups: [],
      knowledgeAreas: [],
      reports: [],
      disabledGrade: false,
      disableGroup: false,
      disableArea: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.GenerateReport = this.GenerateReport.bind(this);
    this.handleSelectheadquarters = this.handleSelectheadquarters.bind(this);
    this.handleSelectGrade = this.handleSelectGrade.bind(this);
  }

  getValueFromArray(arr: Array<object>, idToSearch: string, toReturn: any) {
    const _ele: any = arr.find((el: any) => el.id == idToSearch);
    if (_ele) {
      return _ele[toReturn];
    }
  }

  handleInputChange(event: React.FormEvent<HTMLInputElement>) {
    const target = event.currentTarget;
    const value = target.value;
    const name = target.name;
    const me = this;
    this.setState({
      showReport: false,
      showReportButton: false,
      showDiseableReportButton: false,
      showReportError: false,
    });

    me.setState(
      (prevState) => ({
        ...prevState,
        [name]: value,
        showReportButton: true,
      }),
      () => {
        if (
          me.state.grado !== "-1" &&
          me.state.grupo !== "-1" &&
          me.state.sede !== "-1" &&
          me.state.area !== "-1"
        )
          me.setState({ showDiseableReportButton: true });
      }
    );
  }

  getGroups(_hq: any, _grade: any) {
    GET_AXIOS(`getByheadquarterGrade/${_hq}/${_grade}`).then((res) => {
      this.setState((prevState) => ({
        ...prevState,
        grupo: "-1",
        groups: [],

      }));
      const _data: Array<object> = res.data
        ? res.data.map((el: any) => ({ id: el, name: el }))
        : [];
      this.setState({ groups: _data, disableGroup: true });
    });
  }

  GenerateReport(event: React.FormEvent<HTMLFormElement>) {
    console.log("hola");
    const jsonToReport = {
      headquarter: this.state.sede,
      grade: this.state.grado,
      group: this.state.grupo,
      knowledge_area: this.state.area,
    };

    event.preventDefault();

    if (
      this.state.grado !== "-1" &&
      this.state.grupo !== "-1" &&
      this.state.sede !== "-1" &&
      this.state.area !== "-1"
    ) {
      GET_AXIOS(
        `stats/byGroupAreaGrade/${jsonToReport.grade}/${jsonToReport.headquarter}/${jsonToReport.group}/${jsonToReport.knowledge_area}`
      )
        .then((res) => {
          console.log(res.data);
          if (res.data !== undefined && res.data !== false) {
            this.setState({ reports: res.data }, () => {
              this.setState({
                grado2: jsonToReport.grade,
                grupo2: jsonToReport.group,
                area2: jsonToReport.knowledge_area,
                sede2: jsonToReport.headquarter,
                showReport: true,
                showReportError: false,
              });
            });
          } else {
            this.setState({
              showReport: false,
              showReportError: true,
            });
          }
        })
        .catch((e) => {
          console.log(e);
          this.setState({
            showReport: false,
            showReportError: true,
          });
        });
    }
  }

  generatePDF() {
    const { sede, grado, grupo, area } = this.state;

    window.open(
      `${process.env.REACT_APP_API_ENDPOINT}stats/generateReport?institution=1&headquarter=${sede}&grade=${grado}&group=${grupo}&knowledge_area=${area}`,
      "_blank"
    );
  }

  componentDidMount() {
    GET_AXIOS("users/fisrtLocChildren")
      .then((res) => {
        console.log(res);
        const _data: Array<object> = this.props.capitalize(res.data, "hq_name");
        this.setState({ hqs: _data });
      })
      .catch((e) => {
        console.log(e);
      });

  }

  handleSelectheadquarters(event: React.FormEvent<HTMLInputElement>) {
    const target = event.currentTarget;
    let value = target.value;
    console.log(value);

    this.setState({
      showReport: false,
      showReportButton: false,
      showDiseableReportButton: false,
      showReportError: false,
      sede: value,
    });

    if (value === "-1") {
      this.setState({ disabledGrade: false, disableArea: false, disableGroup: false });
      this.setState((prevState) => ({
        ...prevState,
        grado: "-1",
        grades: [],
        area: "-1",
        knowledgeAreas: [],
        grupo: "-1",
        groups: [],

      }));
    }

    if (value !== "-1") {
      GET_AXIOS(`grades/byHq/${value}`)
        .then((res) => {
          console.log(res);
          this.setState((prevState) => ({
            ...prevState,
            grado: "-1",
            grades: [],
            area: "-1",
            knowledgeAreas: [],
            grupo: "-1",
            groups: [],

          }));
          const _data: Array<object> = this.props.capitalize(
            res.data,
            "grade_name"
          );
          this.setState({ grades: _data, disabledGrade: true });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  handleSelectGrade(event: React.FormEvent<HTMLInputElement>) {
    const target = event.currentTarget;
    let value = target.value;

    this.setState({
      showReport: false,
      showReportButton: false,
      showDiseableReportButton: false,
      showReportError: false,
      grado: value,
    });

    if (value === "-1") {
      this.setState({ disableArea: false, disableGroup: false });
      this.setState((prevState) => ({
        ...prevState,
        grupo: "-1",
        groups: [],
        area: "-1",
        knowledgeAreas: [],
      }));
    }

    if (value !== "-1") {

      this.getGroups(this.state.sede, value);
      this.getAreas(value);
    }
  }

  getAreas(grades: any) {
    GET_AXIOS(`users/knowledgeAreas/${grades}`)
      .then((res) => {
        this.setState((prevState) => ({
          ...prevState,
          area: "-1",
          knowledgeAreas: [],
        }));
        const _data: Array<object> = this.props.capitalize(
          res.data,
          "knowledge_area_name"
        );
        this.setState({ knowledgeAreas: _data, disableArea: true });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  render() {
    let { hqs, grades, groups, knowledgeAreas, disabledGrade, disableGroup, disableArea } = this.state;

    return (
      <>
        <Row className="justify-content-center align-self-center text-center">
          <Row className="w-100  h-100 pt-5">
            <Col>
              <h2 className="title-report">ESTIMADO(A) RECTOR(A),</h2>
            </Col>
          </Row>
          <Row className="justify-content-center align-self-center text-center w-100  h-100">
            <Col
              className="justify-content-center align-self-center text-center p-0"
              md={12}
            >
              <p>
                En el siguiente informe encontrará el estado de avance en
                relación con el acceso y uso de los contenidos y recursos de los estudiantes de su institución educativa, según
                la sede, el ciclo, el grupo y el área consultada.
              </p>
            </Col>
          </Row>
          <Row className="w-100 mb-4">
            <Col className="d-flex justify-content-center">
              <div className="separator" />
            </Col>
          </Row>
          <Form className="container-fluid form-report-Infor" onSubmit={this.GenerateReport}>
            <Row className="justify-content-center align-self-center text-center">
              <Col
                className="justify-content-center align-self-center text-center"
                md={6}
              >
                <Form.Control
                  className="af-reportselect w-100 m-1"
                  name="sede"
                  value={this.state.sede}
                  defaultValue={"-1"}
                  onChange={this.handleSelectheadquarters}
                  as="select"
                  required
                >
                  <option value="-1">Escoge la sede</option>
                  {hqs.map((hq: any, i: number) => (
                    <option value={hq.id} key={i}>
                      {hq.hq_name.toUpperCase()}
                    </option>
                  ))}
                </Form.Control>
              </Col>
              <Col
                className="justify-content-center align-self-center text-center"
                md={6}
              >
                <Form.Control
                  className="af-reportselect w-100 m-1"
                  name="grado"
                  value={this.state.grado}
                  defaultValue={"-1"}
                  onChange={this.handleSelectGrade}
                  as="select"
                  required
                  disabled={disabledGrade === false}
                >
                  <option value="-1">Escoge el grado</option>
                  {grades.map((grade: any, i: number) => (
                    <option value={grade.id} key={i}>
                      {grade.grade_name.toUpperCase()}
                    </option>
                  ))}
                </Form.Control>
              </Col>
              <Col
                className="justify-content-center align-self-center text-center"
                md={6}
              >
                <Form.Control
                  className="af-reportselect w-100 m-1"
                  name="grupo"
                  value={this.state.grupo}
                  defaultValue={"-1"}
                  onChange={this.handleInputChange}
                  as="select"
                  required
                  disabled={disableGroup === false}
                >
                  <option value="-1">Escoge el grupo</option>
                  {groups.map((gr: any, i: number) => (
                    <option value={gr.id} key={i}>
                      {gr.name}
                    </option>
                  ))}
                </Form.Control>
              </Col>
              <Col
                className="justify-content-center align-self-center text-center"
                md={6}
              >
                <Form.Control
                  className="af-reportselect w-100 m-1"
                  name="area"
                  value={this.state.area}
                  defaultValue={"-1"}
                  onChange={this.handleInputChange}
                  as="select"
                  required
                  disabled={disableArea === false}
                >
                  <option value="-1">Escoge el área</option>
                  {knowledgeAreas.map((ka: any, i: number) => (
                    <option value={ka.id} key={i}>
                      {ka.knowledge_area_name.toUpperCase()}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col className="d-flex align-items-center justify-content-center generate-report">
                {this.state.showReportButton &&
                  (this.state.showDiseableReportButton === true ? (
                    <Button
                      variant="primary"
                      type="submit"
                      className="generate-report-button d-flex justify-content-center align-items-center"
                      block
                    >
                      Generar Reporte
                      <img
                        src={IconButtonSend}
                        alt=""
                        className="ml-3 icon-generate-report"
                      />
                    </Button>
                  ) : (
                      <Button
                        variant="primary"
                        type="submit"
                        className="generate-report-button d-flex justify-content-center align-items-center"
                        block
                        disabled
                      >
                        Generar Reporte
                        <img
                          src={IconButtonSend}
                          alt=""
                          className="ml-3 icon-generate-report"
                        />
                      </Button>
                    ))}
              </Col>
            </Row>
          </Form>
          {this.state.showNotGroup && (
            <Container>
              <Row>
                <Col className="" md={12}>
                  <h4>No hay grupos disponibles en este momento.</h4>
                </Col>
              </Row>
            </Container>
          )}
          {this.state.showReport && (
            <LoadingPage>
              <Container fluid>
                <Row>
                  <Col>
                    <Title>Informe</Title>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p>
                      En el siguiente informe usted encontrará el estado de avance
                      en relación con el acceso y uso de los contenidos y/o
                      recursos, según la sede, el grado, el grupo y el aréa
                      consultados.
                  </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <p className="text-center selected-type w-100 p-2">
                      Sede:{" "}
                      {this.getValueFromArray(
                        this.state.hqs,
                        this.state.sede2,
                        "hq_name"
                      )}
                    </p>
                  </Col>
                  <Col md={4}>
                    <p className="text-center selected-type w-100 p-2">
                      Grado:{" "}
                      {this.getValueFromArray(
                        this.state.grades,
                        this.state.grado2,
                        "grade_name"
                      )}
                    </p>
                  </Col>
                  <Col md={4}>
                    <p className="text-center selected-type w-100 p-2">
                      Grupo:{" "}
                      {this.getValueFromArray(
                        this.state.groups,
                        this.state.grupo2,
                        "name"
                      )}
                    </p>
                  </Col>
                  <Col md={4}>
                    <p className="text-center selected-type w-100 p-2">
                      Área:{" "}
                      {this.getValueFromArray(
                        this.state.knowledgeAreas,
                        this.state.area2,
                        "knowledge_area_name"
                      )}
                    </p>
                  </Col>
                </Row>
                {this.state.reports.filter((data: any) => data.id === 1)[0] &&
                  <Row className="mt-4 mb-2">
                    <Col className="d-flex subtitle-report">
                      <img src={IconVideo} alt="" className="icon-report" />
                      <h3>Videos:</h3>
                    </Col>
                  </Row>
                }
                {this.state.reports.filter((data: any) => data.id === 1)[0]
                  ? this.state.reports
                    .filter((data: any) => data.id === 1)[0]
                    .totals.map((value: any) => {
                      return (
                        <DBA
                          atribute="videos"
                          dba={value.dba}
                          total={value.total}
                          student={value.users}
                          type={1}
                        ></DBA>
                      );
                    })
                  : ""}
                {this.state.reports.filter((data: any) => data.id === 2)[0] &&
                  <Row className="mt-4 mb-2">
                    <Col className="d-flex subtitle-report">
                      <img src={IconInteractive} alt="" className="icon-report" />
                      <h3>Actividades interactivas:</h3>
                    </Col>
                  </Row>
                }
                {this.state.reports.filter((data: any) => data.id === 2)[0]
                  ? this.state.reports
                    .filter((data: any) => data.id === 2)[0]
                    .totals.map((value: any) => {
                      return (
                        <DBA
                          atribute="actividades"
                          dba={value.dba}
                          total={value.total}
                          student={value.users}
                          type={1}
                        ></DBA>
                      );
                    })
                  : ""}
                {this.state.reports.filter((data: any) => data.id === 3)[0] &&
                  <Row className="mt-4 mb-2">
                    <Col className="d-flex subtitle-report">
                      <img src={IconMen} alt="" className="icon-report" />
                      <h3>Recursos MEN:</h3>
                    </Col>
                  </Row>
                }
                {this.state.reports.filter((data: any) => data.id === 3)[0]
                  ? this.state.reports
                    .filter((data: any) => data.id === 3)[0]
                    .totals.map((value: any) => {
                      return (
                        <DBA
                          atribute="recursos"
                          dba={value.dba}
                          total={value.total}
                          student={value.users}
                          type={1}
                        ></DBA>
                      );
                    })
                  : ""}
                {this.state.reports.filter((data: any) => data.id === 4)[0] &&
                  <Row className="mt-4 mb-2">
                    <Col className="d-flex subtitle-report">
                      <img src={IconVirtual} alt="" className="icon-report" />
                      <h3>Aula virtual:</h3>
                    </Col>
                  </Row>
                }
                {this.state.reports.filter((data: any) => data.id === 4)[0]
                  ? this.state.reports
                    .filter((data: any) => data.id === 4)[0]
                    .totals.map((value: any) => {
                      return (
                        <DBA
                          atribute="contenidos"
                          dba={value.dba}
                          total={value.total}
                          student={value.users}
                          type={1}
                        ></DBA>
                      );
                    })
                  : ""}
					{this.state.reports.filter((data: any) => data.id === 6)[0] &&
					  <Row className="mt-4 mb-2">
						<Col className="d-flex subtitle-report">
						  <img src={IconVirtual} alt="" className="icon-report" />
						  <h3>Modelos Test:</h3>
						</Col>
					  </Row>
					}
					{this.state.reports.filter((data: any) => data.id === 6)[0]
					  ? this.state.reports
						.filter((data: any) => data.id === 6)[0]
						.totals.map((value: any) => {
						  return (
							<DBA
							  atribute="contenidos"
							  dba={value.dba}
							  total={value.total}
							  student={value.users}
							  type={1}
							></DBA>
						  );
						})
					  : ""}

                {this.state.reports.filter((data: any) => data.id === 5)[0] &&
                  <Row className="mt-4 mb-2">
                    <Col className="d-flex subtitle-report">
                      <img src={IconMateEdu} alt="" className="icon-report" />
                      <h3>Materiales Educativos:</h3>
                    </Col>
                  </Row>
                }
                {this.state.reports.filter((data: any) => data.id === 5)[0]
                  ? this.state.reports
                    .filter((data: any) => data.id === 5)[0]
                    .totals.map((value: any) => {
                      return (
                        <DBA
                          atribute="contenidos"
                          dba={value.dba}
                          total={value.total}
                          student={value.users}
                          type={1}
                        ></DBA>
                      );
                    })
                  : ""}

                <Row className="my-4">
                  <Col md={12}>
                    <Button
                      variant="primary"
                      type="button"
                      className="Butoon-generate-report"
                      block
                      onClick={() => this.generatePDF()}
                    >
                      Para generar el reporte detallado por estudiante haz clic
                      aquí.
                  </Button>
                  </Col>
                </Row>
              </Container>
            </LoadingPage>
          )}
          {this.state.showReportError && (
            <Container className="mt-4" fluid>
              <Row>
                <Col className="" md={12}>
                  <h4>No hay reporte disponibles en este momento.</h4>
                </Col>
              </Row>
            </Container>
          )}
        </Row>
      </>
    );
  }
}
