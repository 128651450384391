import React from "react";
import "./selfManagement.scss";

//import componentes y vistas
import FormSelfManagement from "../../components/formSelfManagement/FormSelfManagement";

//import elmentos de react
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

// import axios from 'axios';

//import imagenes
import { TiHomeOutline } from "react-icons/ti";

export interface Props {
  history: any;
  rol: any;
}

export default class SelfManagement extends React.Component<Props, {}> {
  ref = React.createRef<HTMLDivElement>();
  componentWillMount() {
    // compare if user's role is secretaria, docente or Rect
    // if it's none, redirect to loginPage
    if (this.props.rol === "coor_m") {
    } else {
      this.props.history.push("/loginPage");
    }
  }

  render() {
    return (
      <div ref={this.ref} className="container pt-5">
        <Link
          className="af-buttonHomeIntroduction d-flex justify-content-center p-2 position-absolute mt-4"
          to="/chooseContent"
          style={{ left: 0, top: "1.5em" }}
        >
          <TiHomeOutline />
        </Link>
        <h2 className="af-titleOrange">
          <strong>BIENVENIDO(A), COORDINADOR(A) DE MUNICIPIO - GESTOR(A) TIC:</strong>
        </h2>
        <Row className="p-3">
          <p>
            A continuación, podrás acceder al formato para el registro de las
            acciones de acompañamiento que Usted gestionará y desarrollará, en
            el marco de la atención educativa a los docentes responsables del
            proceso formativo y los estudiantes jóvenes extraedad y adultos
            beneficiarios, del acompañamiento a la implementación del Modelo
            Educativo Flexible Talentum, en el Valle del Cauca.
          </p>
        </Row>
        <Row>
          <Col className="justify-content-center align-self-center text-center py-3">
            <div className="af-lineOrange"></div>
          </Col>
          <Col
            className="justify-content-center align-self-center text-center p-0"
            md={2}
          >
            <h5 className="af-titleOrange mb-0">
              <strong>Autogestión</strong>
            </h5>
          </Col>
          <Col className="justify-content-center align-self-center text-center py-3">
            <div className="af-lineOrange"></div>
          </Col>
        </Row>
        <FormSelfManagement refToPrint={this.ref}></FormSelfManagement>
      </div>
    );
  }
}
