import React from 'react';
import './lessons.scss';

//impport elementos de react
import { Row, Col, Button, Image } from 'react-bootstrap';

//import imagenes
import { ReactComponent as AulaIcon } from "./../../img/iconSvg/logoListaLec.svg";
import aulaVirtual from '../../img/imgResources/aulaVirtual.png';

//import comoponentes y vistas
import { POST_AXIOS } from "../../components/funtionsREST";


export interface Props { rol: any, url: any }

export default class Lessons extends React.Component<Props, { text: string }> {


    constructor(props: any) {
        super(props);
        this.state = {
            text: '',
        };
    }
    componentDidMount() {
        this.textRol(this.props.rol);
    }

    textRol(rol: any) {
        if (rol === 'est') {
            this.setState({
                text: 'Espacio educativo donde los estudiantes podrán acceder a contenidos propios de las áreas y grados, para desarrollar actividades académicas, mediante el uso de herramientas que soportan y facilitan sus procesos de aprendizaje.'
            })
        } else {
            this.setState({
                text: 'Espacio educativo donde los estudiantes podrán acceder a contenidos propios de las áreas y grados, para desarrollar actividades académicas, mediante el uso de herramientas que soportan y facilitan sus procesos de aprendizaje.'

            })
        }
    }

    //funcion para post de conteo de progreso para video
    postProgressResource() {
        let dataResource = {
            resource_id: 4,
        };
        POST_AXIOS("users/newResource", dataResource)
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
            });
    }


    render() {

        //---------------------------------------------------------------------------------
        return (
            <div >
                <Row className="w-100 m-0 h-100 pt-5">
                    <Col className="justify-content-center align-self-center text-center p-3" md={4}>
                        <div className="af-lineOrange"></div>
                    </Col>
                    <Col className=" m-0 p-0" md={4}>
                        <h2 className="af-tittleLesson">Aula Virtual</h2>
                    </Col>

                    <Col className="justify-content-center align-self-center text-center p-3" md={4}>
                        <div className="af-lineOrange"></div>
                    </Col>
                </Row>
                <Row className="justify-content-center align-self-center text-center">
                    <p className="af-textComponent p-4">{this.state.text}</p>

                    <Col md={12}>
                        <Image className="w-100" src={aulaVirtual}></Image>
                    </Col>
                    <Col className="p-3" md={9}>
                        <Button className="af-buttonAula text-left" href={this.props.url}
                            onClick={this.postProgressResource}
                        >
                            <AulaIcon className="af-imgButtonAula mx-2" />
                            Ingresa al Aula Virtual haciendo clic aquí.</Button>
                    </Col>

                </Row>
            </div>
        )
    }


}