import React from "react";
import "./changePass.scss";

//import elmentos de react
import { Row, Col, Button, Image, Form, FormControl, FormGroup} from "react-bootstrap";

//import imagenes
import PassCheckIcon from "./../../img/pass_check_icon.png";
import PassQuestIcon from "./../../img/pass_question_icon.png";
import PassIcon from "./../../img/pass_icon.png";

//import componentes y vistas
import FootPages from "../../components/footPage/footPage";
import ModalGeneral from "../../components/modalGeneral/modalGeneral";

// import funtions REST
import { POST_AXIOS } from "../../components/funtionsREST";


export default class ChangePass extends React.Component<{},{validated:boolean, url:string, textModal:string, tittleModal:string, show:boolean, oldPass:string, newPass:string, confiNewPass:string}>{

  constructor(props: any) {
    super(props);
    this.state = {
      oldPass:"",
      newPass:"",
      confiNewPass:"",
      show: false,
      tittleModal:"",
      textModal:"",
      url:"",
      validated:false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose=this.handleClose.bind(this);
  }
  
  handleClose = () => this.setState({ show: false }); //    Metodo para cerrar modal
//controlador del formulario
  async handleSubmit(e:any){
    e.preventDefault();
    const form = e.currentTarget;
    console.log(form.checkValidity());
    //if para mirar si le usuario ha llenado todos los campos
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      //------------------------
    }else {
      let response= await this.postChangePass();
      if(response !== false){
        this.setState({
          show:true,
          tittleModal:"Confirmación cambio de contraseña",
        textModal:"su contraseña ha sido cambiada con exito"});
        this.acceptModal(true)
  
      }else{
        this.setState({
          show:true,
          tittleModal:"Confirmación cambio de contraseña",
          textModal:"su contraseña no se ha podido cambiar, vuelva a intentarlo"});
          this.acceptModal(false)
      }
    }

      this.setState({validated:true})
  }

  //Metodo para indicar la acción del botón aceptar del modal
  acceptModal(res:any){
    console.log('entre')
  if(res!==false){
    this.setState({url:"/chooseContent"});
  }else{
    this.setState({url:"/changePass"});

  }
  }

  //metodos para optenes los datos de los inputs
  setOldPassword(e:any){
    this.setState({oldPass:e});
  }
  setNewPassword(e:any){
  this.setState({newPass:e});
  }
  setConfiNewPassword(e:any){
    this.setState({confiNewPass:e});
  }

  //post para cambiar contraseña
  async postChangePass(){
    const jsonPostChangePass={
      old_password:this.state.oldPass,
      password:this.state.newPass,
      password_confirmation:this.state.confiNewPass,
    }
    let res= await POST_AXIOS("users/updatePassword", jsonPostChangePass);
    if (res !== false) {
      return res.data;
    } else {
      return res; 
    }
  }


render(){
  const {validated, oldPass, url, newPass,textModal, confiNewPass, show, tittleModal}=this.state
    return(
        <div >
        <div className="container-fluid w-75 af-pageChangePass">
        <Row className="justify-content-center align-self-center text-center m-0 pt-5">
            <Col
              className="justify-content-center align-self-center text-center py-3"
              md={4}
            >
              <div className="af-lineOrange"></div>
            </Col>
            <Col
              className="justify-content-center align-self-center text-center"
              md={4}
            >
              <h3 className="af-titleOrange">
                <strong>Cambiar Contraseña</strong>
              </h3>
            </Col>
            <Col
              className="justify-content-center align-self-center text-center py-3"
              md={4}
            >
             <div className="af-lineOrange"></div>
            </Col>
              </Row>
              <Row className="justify-content-center align-self-center text-center">
                  <Col md={9}>
                  <p>En esta sección usted podra cambiar su contraseña, para ello debe llenar los campos mostrados a continuación.</p>
                  </Col>
              </Row>

              <Form noValidate validated={validated} onSubmit={this.handleSubmit}>
              <Row className="justify-content-center align-self-center text-center af-inputChangePass">
              <Row className="w-100 justify-content-center align-self-center text-center">
              <Col md={6} className="d-flex justify-content-center">
              <FormGroup className="w-100">

                <div className="af-inputIcon">
                  <Image src={PassIcon} />
                </div>
                <FormControl
                  type="password"
                  placeholder="Escriba su contraseña anterior"
                  className="af-inputs"
                  value={oldPass}
                  onChange={(e: any) =>
                    this.setOldPassword(e.target.value)
                  }
                  name="checkPassword"
                  required
                />
                 <Form.Control.Feedback type="invalid">
                  Debe llenar este campo  
                 </Form.Control.Feedback>
                </FormGroup>
              </Col>
              </Row>
              <Row className="w-100 justify-content-center align-self-center text-center">
              <Col md={6} className="d-flex justify-content-center">
              <FormGroup className="w-100">
                <div className="af-inputIcon">
                  <Image src={PassQuestIcon} />
                </div>
                <FormControl
                  type="password"
                  placeholder="Cree su nueva contraseña"
                  value={newPass}
                  onChange={(e: any) =>
                    this.setNewPassword(e.target.value)
                  }
                  className="af-inputs"
                  name="checkPassword"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Debe llenar este campo  
                 </Form.Control.Feedback>
                </FormGroup>
              </Col>
              </Row>
              <Row className="w-100 justify-content-center align-self-center text-center">

              <Col md={6} className="d-flex justify-content-center">
              <FormGroup className="w-100">

                <div className="af-inputIcon">
                  <Image src={PassCheckIcon} />
                </div>
                <FormControl
                  type="password"
                  placeholder="Confirme su nueva contraseña"
                  className="af-inputs"
                  name="checkPassword"
                  value={confiNewPass}
                  onChange={(e: any) =>
                    this.setConfiNewPassword(e.target.value)
                  }
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Debe llenar este campo  
                 </Form.Control.Feedback>
                </FormGroup>
              </Col>
             </Row>
              <Col className="pt-3">    
              <Button
                    className="af-buttonLogin w-25"
                    type="submit"
                  >
                  Guardar
                  </Button >
              </Col>
              </Row>
              </Form>

              </div>
        <FootPages></FootPages>

      {/* modal de confirmación */}
      {show && <ModalGeneral 
      show={show}
      tittle={tittleModal} 
      text={textModal} 
      handleClose={this.handleClose}
      url={url}
      />}

        </div>
    )
}

}
