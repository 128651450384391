import React from "react";

//import componentes y vistas
import FormFinalSelfManagement from "../../components/formFinalSelfManagement/formFinalSelfManagement";

//import elmentos de react
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

// import axios from 'axios';

//import imagenes
import { TiHomeOutline } from "react-icons/ti";

export interface Props {
  history: any;
  rol: any;
}

export default class FinalSelfManagement extends React.Component<Props, {}> {
  ref = React.createRef<HTMLDivElement>();

  componentWillMount() {
    // compare if user's role is secretaria, docente or Rect
    // if it's none, redirect to loginPage
    console.log(this.props.rol);
    if (this.props.rol === "coor_op") {
    } else {
      this.props.history.push("/loginPage");
    }
  }

  render() {
    return (
      <div className="container">
        <div className="mt-4">
          <Link
            className="af-buttonHomeIntroduction d-flex justify-content-center p-2 position-absolute mt-4"
            to="/chooseContent"
            style={{ left: 0, top: "1.5em" }}
          >
            <TiHomeOutline />
          </Link>
        </div>
        <Row>
          <Col
            className="justify-content-center align-self-center text-center pt-5"
            md={12}
          >
            <h3 className="af-titleOrange">
              <strong>BIENVENIDO(A), COORDINADOR(A) OPERATIVO:</strong>
            </h3>
          </Col>
        </Row>
        <Row className="justify-content-center align-self-center text-center p-3">
          <Col md={9}>
            <p>
              A continuación, podrás acceder al formato para el registro de las
              acciones de acompañamiento que Usted gestionará y desarrollará, en
              el marco de la atención educativa a los docentes responsables del
              proceso formativo y los estudiantes jóvenes extraedad y adultos
              beneficiarios del acompañamiento a la implementación del Modelo
              Educativo Flexible Talentum, en el Valle del Cauca.
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center align-self-center text-center align-items-center">
          <Col>
            <div className="af-lineOrange"></div>
          </Col>
          <Col md={4}>
            <h6 className="af-titleOrange m-0">
              <strong>Estado Final de Monitoreo: <br /> Reporte de Acompañamiento</strong>
            </h6>
          </Col>
          <Col>
            <div className="af-lineOrange"></div>
          </Col>
        </Row>
        <FormFinalSelfManagement refToPrint={this.ref}></FormFinalSelfManagement>
      </div>
    );
  }
}
