import React, { useState, useEffect } from "react";
import { Row, Col, Button, Image } from "react-bootstrap";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import filterFactory, {
  textFilter,
  selectFilter,
  Comparator,
} from "react-bootstrap-table2-filter";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import ToolkitProvider, { ColumnToggle } from "react-bootstrap-table2-toolkit";
import { TiHomeOutline } from "react-icons/ti";
import { Link } from "react-router-dom";
import {
  POST_AXIOS,
  GET_AXIOS,
} from "../../components/funtionsREST";

import FootPages from "../../components/footPage/footPage";
import decorationBacgroundUpper from "../../img/decorationBacgroundUpperContent.png";
import decorationBacgroundDown from "../../img/decorationDownContent.png";

export default function FinalSelfManagementReport() {
  const { ToggleList } = ColumnToggle;
  const [townOptions, setTownOptions]: any = useState([
    { value: 0, label: "CALI" },
  ]);


  const [allInstitutions, setAllInstitutions]: any = useState([
    { value: 0, label: "IE ARTURO GOMEZ JARAMILLO" },
    { value: 1, label: "IE SAN JOSE" },
    { value: 3, label: "IE AGRICOLA CAMPOALEGRE" },
  ]);
  const [institutionOptions, setInstitutionOptions]: any = useState([]);
  const [CoordOpOptions, setCoordOpOptions]: any = useState([
    { value: 0, label: "None" },
  ]);
  const [products, setProducts]: any = useState([
    {
      serialCode: "000",
      dateTime: "01/01/00 12:00 AM",
      town_id: 0,
      institution_id: 1,
      conclusion: "Esta es la conclusión",
      coord_op_id: 0,
    },
  ]);
  const [page, setPage]: any = useState(1);
  const [sizePerPage, setSizePerPage]: any = useState(10);
  const [totalSize, setTotalSize]: any = useState(products.length);
  const [hiddenColumns, setHiddenColumns]: any = useState([
    false, false, false, false, false, false
  ]);

  let columns = [
    {
      dataField: "serial_code",
      text: "Código serial",
      hidden: hiddenColumns[0],
      sort: true,
    },
    {
      dataField: "dateTime",
      text: "Fecha y hora",
      hidden: hiddenColumns[1],
      sort: true,
    },
    {
      dataField: "town_id",
      text: "Municipio",
      hidden: hiddenColumns[2],
      sort: true,
      formatter: (cell: number) =>
        townOptions.find((opt: any) => opt.value === cell).label || "",
      filter: selectFilter({
        options: townOptions,
      }),
    },
    {
      dataField: "institution_id",
      text: "Institución",
      hidden: hiddenColumns[3],
      sort: true,
      formatter: (cell: number) =>
        allInstitutions.find((opt: any) => opt.value === cell).label || "",
      filter: selectFilter({
        options: institutionOptions,
      }),
    },
    {
      dataField: "conclusion",
      text: "Validación del proceso",
      hidden: hiddenColumns[4],
      sort: true,
    },
    {
      dataField: "coord_op_id",
      text: "Coordinador operativo",
      hidden: hiddenColumns[5],
      sort: true,
      formatter: (cell: number) =>
        CoordOpOptions.find((opt: any) => opt.value === cell).label || "",
      filter: selectFilter({
        options: CoordOpOptions,
      }),
    },
  ];

  useEffect(() => {
    async function callData() {
      await GET_AXIOS("selfManagements/towns").then((res) => {
        setTownOptions(formatWords(res.data, "town_name"));
      });
      await GET_AXIOS("selfManagements/institutions").then((res) => {
        setAllInstitutions(formatWords(res.data, "inst_name"));
      });
      await GET_AXIOS("selfManagements/users?userType=coord_op_id").then((res) => {
        setCoordOpOptions(formatWords(res.data, "name"));
      });
      await GET_AXIOS(`selfManagements/5?perPage=${sizePerPage}`).then((res) => {
        const data = res.data.data.map((el: any) => {
          let dateEle = new Date(el.created_at);
          el.dateTime =
            dateEle.getFullYear().toString().substr(2) +
            "/" +
            dateEle.getMonth() +
            "/" +
            dateEle.getDate() +
            " " +
            dateEle.toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            });
          el.conclusion =
            el.conclusion &&
            el.conclusion.charAt(0).toUpperCase() + el.conclusion.slice(1);
          return el;
        });
        setProducts(data);
        setTotalSize(res.data.total);
      });
    }
    callData();
  }, []);

  const formatWords = (arr: Array<any>, keyInObj: String) => {
    const toReturn: any = [];
    arr.map((el: any) => {
      let word: string = "";
      el[`${keyInObj}`].split(" ").map((wr: any) => {
        if (wr) {
          word += wr.toUpperCase() + " ";
        }
      });
      word = word.trim();
      toReturn.push({ value: el.id, label: word });
    });
    return toReturn;
  };

  //Metodo de boton de descarga 
  const downloadbuttonReport = () => {
    console.log("entre")
    let typeRol = '1';
    let link = `${process.env.REACT_APP_API_ENDPOINT}selfManagements/download/${typeRol}`;
    window.open(link, "_blank");
  }
  //fin metodo boton de descarga

  const handleTableChange = (
    type: any,
    { page, sizePerPage, filters, sortField, sortOrder = "asc" }: any
  ) => {
    const {
      town_id,
      institution_id,
      user_id,
    } = filters;

    if (town_id) {
      GET_AXIOS(`institutions/byTown/${town_id.filterVal}`).then((res) => {
        setInstitutionOptions(formatWords(res.data, "inst_name"));
      });
    } else {
      setInstitutionOptions([]);
    }

    const jsonPOST = {
      town_id: town_id ? town_id.filterVal : false,
      institution_id: institution_id ? institution_id.filterVal : false,
      user_id: user_id ? user_id.filterVal : false,
      page,
      quantPerPage: sizePerPage,
      columnOrder: sortField == "dateTime" ? "created_at" : "serial_code",
      orderType: sortOrder || "asc",
      status: 5,
      initialStatus: 5,
    };

    POST_AXIOS("selfManagements/filterAndSort", jsonPOST).then((res) => {
      const data = res.data.data.map((el: any) => {
        let dateEle = new Date(el.created_at);
        el.dateTime =
          dateEle.getFullYear().toString().substr(2) +
          "/" +
          dateEle.getMonth() +
          "/" +
          dateEle.getDate() +
          " " +
          dateEle.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          });
        el.conclusion =
          el.conclusion &&
          el.conclusion.charAt(0).toUpperCase() + el.conclusion.slice(1);
        return el;
      });
      setProducts(data);
      setPage(page);
      setTotalSize(res.data.total);
      setSizePerPage(sizePerPage);
    });
  };

  const handleSeeColumns = (field: any) => {
    const indexInColumns = columns.findIndex(
      (el: any) => el.dataField == field
    );
    const hiddenCols = hiddenColumns;
    let currentHidden = hiddenCols[indexInColumns];
    hiddenCols[indexInColumns] = !currentHidden;
    setHiddenColumns([...hiddenCols]);
  };

  const CustomToggleList = ({ columns, onColumnToggle, toggles }: any) => (
    <div
      className="list-altic mb-4 mt-2 btn-group btn-group-toggle w-100 mt-3"
      data-toggle="buttons"
    >
      {columns
        .map((column: any) => ({
          ...column,
          toggle: toggles[column.dataField],
        }))
        .map((column: any) => (
          <button
            type="button"
            key={column.dataField}
            className={`list-btn-custom-class btn btn-success ${column.toggle ? "active" : ""
              }`}
            data-toggle="button"
            aria-pressed={column.toggle ? "true" : "false"}
            onClick={() => handleSeeColumns(column.dataField)}
          >
            {column.text}
          </button>
        ))}
    </div>
  );

  return (
    <div>
      <Image className="af-backImageUpper" src={decorationBacgroundUpper} style={{ position: "relative", zIndex: -1 }} />
      <div className="mt-2" style={{ position: "absolute", top: "82px" }}>
        <Link
          className="af-buttonHome d-flex justify-content-center p-2"
          to="/chooseContent"
        >
          <TiHomeOutline />
        </Link>
      </div>
      <div className="container-fluid px-4">
        <Row className="mt-5 pt-2">
          <Col
            className="justify-content-center align-self-center text-center py-3"
            md={4}
          >
            <div className="af-lineOrange"></div>
          </Col>
          <Col
            className="justify-content-center align-self-center text-center"
            md={4}
          >
            <h3 className="af-titleOrange">
              <strong>Estado Final Reporte de Acompañamiento</strong>
            </h3>
          </Col>
          <Col
            className="justify-content-center align-self-center text-center py-3"
            md={4}
          >
            <div className="af-lineOrange"></div>
          </Col>
        </Row>

        {/* <BootstrapTable keyField='id' data={products} columns={columns} filter={filterFactory()} /> */}
        <ToolkitProvider
          keyField="id"
          data={products}
          columns={columns}
          // @ts-ignore: Unreachable code error
          columnToggle
        >
          {(props) => (
            <div>
              <span className="my-2">
                A continuación, podrás visualizar los reportes generados por los Coordinadores operativos en el proceso de validación de las acciones desplegadas por los Coordinadores de Municipio en relación con el tratamiento de las necesidades de soporte y acompañamiento identificadas durante su interacción con los docentes, estudiantes y directivos docentes.
              </span>
              <br></br>
              <span>Selecciona las columnas que desea consultar: </span>
              {/* <ToggleList
                            contextual="success"
                            className="list-altic mb-4 mt-2"
                            btnClassName="list-btn-custom-class"
                            {...props.columnToggleProps}
                            onColumnToggle={() => handleSeeColumns(props.columnToggleProps)}
                        /> */}
              <CustomToggleList
                contextual="success"
                {...props.columnToggleProps}
              />
              <hr />
              <BootstrapTable
                remote
                {...props.baseProps}
                filter={filterFactory()}
                // @ts-ignore: Unreachable code error
                noDataIndication="No hay Datos Aún"
                classes="table-altic"
                pagination={paginationFactory({ page, sizePerPage, totalSize })}
                onTableChange={handleTableChange}
              />
            </div>
          )}
        </ToolkitProvider>
        <div>
          <Button onClick={downloadbuttonReport} className="w-100 buttonDownloadReports">Descargar</Button>
        </div>
      </div>
      <Image className="af-backImageDown" src={decorationBacgroundDown} />
      <FootPages></FootPages>
    </div>
  );
}
