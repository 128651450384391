import React from 'react';


//impport elementos de react
import {Image} from 'react-bootstrap';

export interface Props { tittle: string; src: string; desc:string; url:string}

export default class SubComponent2 extends React.Component<Props,{}> {
    render() {
        return (
            <div>
                
                    <div className="mb-2">
                           <a href={this.props.url} target="_blank" rel="noopener noreferrer" ><Image className="w-100" src={this.props.src}/></a>
                    </div>
                    <div>
                    <p className="af-textLesson"><strong>{this.props.tittle} </strong>{this.props.desc}</p>
                    </div>
            </div>
        )
    }
}