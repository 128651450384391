import React, { useState } from 'react';
import './completeSignupSection.scss'
import { Form, Container, Row, Col, FormControl } from 'react-bootstrap';

interface Props {
  title: string, options: Array<{ id: number, name: string }>, others?: Array<{ id: number, name: string }>
  type: any, id: string, select: any
}

const CompleteSignupSection = ({ title, options, others, type, id, select }: Props) => {
  const defaultValue = '0:-1'
  const [selectedOthers, setSelectedOthers] = useState([defaultValue])

  const handleChange = (e: any) => {
    select(e.target.value)
  }

  const handleDropdownSelection = (e: any) => {
    const [index, value] = e.target.value.split(':')
    let currentSelectedOthers = [...selectedOthers]
    const selectedIndex = currentSelectedOthers.findIndex(x => x == value)

    if (value == -1) {
      currentSelectedOthers.splice(index, 1)
    }
    else if (selectedIndex === -1) {
      currentSelectedOthers[index] = value
    }
    if (index == currentSelectedOthers.length - 1 && selectedIndex === -1 && value != -1) {
      setSelectedOthers([...currentSelectedOthers, defaultValue])
    }
    else {
      setSelectedOthers(currentSelectedOthers)
    }
  }

  const removeDropdownSelection = (e: any) => {
    const val = e.target.value
    let currentSelectedOthers = [...selectedOthers]
    if (currentSelectedOthers.length > 1) {
      const index = currentSelectedOthers.findIndex(x => x == val)
      currentSelectedOthers.splice(index, 1)
    }
    else {
      currentSelectedOthers[0] = defaultValue
    }
    setSelectedOthers(currentSelectedOthers)
  }


  return (
    <div className="af-completeSignupSectionContainer">
      <h5>{title}</h5>
      <Container fluid /* style={{ padding: "3em" }} */>
        <Row>
          {
            select !== null ? options.map((opt, i) => (
              <Col md={2} key={i}>
                <Form.Check
                  custom
                  className='sectionCheckbox'
                  type={type}
                  name={type === 'radio' ? `${id}` : `${id}-${opt.id}`}
                  value={opt.id}
                  id={`${opt.id}-${id}`}
                  label={`${opt.name}`}
                  onSelect={() => alert('a')}
                  onChange={(e: any) => handleChange(e)}
                />
                {}
              </Col>
            )) : options.map((opt, i) => (
              <Col md={2} key={i}>
                <Form.Check
                  custom
                  className='sectionCheckbox'
                  type={type}
                  name={type === 'radio' ? `${id}` : `${id}-${opt.id}`}
                  value={opt.id}
                  id={`${opt.id}-${id}`}
                  label={`${opt.name}`}
                  onSelect={() => alert('a')}
                // onChange={handleChange}
                />
                {}
              </Col>
            ))
          }
        </Row>
        {
          others !== undefined ?
            <div>
              <h6 className='af-others mb-n4'>Otras Áreas</h6>
              <Row className="mt-0">
                {

                  selectedOthers.map((val, i) => (
                    <Col md={4} key={i + '' + val} className="d-flex jutify-content-center align-items-center mt-2">
                      <Form.Check
                        custom
                        className='sectionCheckbox hidden-label'
                        type={type}
                        name={`${i}-${val}`}
                        value={val}
                        id={`${i}-${val}`}
                        label={`.`}
                        onSelect={() => alert('a')}
                        defaultChecked={val !== defaultValue}
                        onClick={removeDropdownSelection}
                      />
                      <FormControl
                        onChange={handleDropdownSelection}
                        className='others'
                        name={id + '-' + val}
                        as="select"
                        value={i + ':' + val}
                      >
                        <option value={i + ':' + -1}>
                          Agregar Otras Áreas
                    </option>
                        {
                          others.map((opt, j) => (
                            <option
                              key={j}
                              value={i + ':' + opt.id}
                            >{opt.name}</option>
                          ))
                        }
                      </FormControl>
                    </Col>
                  ))
                }
              </Row>
            </div>
            : ''
        }

      </Container>
    </div>
  )
}

export default CompleteSignupSection