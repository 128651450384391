import React, { useState, useEffect } from "react";
import "./selfManagementReport.scss";
import { Row, Col, Button, Image } from "react-bootstrap";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import filterFactory, {
  textFilter,
  selectFilter,
  Comparator,
} from "react-bootstrap-table2-filter";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import ToolkitProvider, { ColumnToggle } from "react-bootstrap-table2-toolkit";
import { TiHomeOutline } from "react-icons/ti";
import { Link } from "react-router-dom";
import {
  POST_AXIOS,
  GET_AXIOS,
  PUT_AXIOS,
} from "../../components/funtionsREST";

import FootPages from "../../components/footPage/footPage";
import decorationBacgroundUpper from "../../img/decorationBacgroundUpperContent.png";
import decorationBacgroundDown from "../../img/decorationDownContent.png";

export default function SelfManagementReport() {
  const { ToggleList } = ColumnToggle;
  const [townOptions, setTownOptions]: any = useState([
    { value: 0, label: "CALI" },
  ]);
  const [allInstitutions, setAllInstitutions]: any = useState([
    { value: 0, label: "IE ARTURO GOMEZ JARAMILLO" },
    { value: 1, label: "IE SAN JOSE" },
    { value: 3, label: "IE AGRICOLA CAMPOALEGRE" },
  ]);
  const [institutionOptions, setInstitutionOptions]: any = useState([]);
  const [allHeadquarters, setAllHeadquarters]: any = useState([
    { value: 0, label: "ARTURO GOMEZ JARAMILLO" },
  ]);
  // const [headquartersOptions, setHeadquartersOptions]: any = useState([]);
  const [cyclesOptions, setCyclesOptions]: any = useState([
    { value: null, label: "None" },
  ]);
  // const [kAreasOptions, setKAreasOptions]: any = useState([
  //   {value: 0, label: 'None'}
  // ])
  const [roles, setRoles]: any = useState([
    { value: 0, label: "None" }
  ])
  const [accompanimentTypes, setAccompanimentTypes]: any = useState([
    { value: 0, label: 'None' }
  ])
  const [CoordMOptions, setCoordMOptions]: any = useState([
    { value: 0, label: "None" },
  ]);

  const [statusOptions, setStatusOptions]: any = useState([
    { value: 2, label: "EN TRÁMITE" },
    { value: 3, label: "FINALIZADO" },
  ]);

  const [products, setProducts]: any = useState([
    {
      serialCode: "000",
      dateTime: "01/01/00 12:00 AM",
      town_id: 0,
      institution_id: 1,
      headquarter_id: 0,
      teacher_national_id: "01",
      teacher_name: "Profesor",
      grade_id: null,
      knowledge_area_id: 0,
      accompaniment_type_id: 0,
      accompaniment_description: "No hay datos disponibles en este momento.",
      accompaniment_approach: "No hay datos disponibles en este momento.",
      achievements: "No hay datos disponibles en este momento.",
      improvement_opportunities: "No hay datos disponibles en este momento.",
      user_id: 0,
      status: 0,
    },
  ]);
  const [page, setPage]: any = useState(1);
  const [sizePerPage, setSizePerPage]: any = useState(10);
  const [totalSize, setTotalSize]: any = useState(products.length);
  const [hiddenColumns, setHiddenColumns]: any = useState([
    true,
    false,
    false,
    false,
    true,
    true,
    true,
    true,
    true,
    // true,
    true,
    true,
    true,
    true,
    true,
    false,
  ]);

  let columns = [
    {
      dataField: "status",
      text: "Estado",
      hidden: hiddenColumns[0],
      sort: true,
      formatter: (cell: number) =>
        statusOptions.find((opt: any) => opt.value === cell).label || "",
      filter: selectFilter({
        options: statusOptions,
      }),
    },
    {
      dataField: "serial_code",
      text: "Código serial",
      hidden: hiddenColumns[1],
      sort: true,
    },
    {
      dataField: "dateTime",
      text: "Fecha y hora",
      hidden: hiddenColumns[2],
      sort: true,
    },
    {
      dataField: "town_id",
      text: "Municipio",
      hidden: hiddenColumns[3],
      sort: true,
      formatter: (cell: number) =>
        townOptions.find((opt: any) => opt.value === cell).label || "",
      filter: selectFilter({
        options: townOptions,
      }),
    },
    {
      dataField: "institution_id",
      text: "Institución",
      hidden: hiddenColumns[4],
      sort: true,
      formatter: (cell: number) =>
        allInstitutions.find((opt: any) => opt.value === cell).label || "",
      filter: selectFilter({
        options: institutionOptions,
      }),
    },
    // {
    //   dataField: "headquarter_id",
    //   text: "Sede",
    //   hidden: hiddenColumns[4],
    //   sort: true,
    //   formatter: (cell: number) =>
    //     allHeadquarters.find((opt: any) => opt.value === cell).label || "",
    //   filter: selectFilter({
    //     options: headquartersOptions,
    //   }),
    // },
    {
      dataField: "teacher_national_id",
      text: "Identificación",
      hidden: hiddenColumns[5],
      sort: true,
    },
    {
      dataField: "teacher_name",
      text: "Nombre",
      hidden: hiddenColumns[6],
      sort: true,
    },
    // {
    //   dataField: "knowledge_area_id",
    //   text: "Asignatura",
    //   hidden: hiddenColumns[8],
    //   sort: true,
    //   formatter: (cell: number) =>
    //     kAreasOptions.find((opt: any) => opt.value === cell).label || "",
    //   filter: selectFilter({
    //     options: kAreasOptions,
    //   }),
    // },
    {
      dataField: "role_id",
      text: "Rol",
      hidden: hiddenColumns[7],
      sort: true,
      formatter: (cell: number) =>
        roles.find((opt: any) => opt.value === cell).label || "",
      filter: selectFilter({
        options: roles
      })
    },
    {
      dataField: "grade_id",
      text: "Ciclo",
      hidden: hiddenColumns[8],
      sort: true,
      formatter: (cell: number) =>
        cyclesOptions.find((opt: any) => opt.value === cell).label || "",
      filter: selectFilter({
        options: cyclesOptions.slice(0, -1),
      }),
    },
    {
      dataField: "accompaniment_type_id",
      text: "Tipo de acompañamiento",
      hidden: hiddenColumns[9],
      sort: true,
      formatter: (cell: number) =>
        accompanimentTypes.find((opt: any) => opt.value === cell).label || "",
      filter: selectFilter({
        options: accompanimentTypes,
      }),
    },
    {
      dataField: "accompaniment_description",
      text: "Descripción del acompañamiento",
      hidden: hiddenColumns[10],
      sort: true,
    },
    {
      dataField: "accompaniment_approach",
      text: "Abordaje del acompañamiento",
      hidden: hiddenColumns[11],
      sort: true,
    },
    {
      dataField: "achievements",
      text: "Logros",
      hidden: hiddenColumns[12],
      sort: true,
    },
    {
      dataField: "improvement_opportunities",
      text: "Oportunidades de mejora",
      hidden: hiddenColumns[13],
      sort: true,
    },
    {
      dataField: "user_id",
      text: "Coordinador municipal / Gestor TIC",
      hidden: hiddenColumns[14],
      sort: true,
      formatter: (cell: number) =>
        CoordMOptions.find((opt: any) => opt.value === cell).label || "",
      filter: selectFilter({
        options: CoordMOptions,
      }),
    },

  ];

  useEffect(() => {
    async function callData() {
      await GET_AXIOS("selfManagements/towns").then((res) => {
        setTownOptions(formatWords(res.data, "town_name"));
      });
      await GET_AXIOS("selfManagements/institutions").then((res) => {
        setAllInstitutions(formatWords(res.data, "inst_name"));
      });
      // GET_AXIOS("selfManagements/headquarters").then((res) => {
      //   setAllHeadquarters(formatWords(res.data, "hq_name"));
      // });
      await GET_AXIOS("grades").then((res) => {
        let data = formatWords(res.data, "grade_name");
        data.push({ value: null, label: "" })
        setCyclesOptions(data);
      });
      // GET_AXIOS("knowledgeAreas").then((res) => {
      //   setKAreasOptions(formatWords(res.data, "knowledge_area_name"));
      // });
      await GET_AXIOS("selfManagements/roles").then(res => {
        setRoles(formatWords(res.data, "name"));
      })
      await GET_AXIOS("accompanimentTypes").then((res) => {
        setAccompanimentTypes(formatWords(res.data, "name"));
      });
      await GET_AXIOS("selfManagements/users?userType=user_id").then((res) => {
        setCoordMOptions(formatWords(res.data, "name"));
      });
      await GET_AXIOS(`selfManagements/2?perPage=${sizePerPage}`).then((res) => {
        const data = res.data.data.map((el: any) => {
          let dateEle = new Date(el.created_at);
          el.dateTime =
            dateEle.getFullYear().toString().substr(2) +
            "/" +
            dateEle.getMonth() +
            "/" +
            dateEle.getDate() +
            " " +
            dateEle.toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            });
          el.teacher_name = el.teacher_name.toUpperCase();
          el.accompaniment_description =
            el.accompaniment_description &&
            el.accompaniment_description.charAt(0).toUpperCase() +
            el.accompaniment_description.slice(1);
          el.accompaniment_approach =
            el.accompaniment_approach &&
            el.accompaniment_approach.charAt(0).toUpperCase() +
            el.accompaniment_approach.slice(1);
          el.achievements =
            el.achievements &&
            el.achievements.charAt(0).toUpperCase() + el.achievements.slice(1);
          el.improvement_opportunities =
            el.improvement_opportunities &&
            el.improvement_opportunities.charAt(0).toUpperCase() +
            el.improvement_opportunities.slice(1);
          return el;
        });
        setProducts(data);
        setTotalSize(res.data.total);
      });
    }
    callData();
  }, []);

  const formatWords = (arr: Array<any>, keyInObj: String) => {
    const toReturn: any = [];
    arr.map((el: any) => {
      let word: string = "";
      el[`${keyInObj}`].split(" ").map((wr: any) => {
        if (wr) {
          word += wr.toUpperCase() + " ";
        }
      });
      word = word.trim();
      toReturn.push({ value: el.id, label: word });
    });
    return toReturn;
  };

  //Metodo de boton de descarga 
  const downloadbuttonReport = () => {
    console.log("entre")
    let typeRol = '1';
    let link = `${process.env.REACT_APP_API_ENDPOINT}selfManagements/download/${typeRol}`;
    window.open(link, "_blank");
  }
  //fin metodo boton de descarga

  const handleTableChange = (
    type: any,
    { page, sizePerPage, filters, sortField, sortOrder = "asc" }: any
  ) => {
    const {
      town_id,
      institution_id,
      headquarter_id,
      role_id,
      grade_id,
      knowledge_area_id,
      accompaniment_type_id,
      user_id,
      status,
    } = filters;

    if (town_id) {
      GET_AXIOS(`institutions/byTown/${town_id.filterVal}`).then((res) => {
        setInstitutionOptions(formatWords(res.data, "inst_name"));
      });
    } else {
      setInstitutionOptions([]);
    }
    // if (institution_id) {
    //   GET_AXIOS(`headquarters/byInstId/${institution_id.filterVal}`).then(
    //     (res) => {
    //       setHeadquartersOptions(formatWords(res.data, "hq_name"));
    //     }
    //   );
    // } else {
    //   setHeadquartersOptions([]);
    // }

    const jsonPOST = {
      town_id: town_id ? town_id.filterVal : false,
      institution_id: institution_id ? institution_id.filterVal : false,
      headquarter_id: headquarter_id ? headquarter_id.filterVal : false,
      role_id: role_id ? role_id.filterVal : false,
      grade_id: grade_id ? grade_id.filterVal : false,
      knowledge_area_id: knowledge_area_id ? knowledge_area_id.filterVal : false,
      accompaniment_type_id: accompaniment_type_id ? accompaniment_type_id.filterVal : false,
      user_id: user_id ? user_id.filterVal : false,
      page,
      quantPerPage: sizePerPage,
      columnOrder: sortField == "dateTime" ? "created_at" : "serial_code",
      orderType: sortOrder || "asc",
      status: status ? status.filterVal : false,
      initialStatus: 2,
    };

    console.log("Filter: ", jsonPOST);


    POST_AXIOS("selfManagements/filterAndSort", jsonPOST).then((res) => {
      const data = res.data.data.map((el: any) => {
        console.log(res)
        let dateEle = new Date(el.created_at);
        el.dateTime =
          dateEle.getFullYear().toString().substr(2) +
          "/" +
          dateEle.getMonth() +
          "/" +
          dateEle.getDate() +
          " " +
          dateEle.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          });
        el.teacher_name = el.teacher_name.toUpperCase();
        el.accompaniment_description =
          el.accompaniment_description &&
          el.accompaniment_description.charAt(0).toUpperCase() +
          el.accompaniment_description.slice(1);
        el.accompaniment_approach =
          el.accompaniment_approach &&
          el.accompaniment_approach.charAt(0).toUpperCase() +
          el.accompaniment_approach.slice(1);
        el.achievements =
          el.achievements &&
          el.achievements.charAt(0).toUpperCase() + el.achievements.slice(1);
        el.improvement_opportunities =
          el.improvement_opportunities &&
          el.improvement_opportunities.charAt(0).toUpperCase() +
          el.improvement_opportunities.slice(1);
        return el;
      });
      setProducts(data);
      setPage(page);
      setTotalSize(res.data.total);
      setSizePerPage(sizePerPage);
    });
  };

  const handleSeeColumns = (field: any) => {
    const indexInColumns = columns.findIndex(
      (el: any) => el.dataField == field
    );
    const hiddenCols = hiddenColumns;
    let currentHidden = hiddenCols[indexInColumns];
    hiddenCols[indexInColumns] = !currentHidden;
    setHiddenColumns([...hiddenCols]);
  };

  const CustomToggleList = ({ columns, onColumnToggle, toggles }: any) => (
    <div
      className="list-altic mb-4 mt-2 btn-group btn-group-toggle w-100 mt-3"
      data-toggle="buttons"
    >
      {columns
        .map((column: any) => ({
          ...column,
          toggle: toggles[column.dataField],
        }))
        .map((column: any) => (
          <button
            type="button"
            key={column.dataField}
            className={`list-btn-custom-class btn btn-success ${column.toggle ? "active" : ""
              }`}
            data-toggle="button"
            aria-pressed={column.toggle ? "true" : "false"}
            onClick={() => handleSeeColumns(column.dataField)}
          >
            {column.text}
          </button>
        ))}
    </div>
  );

  return (
    <div >
      <Image className="af-backImageUpper" src={decorationBacgroundUpper} style={{ position: "relative", zIndex: -1 }} />
      <div className="mt-2" style={{ position: "absolute", top: "82px" }}>
        <Link
          className="af-buttonHome d-flex justify-content-center p-2"
          to="/chooseContent"
        >
          <TiHomeOutline />
        </Link>
      </div>
      <div className="container-fluid px-4">

        <Row className="mt-5 pt-2">
          <Col
            className="justify-content-center align-self-center text-center py-3"
            md={4}
          >
            <div className="af-lineOrange"></div>
          </Col>
          <Col
            className="justify-content-center align-self-center text-center"
            md={4}
          >
            <h3 className="af-titleOrange" style={{ color: "#0ba099" }}>
              <strong>Reporte de Autogestión <br /> Coordinadores de Municipio</strong>
            </h3>
          </Col>
          <Col
            className="justify-content-center align-self-center text-center py-3"
            md={4}
          >
            <div className="af-lineOrange"></div>
          </Col>
        </Row>

        {/* <BootstrapTable keyField='id' data={products} columns={columns} filter={filterFactory()} /> */}
        <ToolkitProvider
          keyField="id"
          data={products}
          columns={columns}
          // @ts-ignore: Unreachable code error
          columnToggle
        >
          {(props) => (
            <div>
              <span className="my-2">
                A continuación, podrás visualizar los reportes generados por los Coordinadores de Municipio en relación con el proceso de tratamiento de las necesidades de soporte y acompañamiento identificadas durante su interacción con los docentes, estudiantes y directivos docentes.
              </span>
              <br></br>
              <span>Selecciona las columnas que desea consultar: </span>
              {/* <ToggleList
                            contextual="success"
                            className="list-altic mb-4 mt-2"
                            btnClassName="list-btn-custom-class"
                            {...props.columnToggleProps}
                            onColumnToggle={() => handleSeeColumns(props.columnToggleProps)}
                        /> */}
              <CustomToggleList
                contextual="success"
                {...props.columnToggleProps}
              />
              <hr />
              <BootstrapTable
                remote
                {...props.baseProps}
                filter={filterFactory()}
                // @ts-ignore: Unreachable code error
                noDataIndication="No hay Datos Aún"
                classes="table-altic"
                pagination={paginationFactory({ page, sizePerPage, totalSize })}
                onTableChange={handleTableChange}
              />
            </div>
          )}
        </ToolkitProvider>
        <div>
          <Button onClick={downloadbuttonReport} className="w-100 buttonDownloadReports">Descargar</Button>
        </div>
      </div>
      <Image className="af-backImageDown" src={decorationBacgroundDown} />
      <FootPages></FootPages>
    </div>
  );
}
