import React from "react";
import "./zoomResources.scss";
//impport elementos de react

import { Row, Button, Image, Col } from "react-bootstrap";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

//import comoponentes y vistas
import { POST_AXIOS } from "../../components/funtionsREST";

export interface Props {
  updateZoomResource: any;
  resource_id: any;
  url: string;
  desc: string;
  dba: string;
  grade: string;
  subject: string;
}

export default class ZoomResource extends React.Component<Props, {}> {
  //funcion para post de conteo de progreso para video
  postProgressResource = () => {
    let dataResource = {
      resource_id: this.props.resource_id,
    };
    POST_AXIOS("users/newResource", dataResource)
      .then((res) => {
        console.log(res);
        return res;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  };

  render() {
    return (
      <Row>
        <p className="af-textZoom mt-3">
          Te invitamos a realizar la actividad interactiva que encuentras a
          continuación. Es importante que tengas en cuenta que: 
          <br/><br/>• Al hacer clic en el botón <strong>Ir a la actividad</strong>, se abre una nueva pestaña del navegador para que puedas realizarla. Al finalizar la actividad, debes cerrar la pestaña y regresar a la plataforma.
          <br/><br/> • En algunas actividades, la plataforma te va a brindar una retroalimentación cuando las hayas completado de manera acertada. En caso contrario, sigue intentándolo hasta lograrlo.
        </p>

        {/* Botenes */}
        <Row className="w-100 justify-content-center align-self-center text-center">
          <Col className="p-0 ml-2" md={4}>
            <Button
              className="w-100 af-buttonActivity m-2 p-0"
              onClick={(e: any) => this.props.updateZoomResource(e)}
            >
              <p className="af-textVideos  ">
                <IoIosArrowBack className="af-backArrow" /> Ver mas actividades
              </p>
            </Button>
          </Col>
          <Col className="mr-2" md={4}>
            <a target="_blank" href={this.props.url} rel="noopener noreferrer">
              <Button
                className="w-100 af-buttonActivity m-2 p-0"
                onClick={this.postProgressResource}
              >
                <p className="af-textVideos">
                  Ir a la actividad{" "}
                  <IoIosArrowForward className="af-backArrow" />
                </p>
              </Button>
            </a>
          </Col>
        </Row>
        {/* fin fila botones */}

        <Row className="">
        <h4 className="af-titleZoomVideo mt-2">
          <strong>
            {this.props.subject} {this.props.grade}
          </strong>
        </h4>
        </Row>
      
        <Row className="w-100 ">
          <p className="mt-2 w-100">{this.props.desc}</p>
          <p className="mt-2 af-textZoom w-100">DBA: {this.props.dba}</p>
        </Row>
      </Row>
    );
  }
}
