import React from 'react';
import { Col, FormControl, FormLabel } from 'react-bootstrap';

interface Props {
    role: string
}

const InputDataPersonal = ({ role }: Props) => {
    return (
        <div>
            { role === "attending" ? <Col style={{ display: "flex", justifyContent: "center" }}>
                <FormLabel style={{ width: "60%" }}>Correo electrónico</FormLabel>
                <FormControl type="email" />
                <FormLabel style={{ width: "60%" }}>Numero de telfono</FormLabel>
                <FormControl style={{ marginRight: "2em" }} type="number" />
                <FormLabel style={{ width: "60%" }}>Tipo de teléfono</FormLabel>
                <FormControl style={{ marginRight: "2em" }} as="select" > 
                    <option>Fijo</option>
                    <option>Celular</option>
                </FormControl>
                <FormLabel style={{ width: "60%" }}>Posees datos</FormLabel>
                <FormControl style={{ marginRight: "2em" }} as="select" > 
                    <option>Si</option>
                    <option>No</option>
                </FormControl>
            </Col>
            : <Col style={{ display: "flex", justifyContent: "center" }}>
                <FormLabel style={{ width: "60%" }}>Correo electrónico</FormLabel>
                <FormControl type="email" />
                <FormLabel style={{ width: "60%" }}>Numero de telfono</FormLabel>
                <FormControl style={{ marginRight: "2em" }} type="number" />
            </Col>
        }
        </div>
    )
}

export default InputDataPersonal