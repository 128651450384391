import React from 'react';
import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';



import RouterPage from './Router';

function App() {
  return (
    <RouterPage></RouterPage>
  );
}

export default App;
