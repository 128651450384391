import React from "react";
import "./recoverPassEmail.scss";

//import elmentos de react
import { Row, Col, Button, Image, FormControl, Form, FormGroup} from "react-bootstrap";

//import imagenes
import EmailIcon from "./../../img/mail_icon.png";

//import componentes y vistas
import FootPages from "../../components/footPage/footPage";
import ModalGeneral from "../../components/modalGeneral/modalGeneral";

// import funtions REST
import { POST_AXIOS } from "../../components/funtionsREST";

export default class RecoverPassEmail extends React.Component<{},{show:boolean, tittleModal:string, textModal:string, url:string, email:string, validated:boolean}>{
  constructor(props: any) {
    super(props);
    this.state = {
      email:"",
      show: false,
      tittleModal:"",
      textModal:"",
      url:"",
      validated:false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose=this.handleClose.bind(this);

  }
  handleClose = () => this.setState({ show: false }); //    Metodo para cerrar modal

  async handleSubmit(e:any){
    e.preventDefault();
    console.log(e.target.value);
    const form = e.currentTarget;
    console.log(form.checkValidity());
    //if para mirar si le usuario ha llenado todos los campos
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      //------------------------
    }else {
      let response= await this.postMailingTokenRecover();
      console.log(response);
      if(response !== false){
        this.setState({
          show:true,
          tittleModal:"Confirmación envio de token",
        textModal:"El token ha sido enviado correctamente al correo registrado, por favor ingrese a su correo y siga los pasos"});
        this.acceptModal(true)
      }else{
        this.setState({
          show:true,
          tittleModal:"Correo incorrecto",
          textModal:"Su correo no se encuentra registrado en nuestra base de datos por favor verifique y vuelva a intentar"});
          this.acceptModal(false)
      }
    }
   
      this.setState({validated:true})
  }

    //Metodo para indicar la acción del botón aceptar del modal
    acceptModal(res:any){
      console.log('entre')
    if(res!==false){
      this.setState({url:"/loginPage"});
    }else{
      this.setState({url:"/recoverPassEmail"});
  
    }
    }
  //Metodo de post para enviar token al correo
  async postMailingTokenRecover(){

    const jsonPostTokenEmail={
      uid:this.state.email
    }
    let res= await POST_AXIOS("forgotPassword", jsonPostTokenEmail);
    console.log(res);
    if (res !== false) {
      
      return res.data;
    } else {
      return res; 
    }
  }

   //metodos para optenes los datos de los inputs
   setEmail(e:any){
    this.setState({email:e});
  }
  
render(){
  const {validated, show, tittleModal, textModal, url}=this.state;
    return(
        <div >
        <div className="container-fluid w-75 af-pageRecoverPass">
        <Row className="justify-content-center align-self-center text-center m-0 pt-5">
            <Col
              className="justify-content-center align-self-center text-center py-3"
              md={4}
            >
              <div className="af-lineOrange"></div>
            </Col>
            <Col
              className="justify-content-center align-self-center text-center"
              md={4}
            >
              <h3 className="af-titleOrange">
                <strong>Recuperar Contraseña</strong>
              </h3>
            </Col>
            <Col
              className="justify-content-center align-self-center text-center py-3"
              md={4}
            >
             <div className="af-lineOrange"></div>
            </Col>
              </Row>
              <Row className="justify-content-center align-self-center text-center">
                  <Col md={9}>
                  <p>Ingrese su correo electronico, para iniciar el proceso de recuperación de contraseña.</p>
                  </Col>
              </Row>
              <Form noValidate validated={validated} onSubmit={this.handleSubmit}>
              <Row className="justify-content-center align-self-center text-center af-inputRecoverPass">
              <Row className="w-100 justify-content-center align-self-center text-center">
              <Col md={6} className="d-flex justify-content-center">
                <div className="af-inputIcon">
                  <Image src={EmailIcon} />
                </div>
                <FormGroup className="w-100">
                <FormControl
                  type="email"
                  placeholder="Digite el correo registrado"
                  className="af-inputs"
                  name="checkEmail"
                  onChange={(e: any) =>
                    this.setEmail(e.target.value)
                  }
                  required
                />
                   <Form.Control.Feedback type="invalid">
                  Debe llenar este campo con los datos correctos
                 </Form.Control.Feedback>
                 </FormGroup>

              </Col>
              </Row>

              <Col className="pt-3">    
              <Button
                    className="af-buttonLogin w-25"
                    type="submit"
                    // href="/IntroductionPage"
                  >
                  Enviar
                  </Button>
              </Col>
              </Row>
              </Form>
              </div>

        <FootPages></FootPages>
          {/* modal de confirmación */}
      {show && <ModalGeneral 
      show={show}
      tittle={tittleModal} 
      text={textModal} 
      handleClose={this.handleClose}
      url={url}
      />}
        </div>
    )
}

}
