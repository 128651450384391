import React from "react";

import { Modal, Button } from "react-bootstrap";

// import { useHistory } from "react-router-dom";
import { POST_AXIOS } from "../funtionsREST";

export default function ModalLogout({ show, handleClose, isLogout }) {
  // const HISTORY = useHistory();

  const setLogout = async () => {
    let response = await POST_AXIOS("logoutsamlify", {});
    console.log("NavBar -> setLogout -> response", response);

    if (response !== false) {
      //   HISTORY.replace("/");
      console.log("entre");
      isLogout();
      handleClose();
      window.location = response.data;
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title> ¿Cerrar sesión? </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Al cerrar sesión debe volver a iniciar sesión para ingresar a la página
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          No, mantener sesión
        </Button>
        <Button variant="danger" onClick={setLogout}>
          Si, cerrar sesión
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
