import React from "react";
import "./introductionPage.scss";

//import componentes y vistas
import FootPages from "../../components/footPage/footPage";
import { GET_AXIOS } from "../../components/funtionsREST";

//import elmentos de react
import { Row, Col, Button, Form, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { TiHomeOutline } from "react-icons/ti";

//import imagenes
import decorationBacgroundUpper from "../../img/decorationBacgroundUpperContent.png";
import decorationBacgroundDown from "../../img/decorationDownContent.png";
import LapizIntroductionPage from "../../img/LapizIntroductionPage.png"
import FoxIntroductionPage from "../../img/FoxIntroductionPage.png";
import LoadingPage from "../../components/spinner/loadingPage";

import animalCiclo2 from "../../img/animalCiclo2.png";
import animalCiclo3 from "../../img/animalCiclo3.png";
import animalCiclo4 from "../../img/animalCiclo4.png";
import animalCiclo5 from "../../img/animalCiclo5.png";
import animalCiclo6 from "../../img/animalCiclo6.png";

export default class IntroductionPage extends React.Component<
  { rol: any; cc: any; user: any },
  {
    seeButtonHome: any;
    name: any;
    textStart: any;
    idGrade: any;
    seeButton: boolean;
    selectGrade: any;
    grades: any;
    subjects: any;
    animalImage: string;
    showPencil: boolean;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      grades: [],
      subjects: [],
      selectGrade: "",
      idGrade: "",
      seeButton: true,
      seeButtonHome: true,
      textStart: "",
      name: "",
      animalImage: FoxIntroductionPage,
      showPencil: true,

    };
    this.handleChange = this.handleChange.bind(this);
  }
  componentWillMount() {
    console.log(this.props.user.first_name);
    this.getGrades();
    this.setState({ selectGrade: "" });
  }

  componentDidMount() {
    this.knowRol();
    this.showStartText(this.props.rol);
  }

  knowRol() {
    console.log(this.props.rol);
    //Se utiliza el rol traido de la base de datos
    if (this.props.rol === "est" || this.props.rol === "acu") {
      this.setState({ seeButtonHome: false });
    }
    if (this.props.rol === "est") {
      this.setState({ seeButton: false });

      //---------- cambiar despues de muestra-----------------------
      // this.getSubject();
    }
  }

  // Muestra texto de bienvenida de acuerdo al rol del usuario est:estudiante, acu:acudiente, doce:docente, rec:rector, sec_ed: secretaria de, gagem:Gagem, pta:pta
  showStartText(rol: any) {
    rol = this.props.rol;
    let newText;
    let newName;
    switch (rol) {
      case "est":
        newText =
          "A continuación, encontrarás contenidos y recursos para el aprendizaje, organizados en las áreas básicas; así como también a los materiales educativos “Desafíos para Aprender”.\n Para ingresar a los contenidos y recursos, selecciona el área a la que vas a acceder:";
        newName = "BIENVENIDO(A) ESTUDIANTE";
        break;
      case "acu":
        newText = "";
        // "A continuación, encontrará contenidos y/o recursos para la enseñanza y el aprendizaje, organizados en las áreas básicas, los cuales podrá consultar para brindarle acompañamiento a su hijo(a) o familiar.\n Para ingresar a los contenidos y recursos, seleccione el grado al que vas a acceder:";
        newName = "BIENVENIDO(S) PADRE(S) DE FAMILIA O ACUDIENTE,";
        break;
      case "doce":
        newText =
          "A continuación, encontrarás contenidos y recursos para la enseñanza y el aprendizaje, organizados en las áreas básicas; así como también a los materiales educativos “Desafíos para Aprender” los cuales se constituyen en alternativas para que, los estudiantes avancen en su proceso de aprendizaje y tú los utilices para complementar el desarrollo de las acciones formativas propias, de la atención educativa a la población de jóvenes extraedad y adultos beneficiarios, del acompañamiento a la implementación del Modelo Educativo Flexible Talentum, en el Valle del Cauca. \n Para ingresar a los contenidos y/o recursos, seleccione el ciclo al que vas acceder:";
        newName = "ESTIMADO(A) DOCENTE";
        break;
      case "rec":
        newText = `A continuación, encontrará contenidos y recursos para la enseñanza y el aprendizaje, organizados en las áreas básicas; así como también a los materiales educativos “Desafíos para Aprender”, los cuales se constituyen en alternativas para que, los estudiantes avancen en su proceso de aprendizaje y los docentes las utilicen para complementar el desarrollo de las acciones formativas propias, de la atención educativa a la población de jóvenes extraedad y adultos beneficiarios, del acompañamiento a la implementación del Modelo Educativo Flexible Talentum, en el Valle del Cauca.`;
        newName = "ESTIMADO(A) RECTOR(A)";
        break;
      case "sec_ed":
        newText = `A continuación, encontrará contenidos y recursos para la enseñanza y el aprendizaje, organizados en las áreas básicas; así como también a los materiales educativos “Desafíos para Aprender”, los cuales se constituyen en alternativas para que, los estudiantes avancen en su proceso de aprendizaje y los docentes las utilicen para complementar el desarrollo de las acciones formativas propias, de la atención educativa a la población de jóvenes extraedad y adultos beneficiarios, del acompañamiento a la implementación del Modelo Educativo Flexible Talentum, en el Valle del Cauca.`;
        newName =
          "ESTIMADO(A) DELEGADO(A) DE LA SECRETARÍA DE EDUCACIÓN DEPARTAMENTAL";
        break;
      case "dir_ge":
        newText =
          "A continuación, encontrará contenidos y recursos para la enseñanza y el aprendizaje, organizados en las áreas básicas; así como también a los materiales educativos “Desafíos para Aprender”, los cuales se constituyen en alternativas para que, los estudiantes avancen en su proceso de aprendizaje y los docentes las utilicen para complementar el desarrollo de las acciones formativas propias, de la atención educativa a la población de jóvenes extraedad y adultos beneficiarios, del acompañamiento a la implementación del Modelo Educativo Flexible Talentum, en el Valle del Cauca.";
        newName = "ESTIMADO(A) DIRECTOR(A) GENERAL";
        break;
      case "agent":
        newText = "Cambiar texto!";
        newName = "Asesor tecnopedagógica";
        break;
      case "coor_m":
        newText =
          "A continuación, encontrarás contenidos y recursos para la enseñanza y el aprendizaje, organizados en las áreas básicas; así como también a los materiales educativos “Desafíos para Aprender”,  los cuales se constituyen en alternativas para que, los estudiantes avancen en su proceso de aprendizaje y los docentes las utilicen para complementar el desarrollo de las acciones formativas propias, de la atención educativa a la población de jóvenes extraedad y adultos beneficiarios, del acompañamiento a la implementación del Modelo Educativo Flexible Talentum, en el Valle del Cauca.";
        newName = "ESTIMADO(A), COORDINADOR(A) DE MUNICIPIO";
        break;
      case "coor_op":
        newText =
          "A continuación, encontrarás contenidos y recursos para la enseñanza y el aprendizaje, organizados en las áreas básicas; los cuales se constituyen en alternativas para que, los estudiantes avancen en su proceso de aprendizaje y los docentes las utilicen para complementar el desarrollo de las acciones formativas propias, de la atención educativa a la población de jóvenes extraedad y adultos beneficiarios, del acompañamiento a la implementación del Modelo Educativo Flexible Talentum, en el Valle del Cauca.";
        newName = "ESTIMADO(A), COORDINADOR(A) OPERATIVO";
        break;
      case "inter_aco":
        newText =
          "A continuación, encontrarás contenidos y recursos para la enseñanza y el aprendizaje, organizados en las áreas básicas; los cuales se constituyen en alternativas para que, los estudiantes avancen en su proceso de aprendizaje y los docentes las utilicen para complementar el desarrollo de las acciones formativas propias, de la atención educativa a la población de jóvenes extraedad y adultos beneficiarios, del acompañamiento a la implementación del Modelo Educativo Flexible Talentum, en el Valle del Cauca.";
        newName =
          "ESTIMADO(A), INTEGRANTE DEL EQUIPO DE ACOMPAÑAMIENTO Y MONITOREO";
        break;
      default:
        newText = `A continuación encontrarás contenidos y/o recursos para el aprendizaje, organizados en las áreas básicas.\nPara ingresar a los contenidos y/o recursos, selecciona el área a la que vas a acceder:`;
        newName = "Bienvenido usuario";
    }
    this.setState({ textStart: newText, name: newName });
  }

  // controlador de select del grado
  handleChange(event: any) {
    var index = event.target.selectedIndex;
    var optionElement = event.target.childNodes[index].id;

    if (event.target.value && event.target.id === "dd") {
      this.getSubject(optionElement);
    }

    this.setState({ selectGrade: event.target.value, idGrade: optionElement });

    let newAnimalImage;
    let showPencil = false;

    // Cambia la imagen del animal según el ciclo seleccionado
    switch (optionElement) {
      case "22": // ID para "Ciclo 2"
        newAnimalImage = animalCiclo2;
        break;
      case "23":
        newAnimalImage = animalCiclo3;
        break;
      case "24":
        newAnimalImage = animalCiclo4;
        break;
      case "25":
        newAnimalImage = animalCiclo5;
        break;
      case "26":
        newAnimalImage = animalCiclo6;
        break;
      default:
        newAnimalImage = FoxIntroductionPage;
        showPencil = true; // Volver a mostrar el lápiz si no se selecciona un ciclo específico
    }

    this.setState({ animalImage: newAnimalImage, showPencil });

    if (optionElement === "0") {
      this.setState({
        subjects: [
          {
            id: 0,
            knowledge_area_name: "transición",
          },
          {
            id: 6,
            knowledge_area_name: "Competencias transversales y/o socioeconómicas",
          },
        ],
      });
    } else if (!event.target.value) {
      this.setState({
        subjects: [],
      });
    }
  }
  // -------Peticiones a la base de datos---------

  getGrades() {
    // Obtener grados base de datos
    GET_AXIOS("users/grades")
      .then((res: any) => {
        if (this.props.rol === "est") {
          this.setState({
            selectGrade: res.data[0].grade_name,
            idGrade: res.data[0].id,
          });
          this.getSubject(this.state.idGrade);
        }
        this.setState({ grades: res.data || [] });
      })
      .catch((err: any) => {
        console.log(err);
        this.setState({ grades: [] }); // En caso de error, asegúrate de que grades sea un arreglo vacío
      });
  }

  getSubject(grade: any) {
    //Obtener materias base de datos
    GET_AXIOS(`users/knowledgeAreas/${grade}`)
      .then((res: any) => {
        // console.log(res.data);
        this.setState({ subjects: res.data });
      })
      .catch((err: any) => {
        console.log(err);
      });
  }
  // -------Cierre Peticiones a la base de datos---------

  render() {
    const {
      grades,
      subjects,
      selectGrade,
      seeButton,
      seeButtonHome,
      idGrade,
      textStart,
      name,
      animalImage,
      showPencil
    } = this.state;
    console.log(seeButton);
    return (
      <div className="">
        {/* <NavBar
          url="/IntroductionPage"
          activeKey="4"
          name="Perfil"
          auth={true}
        ></NavBar>
      ></NavBar> */}
        <div className="container-fluid principal-page-introduction p-0 mt-5">
          <Row className="w-100 af-divIntro m-0">
            <Image className="af-backImageUpper" src={decorationBacgroundUpper} style={{ marginTop: "-50px" }} />

            <Col
              className="m-0 p-0 d-flex flex-column justify-content-center"
              md={12}
            >

              <LoadingPage>

                <>
                  {seeButtonHome && (
                    <div className="mt-2">
                      <Link
                        className="af-buttonHomeIntroduction d-flex justify-content-center p-2"
                        to="/chooseContent"
                      >
                        <TiHomeOutline />
                      </Link>
                    </div>
                  )}

                  <Row className="w-100 justify-content-center align-self-center text-center m-0 p-0">
                    <Col md={8}>
                      <h2 className="p-2 af-tittleIntro">
                        <strong>{name}</strong>
                      </h2>
                      {textStart.split("\n").map((item: any, i: any) => {
                        return <p key={i}>{item}</p>;
                      })}
                    </Col>
                  </Row>
                  <Row className="w-100 m-0 justify-content-center align-self-center text-center pb-3">
                    <Col md={4}>
                      <div className="af-lineOrangeIntro"></div>
                    </Col>
                  </Row>

                  <Row className="w-100 justify-content-center align-self-center text-center m-0 pb-0">
                    <Col md={8}>
                      <Row className="justify-content-center align-self-center text-center pb-0">
                        <Col md={8}>
                          {seeButton && (
                            <Form.Control
                              custom
                              className="af-selectGrade w-100 m-1"
                              id="dd"
                              as="select"
                              value={selectGrade}
                              onChange={this.handleChange}
                            >
                              <option value="">Elige un ciclo...</option>
                              {Array.isArray(grades) && grades.map((grade: any, i: any) => (
                                <option
                                  key={grade.id}
                                  id={grade.id}
                                  value={grade.grade_name}
                                  selected={this.state.idGrade === grade.id}
                                >
                                  {grade.grade_name}
                                </option>
                              ))}
                            </Form.Control>
                          )}
                        </Col>
                      </Row>
                      <Col className="af-buttonList pb-5 m2-2 justify-content-center" md={12}>
                        {subjects && subjects.map((sub: any, i: any) => (
                          <Col className="p-1" key={i} md={6}>
                            <Link
                              to={{
                                pathname: "/detailPage",
                                state: {
                                  subject: sub.knowledge_area_name,
                                  grade: selectGrade,
                                  rol: this.props.rol,
                                  idSubject: sub.id,
                                  idGrade: idGrade,
                                  cc: this.props.cc,
                                  userName: this.props.user.username,
                                },
                              }}
                            >
                              <Button
                                className="af-buttonSubject"
                                disabled={selectGrade === "" && seeButton}
                              >
                                {" "}
                                {sub.knowledge_area_name}
                              </Button>
                            </Link>
                          </Col>
                        ))}
                        {/* {subjects[0] && this.props.rol == "doce" && (
                          <Col className="p-1" md={6}>
                            <Button
                              className="af-buttonSubject"
                              href={process.env.REACT_APP_CAPACITACION}
                            >
                              {" "}
                              Formación Docente
                            </Button>
                          </Col>
                        )} */}
                      </Col>
                    </Col>

                  </Row>
                </>

              </LoadingPage>


            </Col>
          </Row>

          <div style={{ marginTop: "-200px" }}>
            <div className="relative-container" style={{ marginTop: "-200px" }}>
              <Image
                className="af-lapiz-intro"
                src={LapizIntroductionPage}
                style={{
                  width: '24vw',
                  zIndex: -10,
                  opacity: showPencil ? 1 : 0,
                  transition: 'opacity 0.3s ease'
                }}
              />
              <Image className="af-fox-intro" src={animalImage} style={{ paddingBottom: "80px", right: "5%", width: '9vw', maxWidth: '9vw', height: 'auto', zIndex: -10 }} />
            </div>
            <div style={{ marginTop: "-100px" }}>
              <Image className="af-backImageDown" src={decorationBacgroundDown} />
              <FootPages></FootPages>
            </div>
          </div>
        </div>

      </div>
    );
  }
}