import React, { useState, useEffect } from "react";
import "./coordOpReport.scss";
import { Row, Col, Button } from "react-bootstrap";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import filterFactory, {
  textFilter,
  selectFilter,
  Comparator,
} from "react-bootstrap-table2-filter";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import ToolkitProvider, { ColumnToggle } from "react-bootstrap-table2-toolkit";
import { TiHomeOutline } from "react-icons/ti";
import { Link } from "react-router-dom";
import {
  POST_AXIOS,
  GET_AXIOS,
  PUT_AXIOS,
} from "../../components/funtionsREST";

export default function CoordOpReport() {
  const { ToggleList } = ColumnToggle;
  const [townOptions, setTownOptions]: any = useState([
    { value: 0, label: "CALI" },
  ]);
  const [allInstitutions, setAllInstitutions]: any = useState([
    { value: 0, label: "IE ARTURO GOMEZ JARAMILLO" },
    { value: 1, label: "IE SAN JOSE" },
    { value: 3, label: "IE AGRICOLA CAMPOALEGRE" },
  ]);
  const [institutionOptions, setInstitutionOptions]: any = useState([]);
  // const [allHeadquarters, setAllHeadquarters]: any = useState([
  //   { value: 0, label: "ARTURO GOMEZ JARAMILLO" },
  // ]);
  // const [headquartersOptions, setHeadquartersOptions]: any = useState([]);
  // const [cyclesOptions, setCyclesOptions]: any = useState([
  //   { value: 0, label: "Ciclo 1" },
  // ]);

  const [coordOpOptions, setCoordOpOptions]: any = useState([
    { value: 0, label: "None" },
  ]);
  const [products, setProducts]: any = useState([
    {
      serialCode: "000",
      dateTime: "01/01/00 12:00 AM",
      town_id: 0,
      institution_id: 1,
      headquarter_id: 0,
      grade_id: 0,
      conclusion: "No hay datos disponibles en este momento.",
      coord_o_id: 0,
      status: 0,
    },
  ]);
  const [page, setPage]: any = useState(1);
  const [sizePerPage, setSizePerPage]: any = useState(10);
  const [totalSize, setTotalSize]: any = useState(products.length);
  const [hiddenColumns, setHiddenColumns]: any = useState([
    false,
    false,
    false,
    true,
    // true,
    // true,
    true,
    false,
  ]);

  let columns = [
    {
      dataField: "serial_code",
      text: "Código serial",
      hidden: hiddenColumns[0],
      sort: true,
    },
    {
      dataField: "dateTime",
      text: "Fecha y hora",
      hidden: hiddenColumns[1],
      sort: true,
    },
    {
      dataField: "town_id",
      text: "Municipio",
      hidden: hiddenColumns[2],
      sort: true,
      formatter: (cell: number) =>
        townOptions.find((opt: any) => opt.value === cell).label || "",
      filter: selectFilter({
        options: townOptions,
      }),
    },
    {
      dataField: "institution_id",
      text: "Institución",
      hidden: hiddenColumns[3],
      sort: true,
      formatter: (cell: number) =>
        allInstitutions.find((opt: any) => opt.value === cell).label || "",
      filter: selectFilter({
        options: institutionOptions,
      }),
    },
    // {
    //   dataField: "headquarter_id",
    //   text: "Sede",
    //   hidden: hiddenColumns[4],
    //   sort: true,
    //   formatter: (cell: number) =>
    //     allHeadquarters.find((opt: any) => opt.value === cell).label || "",
    //   filter: selectFilter({
    //     options: headquartersOptions,
    //   }),
    // },
    // {
    //   dataField: "grade_id",
    //   text: "Ciclo",
    //   hidden: hiddenColumns[5],
    //   sort: true,
    //   formatter: (cell: number) =>
    //     cyclesOptions.find((opt: any) => opt.value === cell).label || "",
    //   filter: selectFilter({
    //     options: cyclesOptions,
    //   }),
    // },
    {
      dataField: "conclusion",
      text: "Validación del proceso",
      hidden: hiddenColumns[4],
      sort: true,
    },
    {
      dataField: "coord_o_id",
      text: "Coordinador operativo",
      hidden: hiddenColumns[5],
      sort: true,
      formatter: (cell: number) =>
        coordOpOptions.find((opt: any) => opt.value === cell).label || "",
      filter: selectFilter({
        options: coordOpOptions,
      }),
    },

  ];

  useEffect(() => {
    async function callData() {
      await GET_AXIOS("accompaniments/towns").then((res) => {
        setTownOptions(formatWords(res.data, "town_name"));
      });
      await GET_AXIOS("accompaniments/institutions").then((res) => {
        setAllInstitutions(formatWords(res.data, "inst_name"));
      });
      // GET_AXIOS("accompaniments/headquarters").then((res) => {
      //   setAllHeadquarters(formatWords(res.data, "hq_name"));
      // });
      // GET_AXIOS("grades").then((res) => {
      //   setCyclesOptions(formatWords(res.data, "grade_name"));
      // });
      await GET_AXIOS("accompaniments/users/coord_o").then((res) => {
        setCoordOpOptions(formatWords(res.data, "name"));
      });
      await GET_AXIOS(`accompaniments/5?perPage=${sizePerPage}`).then((res) => {
        const data = res.data.data.map((el: any) => {
          let dateEle = new Date(el.created_at);
          el.dateTime =
            dateEle.getFullYear().toString().substr(2) +
            "/" +
            dateEle.getMonth() +
            "/" +
            dateEle.getDate() +
            " " +
            dateEle.toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            });
          el.conclusion =
            el.conclusion &&
            el.conclusion.charAt(0).toUpperCase() + el.conclusion.slice(1);
          return el;
        });
        setProducts(data);
        setTotalSize(res.data.total);
      });
    }
    callData();
  }, []);

  const formatWords = (arr: Array<any>, keyInObj: String) => {
    const toReturn: any = [];
    arr.map((el: any) => {
      let word: string = "";
      el[`${keyInObj}`].split(" ").map((wr: any) => {
        if (wr) {
          word += wr.toUpperCase() + " ";
        }
      });
      word = word.trim();
      toReturn.push({ value: el.id, label: word });
    });
    return toReturn;
  };

  const handleTableChange = (
    type: any,
    { page, sizePerPage, filters, sortField, sortOrder = "asc" }: any
  ) => {
    const {
      town_id,
      institution_id,
      headquarter_id,
      grade_id,
      coord_o_id,
    } = filters;

    if (town_id) {
      GET_AXIOS(`institutions/byTown/${town_id.filterVal}`).then((res) => {
        setInstitutionOptions(formatWords(res.data, "inst_name"));
      });
    } else {
      setInstitutionOptions([]);
    }
    // if (institution_id) {
    //   GET_AXIOS(`headquarters/byInstId/${institution_id.filterVal}`).then(
    //     (res) => {
    //       setHeadquartersOptions(formatWords(res.data, "hq_name"));
    //     }
    //   );
    // } else {
    //   setHeadquartersOptions([]);
    // }

    const jsonPOST = {
      town_id: town_id ? town_id.filterVal : false,
      institution_id: institution_id ? institution_id.filterVal : false,
      headquarter_id: headquarter_id ? headquarter_id.filterVal : false,
      grade_id: grade_id ? grade_id.filterVal : false,
      coord_o_id: coord_o_id ? coord_o_id.filterVal : false,
      page,
      quantPerPage: sizePerPage,
      columnOrder: sortField == "dateTime" ? "created_at" : "serial_code",
      orderType: sortOrder || "asc",
      status: 4,
      initialStatus: 4,
    };

    POST_AXIOS("accompaniments/filterAndSort", jsonPOST).then((res) => {
      const data = res.data.data.map((el: any) => {
        let dateEle = new Date(el.created_at);
        el.dateTime =
          dateEle.getFullYear().toString().substr(2) +
          "/" +
          dateEle.getMonth() +
          "/" +
          dateEle.getDate() +
          " " +
          dateEle.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          });
        el.conclusion =
          el.conclusion &&
          el.conclusion.charAt(0).toUpperCase() + el.conclusion.slice(1);
        return el;
      });
      setProducts(data);
      setPage(page);
      setTotalSize(res.data.total);
      setSizePerPage(sizePerPage);
    });
  };

  //Metodo de boton de descarga 
  const downloadbuttonReport = () => {
    console.log("entre")
    let typeRol = '5';
    let link = `${process.env.REACT_APP_API_ENDPOINT}accompaniments/download/${typeRol}`;
    console.log(link);
    window.open(link, "_blank");
  }
  //fin metodo boton de descarga

  const handleSeeColumns = (field: any) => {
    const indexInColumns = columns.findIndex(
      (el: any) => el.dataField == field
    );
    const hiddenCols = hiddenColumns;
    let currentHidden = hiddenCols[indexInColumns];
    hiddenCols[indexInColumns] = !currentHidden;
    setHiddenColumns([...hiddenCols]);
  };

  const CustomToggleList = ({ columns, onColumnToggle, toggles }: any) => (
    <div
      className="list-altic mb-4 mt-2 btn-group btn-group-toggle w-100 mt-3"
      data-toggle="buttons"
    >
      {columns
        .map((column: any) => ({
          ...column,
          toggle: toggles[column.dataField],
        }))
        .map((column: any) => (
          <button
            type="button"
            key={column.dataField}
            className={`list-btn-custom-class btn btn-success ${
              column.toggle ? "active" : ""
              }`}
            data-toggle="button"
            aria-pressed={column.toggle ? "true" : "false"}
            onClick={() => handleSeeColumns(column.dataField)}
          >
            {column.text}
          </button>
        ))}
    </div>
  );

  return (
    <div >
      <div className="mt-2" style={{ position: "absolute", top: "82px" }}>
        <Link
          className="af-buttonHome d-flex justify-content-center p-2"
          to="/chooseContent"
        >
          <TiHomeOutline />
        </Link>
      </div>
      <div className="container-fluid px-4">
        <Row className="mt-5 pt-2">
          <Col
            className="justify-content-center align-self-center text-center py-3"
            md={4}
          >
            <div className="af-lineOrange"></div>
          </Col>
          <Col
            className="justify-content-center align-self-center text-center"
            md={4}
          >
            <h3 className="af-titleOrange">
              <strong>Estado Final Reporte de Coordinador</strong>
            </h3>
          </Col>
          <Col
            className="justify-content-center align-self-center text-center py-3"
            md={4}
          >
            <div className="af-lineOrange"></div>
          </Col>
        </Row>

        {/* <BootstrapTable keyField='id' data={products} columns={columns} filter={filterFactory()} /> */}
        <ToolkitProvider
          keyField="id"
          data={products}
          columns={columns}
          // @ts-ignore: Unreachable code error
          columnToggle
        >
          {(props) => (
            <div>
              <span className="my-2">
                A continuación, podrás visualizar los reportes generados por los Coordinadores operativos en el proceso de validación de las acciones desplegadas por los Coordinadores de Municipio.
            </span>
              <br></br>
              <span>Selecciona las columnas que desea consultar: </span>
              {/* <ToggleList
                            contextual="success"
                            className="list-altic mb-4 mt-2"
                            btnClassName="list-btn-custom-class"
                            {...props.columnToggleProps}
                            onColumnToggle={() => handleSeeColumns(props.columnToggleProps)}
                        /> */}
              <CustomToggleList
                contextual="success"
                {...props.columnToggleProps}
              />
              <hr />
              <BootstrapTable
                remote
                {...props.baseProps}
                filter={filterFactory()}
                // @ts-ignore: Unreachable code error
                noDataIndication="No hay Datos Aún"
                classes="table-altic"
                pagination={paginationFactory({ page, sizePerPage, totalSize })}
                onTableChange={handleTableChange}
              />
            </div>
          )}
        </ToolkitProvider>
        <div>
          <Button onClick={downloadbuttonReport} className="w-100 buttonDownloadReports">Descargar</Button>
        </div>
      </div>
    </div>
  );
}
