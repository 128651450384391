import React from "react";
import "./detailPage.scss";

//import comoponentes y vistas
import FootPages from "../../components/footPage/footPage";
import Lessons from "../../components/lessons/lessons";
import Models from "../../components/resources/models";
import ReportStudent from "../../components/report/reports/reportStudent";
import Videos from "../../components/videos/videos";
import Activity from "../../components/activity/activiy";
import Resources from "../../components/resources/resources";
import ChallengeLearn from "../../components/challengeLearn/challengeLearn";
import EduMaterial from "../../components/eduMaterial/eduMaterial";
import PrintableActivities from "../../components/PrintableActivities/printableActivities"; // Nuevo componente para Actividades Imprimibles
import TeacherGuides from "../../components/TeacherGuides/TeacherGuides"; // Nuevo componente para Guías Docente
import PageInit from "../../components/pageInit/pageInit";
import LoadingPage from "../../components/spinner/loadingPage";

//impport elementos de react
import { Row, Col, Button } from "react-bootstrap";
import { TiHomeOutline } from "react-icons/ti";
import { RouteProps } from "react-router";
import { Link } from "react-router-dom";
import { IoIosMenu, IoMdArrowDropright } from "react-icons/io";
import { POST_AXIOS, GET_AXIOS } from "../../components/funtionsREST";

//Import Imagenes
import { ReactComponent as VideoIcon } from "./../../img/iconSvg/logoLista.svg";
import { ReactComponent as ActivityIcon } from "./../../img/iconSvg/logoListaAct.svg";
import { ReactComponent as ResourceIcon } from "./../../img/iconSvg/logoListaMen.svg";
import { ReactComponent as AulaIcon } from "./../../img/iconSvg/logoListaLec.svg";
import { ReactComponent as ReportIcon } from "./../../img/iconSvg/logoListaRepor.svg";
import { ReactComponent as MatEduIcon } from "./../../img/iconSvg/logoListaMatEdu.svg";
import { ReactComponent as GuideDocIcon } from "./../../img/iconSvg/logoListaDocente.svg";
import { ReactComponent as PrintableActIcon } from "./../../img/iconSvg/logoListaAImprimibles.svg";
// import { isBuffer } from "util";

export interface Props {
  location: any;
  rol: any;
}

export default class DetailPage extends React.Component<
  Props & RouteProps,
  {
    resources: any;
    icon: any;
    menu: any;
    component: any;
    graphics: any;
    hiddenInterfaces: any;
    seeButton: Boolean;
    isLoading: boolean;
    textDescSubject: string;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      resources: [],
      menu: [],
      graphics: [
        { total: 1, current: 1, type: "videos" },
        { total: 2, current: 1, type: "actividades" },
        { total: 2, current: 1, type: "recursos" },
        { total: 2, current: 2, type: "contenidos" },
      ],
      isLoading: true,
      component: "",
      hiddenInterfaces: true,
      seeButton: true,
      icon: "",
      textDescSubject: "",
    };
  }

  componentWillMount() {
    console.log(this.props.location);
    this.getOpcionMenu();
    this.hiddenInterface(this.props.location.state.idSubject);
  }

  componentDidMount() {
    this.getTextSubject(this.props.location.state.idSubject);
    this.setState({
      component: <PageInit />,
    });
    this.knowRol();
    if (this.state.resources.length > 0) {
      this.setState({ isLoading: false });
    }
  }

  componentDidUpdate(prevState: any) {
    console.log(" this.state.isLoading", this.state.isLoading);
    if (
      this.state.resources !== prevState.resources &&
      this.state.isLoading === true
    ) {
      this.setState({ isLoading: false });
    }
  }

  knowRol() {
    // Se utiliza el rol traido de la base de datos y la materia para mostrar el botón de reportes, 6 = competencias transversales
    if (
      this.props.location.state.rol === "est" &&
      this.props.location.state.idSubject !== 6
    ) {
      this.setState({ seeButton: false });
    }
  }

  callComponent(idResource: any, idSubject: any) {
    // Ingresa el id del menú para llamar al componente 1:video 2:actividades 3:recursos 4:contenido 5:material educativo 6: guías docente 7: actividades imprimibles
    let newComponent;
    switch (idResource) {
      case 1:
        newComponent = (
          <Videos
            Arrayresources={this.state.resources}
            grade={this.props.location.state.grade}
            subject={this.props.location.state.subject}
            rol={this.props.location.state.rol}
          />
        );
        break;
      case 2:
        newComponent = (
          <Activity
            Arrayresources={this.state.resources}
            grade={this.props.location.state.grade}
            subject={this.props.location.state.subject}
          />
        );
        break;
      case 3:
        newComponent = (
          <Resources
            Arrayresources={this.state.resources}
            grade={this.props.location.state.grade}
            subject={this.props.location.state.subject}
          />
        );
        break;
      case 4:
        newComponent = (
          <Lessons
            rol={this.props.location.state.rol}
            url={this.state.resources[0].resource_url}
          />
        );
        break;
      case 5:
        newComponent = <EduMaterial resource_id={this.state.resources[0].id} thumb={this.state.resources[0].resource_name} url={this.state.resources[0].resource_url} rol={this.props.location.state.rol} />;
        break;
      case 6:
        newComponent = <Models
          Arrayresources={this.state.resources}
          grade={this.props.location.state.grade}
          subject={this.props.location.state.subject}
        />;
        break;
      case 7: // Componente para Guías Docente
        newComponent = (
          <TeacherGuides
            Arrayresources={this.state.resources}
            grade={this.props.location.state.grade}
            subject={this.props.location.state.subject}
          />
        );
        break;
      case 8: // Componente para Actividades Imprimibles
        newComponent = (
          <PrintableActivities
            Arrayresources={this.state.resources}
            grade={this.props.location.state.grade}
            subject={this.props.location.state.subject}
          />
        );
        break;
      default:
        newComponent = <PageInit />;
    }
    this.setState({ component: newComponent });
  }

  callReportComponent() {
    this.setState({
      component: (
        <ReportStudent
          graphics={this.state.graphics}
          area={this.props.location.state.idSubject}
        />
      ),
    });
  }

  hiddenInterface(id: any) {
    // Se hace metodo para esconder interfaz y mostrar la de "desafío para aprender" con el id de la materia que en este momento es 6: competencias transversales
    if (id === 6) {
      this.setState({ hiddenInterfaces: false });
    }
  }

  // ----------------Peticiones base de datos----------------
  getResourcesDB(idResource: any, idSubject: any, idGrade: any) {
    // Json para enviar en el post y pedir el recurso
    console.log(idResource);
    let dataResource = {
      resource_type_id: idResource,
      grade_id: idGrade,
      knowledge_area_id: idSubject,
    };
    console.log(dataResource);
    POST_AXIOS("resources/specific", dataResource)
      .then((res) => {
        console.log(res.data);
        this.setState({ component: "" });
        this.setState({ resources: res.data });
        this.callComponent(idResource, idSubject);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // Metodo para obtener la descripción de la materia 
  getTextSubject(idSubject: any) {
    console.log(idSubject);
    if (idSubject !== 0) {
      GET_AXIOS(`knowledgeAreas/${idSubject}`).then((res: any) => {
        console.log(res.data);
        let resp = res.data.desc;
        this.setState({ textDescSubject: resp });
      });
    } else {
      this.setState({
        textDescSubject:
          "No hay descripción disponible en este momento para esta materia.",
      });
    }
  }

  getOpcionMenu() {
    // Traer las opciones del menú lateral izquierdo de la base de datos
    GET_AXIOS(
      `resourceTypes/${this.props.location.state.idSubject}/${this.props.location.state.idGrade}`
    )
      .then((res: any) => {
        console.log(res.data);
        if (res.data.length === 0) {
          this.setState({
            menu: [
              {
                resource_type_name:
                  "Actualmente no existen recursos disponibles para el grado y área seleccionados",
              },
            ],
          });
        } else {
          this.setState({ menu: res.data });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          menu: [
            {
              resource_type_name:
                "Actualmente no existen recursos disponibles para el grado y área seleccionados",
            },
          ],
        });
      });
  }

  render() {
    const { menu, seeButton, textDescSubject } = this.state;
    const {
      grade,
      subject,
      idSubject,
      rol,
      idGrade,
      cc,
      userName,
    } = this.props.location.state;

    const assignIcon: any = {
      1: <VideoIcon className="af-list af-icon" />,
      2: <ActivityIcon className="af-list af-icon" />,
      3: <ResourceIcon className="af-list af-icon" />,
      4: <AulaIcon className="af-list af-icon" />,
      5: <MatEduIcon className="af-list af-icon" />,
      7: <GuideDocIcon className="af-list af-icon" />,
      8: <PrintableActIcon className="af-list af-icon" />
    };

    return (
      <div>
        <div className="principal-page">
          {/* Muestra el menú si la materia no es competencias transversales */}
          {this.state.hiddenInterfaces ? (
            <Row className="w-100 af-main m-0 pb-5">
              <Col className="d-none d-md-block pl-0 mb-5" md={3}>
                <div className="mt-5">
                  <Link
                    className="af-buttonHome d-flex justify-content-center p-2"
                    to="/chooseContent"
                  >
                    <TiHomeOutline />
                  </Link>
                </div>
                <div className="af-tittle p-2 mt-4 d-flex justify-content-center align-self-center align-middle text-center">
                  <p className="m-0">
                    {subject} {grade}
                  </p>
                </div>
                <p className="af-text p-2">{textDescSubject}</p>
                {menu.map((resour: any, i: any) => (
                  <Button
                    key={i}
                    className="af-list p-2 mt-1 d-flex justify-content-start text-left align-self-start "
                    onClick={() =>
                      this.getResourcesDB(resour.id, idSubject, idGrade)
                    }
                  >
                    <IoMdArrowDropright className="mt-2" />

                    <div className="af-div pr-1">{assignIcon[resour.id]}</div>

                    <p className="m-0">{resour.resource_type_name}</p>
                  </Button>
                ))}
                {/* muestra el botón de reporte si el usuario es estudiante */}
                {!seeButton && (
                  <div>
                    {/* Texto entre botones del menú */}
                    <Col className="text-left w-100 p-1 mt-2" md={12}>
                      <h4 className="af-tittleProgress pl-2">
                        <strong>Progreso</strong>
                      </h4>
                      <p className="af-text w-100 text-justify">
                        En el siguiente informe encontrarás una descripción de tu estado y progreso en relación con el acceso y uso de los contenidos y/o recursos propios de esta área.
                      </p>
                    </Col>
                    {/* Botón de reporte */}
                    <Button
                      className="af-list p-2 mt-1 d-flex justify-content-start align-self-start "
                      onClick={() => this.callReportComponent()}
                    >
                      <IoMdArrowDropright className="mt-2" />
                      <div className="w-25 pr-1">
                        <ReportIcon className="af-list af-icon" />
                      </div>
                      <p className="m-0">Reportes</p>
                    </Button>
                  </div>
                )}
              </Col>
              <Col className="w-100 p-0" md={9}>
                <Row className="w-100 m-0 justify-content-center align-self-center align-middle text-center">
                  <Col md={9} sm={12}>
                    <Col className="pb-4 m-0 p-0" md={12}>
                      {this.state.component}
                    </Col>
                  </Col>
                </Row>
              </Col>
              {/* Botón flotante */}
              <div className="d-md-none d-lg-none d-xl-none">
                <div className="float" id="menu-share">
                  <IoIosMenu />
                </div>
                <ul className="af-ulButtonfloat">
                  {menu.map((resour: any, i: any) => (
                    <li key={i}>
                      <Button
                        className="my-float"
                        onClick={() =>
                          this.getResourcesDB(resour.id, idSubject, idGrade)
                        }
                      >
                        <div className="af-div2 pr-1">
                          {assignIcon[resour.id]}
                        </div>
                      </Button>
                    </li>
                  ))}
                  {/* Botón Home */}
                  <li>
                    <Button className="my-float">
                      <Link to="/chooseContent">
                        <TiHomeOutline className="af-iconHomenFloat" />
                      </Link>
                    </Button>
                  </li>
                  {/* Botón de reporte */}
                  {!seeButton && (
                    <li>
                      <Button
                        className="my-float"
                        onClick={() => this.callReportComponent()}
                      >
                        <div className="w-25 pr-1">
                          <ReportIcon className="my-float af-icon" />
                        </div>
                      </Button>
                    </li>
                  )}
                </ul>
              </div>
            </Row>
          ) : (
            // Componente de competencias transversales
            <Row className="w-100 af-main m-0 pb-5">
              <Col className="w-100 p-0" md={12}>
                <ChallengeLearn
                  rol={rol}
                  cc={cc}
                  idGrade={idGrade}
                  grade={grade}
                  textDescSubject={textDescSubject}
                  userName={userName}
                />
              </Col>
            </Row>
          )}
        </div>

        <FootPages></FootPages>
      </div>
    );
  }
}