import React from "react";
import { Card } from "react-bootstrap";
import "./CardNews.scss";

export default function CardNews({
  title,
  data,
  link,
  img,
}: {
  title: string;
  link: string;
  img: string;
  data: any;
}) {
  return (
    <Card
      className="text-center card-news rounded-0 mb-4"
      onClick={() => {
        if (link) {
          window.open(link, "_blank");
        }
      }}
    >
      {/* <Card.Header className="head-Card pt-1 pb-1 pl-4 pr-4">
        <Card.Title>{title}</Card.Title>
      </Card.Header> */}
      <Card.Body className="p-0 rounded-0">
        <div
          className="new-img"
          style={{ backgroundImage: `url(${img})` }}
        ></div>
        {/* <img src={img} className="new-img" alt="cosas" /> */}
      </Card.Body>
    </Card>
  );
}
