import React, { useState, useEffect } from "react";

//import componentes y vistas
import { Form, Row, Col, Button } from "react-bootstrap";

//import elmentos de react
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

import ModalGeneral from "../../components/modalGeneral/modalGeneral";

// import axios from 'axios';
import { POST_AXIOS, GET_AXIOS } from "./../../components/funtionsREST";

//import imagenes
import "./formCoordinator.scss";
import Pdf from "react-to-pdf";

export interface Props {
  //   history: any;
  //   rol: any;
  refToPrint: React.RefObject<HTMLDivElement>;
}

export default function FormCoordinator(props: Props) {
  const initialFetchedDataState = {
    caller_name: "",
    conclusion: "",
    desc_request: "",
    email: "",
    email_type_id: "",
    email_type_name: "",
    headquarter_id: "",
    headquarter_name: "",
    institution_id: "",
    institution_name: "",
    observations: "",
    phone: "",
    phone_type_id: "",
    phone_type_name: "",
    request_id: "",
    request_name: "",
    role_id: "",
    role_name: "",
    serial_code: "",
    solution: "",
    town_id: "",
    town_name: "",
    grade_id: "",
    grade_name: "",
    process_desc: "",
    achievements: "",
    improvement: "",
    status: 0,
  };
  //  variable to validate form
  const [validated, setValidated] = useState(false);
  const [code, setCode] = useState();
  const [haveEmail, sethaveEmail] = useState(false);
  const [sede, setSedes] = useState([]);
  // const [group, setGroup] = useState([]);
  const [grades, setGrades] = useState([]);
  const [emailTypes, setEmailTypes] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [buttonState, setbuttonState] = useState(0);

  //FormData
  const [fetchedData, setfetchedData] = useState(initialFetchedDataState);

  //Modal
  const [show, setShow] = useState(false);
  const [tittleModal, setTittleModal] = useState("");
  const [textModal, setTextModal] = useState("");

  useEffect(() => {
    // GET_AXIOS('emailTypes').then(res => {
    //   if (res.data) setEmailTypes(capitalizeWords(res.data, "name"));
    // }).catch(e => {
    //   console.log("Error getting phone types: " + e)
    // })
    // setGroup(
    //   capitalizeWords(
    //     [
    //       { id: 1, name: "1" },
    //       { id: 2, name: "2" },
    //       { id: 3, name: "3" },
    //       { id: 4, name: "4" },
    //     ],
    //     "name"
    //   )
    // );
    // setSedes(
    //   capitalizeWords(
    //     [
    //       { id: 1, name: "ie arturo gomez jaramillo" },
    //       { id: 2, name: "ie san jose" },
    //       { id: 3, name: "ie agricola campoalegre" },
    //       { id: 4, name: "ie eleazar libreros salamanca" },
    //     ],
    //     "name"
    //   )
    // );
    GET_AXIOS("grades")
      .then((res) => {
        if (res.data) setGrades(capitalizeWords(res.data, "grade_name"));
      })
      .catch((e) => {
        console.log("Error getting phone types: " + e);
      });
    // setGrades(
    //   capitalizeWords(
    //     [
    //       { id: 1, name: "Primero" },
    //       { id: 2, name: "Segundo" },
    //       { id: 3, name: "Tercero" },
    //       { id: 4, name: "Cuarto" },
    //     ],
    //     "name"
    //   )
    // );
    setEmailTypes(
      capitalizeWords(
        [
          { id: 1, name: "Propio" },
          { id: 2, name: "Familiar" },
          { id: 3, name: "Vecino" },
          { id: 4, name: "Otro" },
        ],
        "name"
      )
    );
  }, []);

  const capitalizeWords = (
    arr: Array<any>,
    keyInObj: String,
    all: boolean = false
  ) => {
    const toReturn: any = [];
    arr.map((el: any) => {
      let word: string = "";
      el[`${keyInObj}`].split(" ").map((wr: any, i: number) => {
        if (!all) {
          word += i === 0 ? wr[0].toUpperCase() + wr.slice(1) + " " : wr + " ";
        } else {
          word += wr[0].toUpperCase() + wr.slice(1) + " ";
        }
      });
      word = word.trim();
      toReturn.push({ id: el.id, [`${keyInObj}`]: word });
    });
    return toReturn;
  };

  const sendData = async (status: number) => {
    if (status === 3) {
      await GET_AXIOS("users/opCoords");
    }
    const dataToPost = { ...fetchedData };
    dataToPost.status = status;
    const res = await POST_AXIOS("accompaniments/new", dataToPost);
    if (res) {
      setShow(true);
      if (status === 3) {
        setTittleModal("Enviado");
        setTextModal("Información enviada con éxito");
      } else {
        setTittleModal("Guardado");
        setTextModal("Información guardada con éxito");
      }
    } else {
      setShow(true);
      setTittleModal("Error");
      setTextModal(
        "Hubo un error al enviar la información, por favor comunicarse con soporte"
      );
    }
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const form = event.currentTarget;
    console.log(validated);
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      sendData(4);
    }

    setValidated(true);

    //   const form = event.currentTarget;
    //   const elements = form.elements;
    //   let datetime = dataForm.datetime || "";
    //   datetime = datetime.replace(/T/, ' ').replace(/-/g, ':') + ":00"
    //   const jsonToPost = {
    //     datetime,
    //     callerName: elements.callerName.value,
    //     request_id: parseInt(elements.request_id.value),
    //     desc_request: elements.desc_request.value,
    //     role_id: parseInt(elements.role_id.value),
    //     town_id: parseInt(elements.town_id.value),
    //     institution_id: parseInt(elements.institution_id.value),
    //     phone: elements.hasPhone.checked ? elements.phone.value : "",
    //     phone_type_id: elements.hasPhone.checked ? parseInt(elements.phone_type_id.value) : null,
    //     observations: elements.observations.value
    //   }
    //   console.log("handleSubmit -> form", jsonToPost);
    //   if (form.checkValidity() === false) {
    //     event.preventDefault();
    //     event.stopPropagation();
    //   } else {
    //     POST_AXIOS('callcenter/new', jsonToPost).then(res => {
    //       if (res.status === 200) {
    //         alert('Registro guardado exitosamente.');
    //       }
    //     }).catch(e => {
    //       event.preventDefault();
    //       console.log('Error posting new callcenter register: ' + e);
    //     })
    //   }
    //   setValidated(true);
  };

  const handleChange = (event: any) => {
    if (event.target && event.target.id === "code_form") {
      setCode(event.target.value);
    }
    // console.log("handleChange -> event", event.target.value, event.target.id);
  };

  const enableButtons = (event: any) => {
    setbuttonState(event.target.value);
  };

  const handleCodeChange = async (event: any) => {
    const code = event.target.value;
    if (code.length >= 6) {
      const res = await GET_AXIOS("accompaniments/getCurrentInfo/" + code);
      if (res.data) {
        await setfetchedData(res.data);
        const headquartersRes = await GET_AXIOS(
          "headquarters/byInstId/" + res.data.institution_id
        );
        if (headquartersRes.data)
          await setSedes(capitalizeWords(headquartersRes.data, "hq_name"));
      } else {
        if (!res) {
          setTittleModal("Información no encontrada");
          setTextModal(
            "No se encuentra información con el código ingresado, es posible que el código no exista o que ya se haya enviado."
          );
          setShow(true);
        }
      }
    }
  };

  const handleHeadquarterChange = (event: any) => {
    const hq = event.target.value;
    const tmpFetchedData = { ...fetchedData };
    tmpFetchedData.headquarter_id = hq;
    setfetchedData(tmpFetchedData);
  };

  const handleGradeChange = (event: any) => {
    const grade = event.target.value;
    const tmpFetchedData = { ...fetchedData };
    tmpFetchedData.grade_id = grade;
    setfetchedData(tmpFetchedData);
  };

  const handleProcessChange = (event: any) => {
    const process_desc = event.target.value;
    const tmpFetchedData = { ...fetchedData };
    tmpFetchedData.process_desc = process_desc;
    setfetchedData(tmpFetchedData);
  };
  const handleAchievementsChange = (event: any) => {
    const achievements = event.target.value;
    const tmpFetchedData = { ...fetchedData };
    tmpFetchedData.achievements = achievements;
    setfetchedData(tmpFetchedData);
  };
  const handleImprovementChange = (event: any) => {
    const improvement = event.target.value;
    const tmpFetchedData = { ...fetchedData };
    tmpFetchedData.improvement = improvement;
    setfetchedData(tmpFetchedData);
  };

  const handleConclutionChange = (event: any) => {
    const improvement = event.target.value;
    const tmpFetchedData = { ...fetchedData };
    tmpFetchedData.conclusion = improvement;
    setfetchedData(tmpFetchedData);
  };

  const SendNprint = async (printF: any) => {
    console.log(validated);
    if (validated === true) {
      console.log("entre al if");
      window.print();
      await sendData(5);
    }
    setValidated(true);
    // document.body.style.width = "210mm";
    // document.body.style.height = "297mm";
    // printF();
    // document.body.style.width = "";
    // document.body.style.height = "";
  };

  const consultCode = () => {
    console.log(code);
    GET_AXIOS(`accompaiment/getCurrentInfo/${code}`).then((res) => {
      console.log(res);
    });
  };

  const handleModalClose = () => {
    setShow(false);
    setTittleModal("");
    setTextModal("");
    setfetchedData(initialFetchedDataState);
    setSedes([]);
  };

  return (
    <Row className="justify-content-center align-self-center text-center">
      <Col md={8}>
        <Form
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
          className="my-4 af-formAccompaniment"
        >
          <Row className="mb-2">
            {/* Date */}
            <Col
              md={4}
              className="ml-auto d-md-flex justify-content-end pr-md-1 mb-2 mb-md-0"
            >
              <div className="d-flex flex-column align-items-start">
                <b>Fecha y Hora</b>
                <DatePicker
                  selected={startDate}
                  // onChange={(e: any) => handleChange(e)}
                  onChange={(date: any) => handleChange(date)}
                  showTimeSelect
                  timeFormat="HH:mm"
                  id="datetime"
                  timeIntervals={15}
                  className="af-inputs px-3"
                  timeCaption="time"
                  dateFormat="yyyy/MM/dd h:mm aa"
                  disabled={true}
                />
              </div>
            </Col>
            <Col
              md={4}
              className="mr-auto d-flex justify-content-center justify-content-md-start pl-md-1 flex-column align-items-start"
            >
              {/* Code */}
              <b>Código</b>
              <Form.Control
                id="code"
                className="af-inputs"
                placeholder="Código"
                type="text"
                onChange={(e: any) => handleCodeChange(e)}
                style={{ width: "214px" }}
                maxLength={6}
                required
              />
              <Form.Control.Feedback type="invalid">
                Debe llenar el campo
              </Form.Control.Feedback>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md={6} className="pr-md-1 mb-2 mb-md-0 text-left">
              {/* Municipio */}
              <b>Municipio</b>
              <Form.Control
                id="town"
                className="af-inputs"
                placeholder="Municipio"
                disabled
                type="text"
                value={fetchedData.town_name || ""}
              />
            </Col>
            <Col md={6} className="pl-md-1 text-left">
              {/* Institución */}
              <b>Institución</b>
              <Form.Control
                id="institution"
                className="af-inputs"
                placeholder="Institución"
                disabled
                type="text"
                value={fetchedData.institution_name || ""}
              />
            </Col>
          </Row>

          {/* <Row className="mb-2">
            <Col md={6} className="pr-md-1 text-left">
              {/* Sede *
              <b>Sede</b>
              <Form.Control
                id="town_id"
                className="af-inputs"
                placeholder="Sede"
                onChange={(e: any) => handleHeadquarterChange(e)}
                disabled
                type="text"
                value={fetchedData.headquarter_name || ""}
              ></Form.Control>
            </Col>
            <Col md={6} className="pl-md-1 text-left">
              {/* Ciclo *
              <b>Ciclo</b>
              <Form.Control
                id="grade_id"
                className="af-inputs"
                placeholder="Ciclo"
                onChange={(e: any) => handleGradeChange(e)}
                disabled
                type="text"
                value={fetchedData.grade_name || ""}
              ></Form.Control>
            </Col>
          </Row> */}

          {/* Solución de Inquietud */}
          <Row className="mb-2 text-left">
            <Col>
              {/* Nombre */}
              <b>Nombre de la persona que llamó</b>
              <Form.Control
                id="nombre"
                className="af-inputs"
                placeholder="Nombre de la persona que llamó"
                disabled
                type="text"
                value={fetchedData.caller_name || ""}
              />
            </Col>
          </Row>
          <Row className="mb-2 ">
            {/* Teléfono */}
            <Col md={6} className="pr-md-1 mb-2 mb-md-0 text-left">
              <b>Teléfono</b>
              <Form.Control
                id="phone"
                className="af-inputs"
                placeholder="Teléfono"
                type="text"
                disabled
                value={fetchedData.phone || ""}
              />
            </Col>
            <Col md={6} className="pl-md-1 text-left">
              <b>Tipo de teléfono</b>
              <Form.Control
                id="phone_type"
                className="af-inputs"
                placeholder="Tipo de teléfono"
                type="text"
                disabled
                value={fetchedData.phone_type_name || ""}
              />
            </Col>
          </Row>
          <Row className="mb-2 ">
            {/* Rol */}
            <Col md={6} className="pr-md-1 mb-2 mb-md-0 text-left">
              <b>Rol</b>
              <Form.Control
                id="rol"
                className="af-inputs"
                placeholder="Rol"
                type="text"
                disabled
                value={fetchedData.role_name || ""}
              />
            </Col>
            <Col md={6} className="pl-md-1 text-left">
              {/* Tipo de Inquietud */}
              <b>Tipo de inquietud</b>
              <Form.Control
                id="request_id"
                className="af-inputs"
                placeholder="Tipo de inquietud"
                type="text"
                disabled
                value={fetchedData.request_name || ""}
              />
            </Col>
          </Row>
          <Form.Group className="text-left">
            <b>Inquietud</b>
            <Form.Control
              id="desc_request"
              className="af-inputs py-3 my-2"
              placeholder="Inquietud"
              onChange={(e: any) => handleChange(e)}
              as="textarea"
              rows={3}
              disabled
              value={fetchedData.desc_request || ""}
            />
            <b>Observaciones</b>
            <Form.Control
              id="observations"
              className="af-inputs py-3 my-2"
              placeholder="Observaciones"
              onChange={(e: any) => handleChange(e)}
              as="textarea"
              rows={3}
              disabled
              value={fetchedData.observations || ""}
            />
            <b>
              Descripción del proceso adelantado (incluir fechas y acciones
              realizadas)
            </b>
            <Form.Control
              id="process"
              className="af-inputs py-3 my-2"
              placeholder="Descripción del proceso adelantado (incluir fechas y acciones realizadas)"
              onChange={(e: any) => handleProcessChange(e)}
              disabled
              as="textarea"
              rows={3}
              value={fetchedData.process_desc || ""}
            />

            <b>Logros</b>
            <Form.Control
              id="achievements"
              className="af-inputs py-3 my-2"
              placeholder="Logros"
              onChange={(e: any) => handleAchievementsChange(e)}
              disabled
              as="textarea"
              rows={3}
              value={fetchedData.achievements || ""}
            />
            <b>Oportunidades de mejora</b>
            <Form.Control
              id="improvement"
              className="af-inputs py-3 my-2"
              placeholder="Oportunidades de mejora"
              onChange={(e: any) => handleImprovementChange(e)}
              disabled
              as="textarea"
              rows={3}
              value={fetchedData.improvement || ""}
            />
            <b>
              Validación del proceso adelantado - Abordaje de la necesidad de
              acompañamiento
            </b>
            <Form.Control
              id="conclution"
              className="af-inputs py-3 my-2"
              placeholder="Validación del proceso adelantado - Abordaje de la necesidad de acompañamiento"
              onChange={(e: any) => handleConclutionChange(e)}
              required
              as="textarea"
              rows={3}
              value={fetchedData.conclusion || ""}
            />
          </Form.Group>
          <Row>
            <Col md={6}>
              <Form.Control
                custom
                id="state"
                className="af-inputs af-select"
                onChange={(e: any) => enableButtons(e)}
                required
                as="select"
              >
                <option value="0" disabled selected>
                  Seleccione un Estado
                </option>
                <option value="2">En trámite</option>
                <option value="3">Finalizado</option>
              </Form.Control>
            </Col>
          </Row>

          <Form.Row className="right-0 justify-content-center align-self-center text-center mt-4">
            <Button
              className="af-buttonCcenterG mx-2"
              type="submit"
              style={{ backgroundColor: "#2665B4" }}
              // eslint-disable-next-line eqeqeq
              disabled={buttonState != 2}
            >
              <h4 className="m-0">Guardar</h4>
            </Button>

            {/*
                 // @ts-ignore */}
            <Pdf targetRef={props.refToPrint} filename="div-blue.pdf">
              {/*
                 // @ts-ignore */}
              {({ toPdf }) => (
                <Button
                  className="af-buttonCcenter mx-2 btn-alt"
                  type="button"
                  onClick={() => SendNprint(toPdf)}
                  // eslint-disable-next-line eqeqeq
                  disabled={buttonState != 3}
                >
                  <h4 className="m-0"> Enviar y generar informe</h4>
                </Button>
              )}
            </Pdf>
          </Form.Row>
        </Form>
      </Col>
      {/* modal de confirmación */}
      {show && (
        <ModalGeneral
          show={show}
          tittle={tittleModal}
          text={textModal}
          handleClose={handleModalClose}
          acceptModal={handleModalClose}
        />
      )}
    </Row>
  );
}
