import React from 'react';
import './eduMaterial.scss';

//impport elementos de react
import { Row, Col, Button, Image } from 'react-bootstrap';

//import imagenes
import logoEduMaterial from "./../../img/logoEduMaterial.png";
import { ReactComponent as MatEduIcon } from "./../../img/iconSvg/logoListaMatEdu.svg";

//import comoponentes y vistas
import { POST_AXIOS } from "../../components/funtionsREST";


export interface Props { resource_id: any, rol: any, url: any, thumb: any }
export default class EduMaterial extends React.Component<Props, {}> {


    componentDidMount() { }

    //funcion para post de conteo de progreso para video

    postProgressResource = () => {
        window.open(this.props.url, '_blank')
        let dataResource = {
            resource_id: this.props.resource_id,
        };
        POST_AXIOS("users/newResource", dataResource)
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    renderButtonResource(): any {
        if (this.props.thumb) {
            return (
                <Button className="af-thumbnail p-0"
                    onClick={this.postProgressResource}
                >
                    <Image className="" src={this.props.thumb}></Image>
                </Button>
            )
        }
        else {
            return (
                <Button className="af-buttonEdu text-sm-left"
                    onClick={this.postProgressResource}
                ><MatEduIcon className="af-imgButtonAula mx-2" />Haz clic aquí para visualizar el contenido.</Button>
            )
        }
    }

    render() {

        //---------------------------------------------------------------------------------
        return (
            <div >
                <Row className="justify-content-center text-center  w-100 m-0 h-100 pt-5">
                    <Col className="w-100 justify-content-center align-self-center text-center" md={3}>
                        <div className="af-lineOrange"></div>
                    </Col>
                    <Col className="m-0 p-0" md={6}>
                        <h2 className="af-tittleLesson">Materiales Educativos</h2>

                    </Col>

                    <Col className="w-100 justify-content-center align-self-center text-center" md={3}>
                        <div className="af-lineOrange"></div>
                    </Col>
                    <h2 className="af-tittleLesson">Módulos "Estudiando y Trabajando"</h2>

                </Row>
                <Row className="w-100 justify-content-center align-self-center text-center">
                    <p className="af-textComponent p-4">Los módulos son una guía que buscan motivar la buena disposición del estudiante hacia los retos del aprendizaje, integrar los contenidos académicos con procesos formativos, facilitar la comprensión de los saberes y la ejercitación de desempeños, orientar los procesos de enseñanza-aprendizaje y el trabajo autónomo y promover la evaluación formativa y la retroalimentación permanente.</p>

                    <Col md={12}>
                        <Image className="w-50" src={logoEduMaterial}></Image>
                    </Col>
                    <Col className="p-3" md={9}>
                        {this.renderButtonResource()}
                    </Col>

                </Row>
            </div>
        )
    }


}