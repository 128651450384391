import React, { useState, useEffect } from "react";
import './tutorials.scss';

//image
import decorationBacground from "../../img/decorationBacground.png";

//component react
import { Image, Row, Col, Nav } from "react-bootstrap";
import ReactPlayer from "react-player";

//Page, views, components 
import { GET_AXIOS } from "../../components/funtionsREST";
import LoadingPage from "../../components/spinner/loadingPage";

export default function Tutorials() {
    const scrollTo = (id: string) => window.scrollTo(0, document.getElementById(id)?.offsetTop || 0);

    const [tutorial, setTutorials] = useState<any[]>([]);  // Inicializa como un array vacío

    useEffect(() => {
        GET_AXIOS("tutorials")
            .then(res => {
                if (res && res.data) {  // Verifica si res no es false y tiene data
                    setTutorials(res.data);
                } else {
                    console.error("Error: La respuesta no contiene datos válidos.");
                    setTutorials([]);  // Asegura que tutorial siempre sea un array
                }
            })
            .catch(error => {
                console.error("Error fetching tutorials:", error);
                setTutorials([]);  // En caso de error, aseguramos que tutorial sea un array vacío
            });
    }, []);

    return (
        <div className="container">
            <Image className="af-backImage p3" src={decorationBacground} />
            <Row className="af-header af-background pt-5 mb-4" id="news">
                <Col
                    className="justify-content-center align-self-center text-center py-3"
                    md={4}
                >
                    <div className="af-lineOrange"></div>
                </Col>
                <Col
                    className="justify-content-center align-self-center text-center"
                    md={4}
                >
                    <h3 className="af-titleOrange">
                        <strong>Aprende a Navegar</strong>
                    </h3>
                </Col>
                <Col
                    className="justify-content-center align-self-center text-center py-3"
                    md={4}
                >
                    <div className="af-lineOrange"></div>
                </Col>
            </Row>
            {/* Navigation */}
            <Row>
                <Col md={4}>
                    <Nav className="flex-column sticky-top af-nav">
                        <h4 className="af-titleOrange">
                            <strong>Aprende a Usar</strong>
                        </h4>
                        <p className="d-none d-md-flex text-justify mx-4">Selecciona el rol y encuentra diferente contenido para aprender.</p>
                        {
                            tutorial.map((key: any, index: number) => (
                                <Nav.Item className="af-nav-parent ml-4" key={index}>
                                    <Nav.Link onClick={() => scrollTo('sect-' + key.name)}>
                                        <strong>{key.name}</strong>
                                    </Nav.Link>
                                    <div className="af-nav-children ml-2">
                                        {
                                            Array.isArray(key.tutorials) && key.tutorials.map((video: any, vidIndex: number) => (
                                                <Nav.Link key={vidIndex} onClick={() => scrollTo('sect-' + video.id)}>
                                                    {video.tutorial_name}
                                                </Nav.Link>
                                            ))
                                        }
                                    </div>
                                </Nav.Item>
                            ))
                        }
                    </Nav>
                </Col>
                <Col>
                    {
                        tutorial.map((key: any, index: number) =>
                            <div className="py-4 mb-4" id={"sect-" + key.name} key={index}>
                                <h4 className="af-titleOrange"><strong>{key.name}</strong></h4>
                                {
                                    Array.isArray(key.tutorials) && key.tutorials.map((video: any, vidIndex: number) => (
                                        <div className="text-left my-4" id={'sect-' + video.id} key={vidIndex}>
                                            <h6><strong>{video.tutorial_name}</strong></h6>
                                            <ReactPlayer
                                                width="100%"
                                                url={video.tutorial_url}
                                            />
                                        </div>
                                    ))
                                }
                            </div>
                        )
                    }
                </Col>
            </Row>
        </div>
    )
}