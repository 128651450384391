import React from "react";

//import componentes y vistas
// import { NavBar } from "../../components/navBar/navBar";
import FootPages from "../../components/footPage/footPage";
import { GET_AXIOS } from "../../components/funtionsREST";

//import elmentos de react
import { Row, Col, Button, Image } from "react-bootstrap";

// import axios from 'axios';

//import imagenes
import chooseContentReportes from "../../img/chooseContentReportes.png";



// import { stringify } from "querystring";

export interface Props {
  history: any;
  rol: any;
}

export default class DownloadExcel extends React.Component<{},{}> {
//   constructor(props: any) {
//     super(props);
//   }

  componentWillMount() {
   
  }

  componentDidMount() {
  }

  download(url:any, value:any = -1){
      if(value != -1){
        let link = `${process.env.REACT_APP_API_ENDPOINT}${url}/${value}`;
        window.open(link, "_blank");

      }else{
        let link = `${process.env.REACT_APP_API_ENDPOINT}${url}`;
        window.open(link, "_blank");
      }
  }

  render() {

    return (
      <div>
          <div className="principal-page">
            <Row className="w-100 af-divIntro m-0">
              <Col className="m-0" md={12}>
                <Row className="justify-content-center align-self-center text-center">
                  <Col className="pt-5" md={9}>
                    <h2 className="af-tittleChooseConten">
                      <strong>BIENVENIDO</strong>
                    </h2>
                    <p>Aqui podra descargar los excel</p>
                  </Col>
                </Row>
                <Row className="w-100 m-0 justify-content-center align-self-center text-center pb-3">
                  <Col className="" md={4}>
                    <div className="af-lineOrangechoose"></div>
                  </Col>
                </Row>

                <Row className="justify-content-center align-self-center text-center pt-3">
                  <Col md={6} lg={6}>
                    <Row className="justify-content-center align-self-center text-center mb-5">
                      <Col md={4} xs={12}>
                            <Button className="af-chooseAButton" onClick={()=>this.download("teacherProfiling")}>
                              <Image
                                className="w-50"
                                src={chooseContentReportes}
                              />
                              <p className="m-0 ">Perfilación docentes</p>
                            </Button>
                      </Col>
                      <Col md={4} xs={12}>
                            <Button className="af-chooseAButton" onClick={()=>this.download("profiledTeachers", 1)}>
                              <Image
                                className="w-50"
                                src={chooseContentReportes}
                              />
                              <p className="m-0">Docentes Perfilados</p>
                            </Button>
                      </Col>
                      <Col md={4} xs={12}>
                            <Button className="af-chooseAButton" onClick={()=>this.download("profiledTeachers", 0)}>
                              <Image
                                className="w-50"
                                src={chooseContentReportes}
                              />
                              <p className="m-0">Docentes No perfilados</p>
                            </Button>
                      </Col>
                    </Row>
                    <Col className="af-buttonList" md={12}></Col>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>


        <FootPages></FootPages>
      </div >
    );
  }
}
