import React from 'react';
import './zoomVideo.scss';

//impport elementos de react
import ReactPlayer from 'react-player'
import { Row, Button, Col } from 'react-bootstrap';
import { IoIosArrowBack } from 'react-icons/io';

//import comoponentes y vistas
import { POST_AXIOS } from "../../components/funtionsREST";


export interface Props {
    updateZoomVideo: any,
    resource_id: any,
    url: string,
    desc: string,
    grade: string,
    subject: string,
    tittle: any,
}

export default class ZoomVideo extends React.Component<Props, {}> {

    componentDidMount() {
        this.postProgressResource();
    }
    //funcion para post de conteo de progreso para video
    postProgressResource = () => {
        console.log(this.props)
        let dataResource = {
            resource_id: this.props.resource_id,
        };
        POST_AXIOS("users/newResource", dataResource)
            .then((res) => {
                console.log(res);
                return res;
            })
            .catch((err) => {
                console.log(err);
                return err;
            });
    }

    render() {
        return (
            <div >
                <Row>
                    <Col md={12}>
                        <ReactPlayer 
                        width="100%"
                        url={this.props.url} 
                        controls={true} 
                        />
                    </Col>
                    <h4 className="af-titleZoomVideo mt-2"><strong>{this.props.subject}  {this.props.grade}</strong></h4>
                    <p className="mt-2 text-justify"><strong>{this.props.tittle}</strong> DBA: {this.props.desc}</p>
                    <Row>
                        <Button className="af-buttonBackVideo m-2 p-0" onClick={(e: any) => this.props.updateZoomVideo(e)}><IoIosArrowBack /></Button><p className="af-textVideos" >Ver más videos</p>
                    </Row>
                </Row>

            </div>
        )
    }
}