import React from "react";
import "./chooseContent.scss";

//import componentes y vistas
// import { NavBar } from "../../components/navBar/navBar";
import FootPages from "../../components/footPage/footPage";

//import elmentos de react
import { Row, Col, Button, Image } from "react-bootstrap";
import { RouteProps } from "react-router";
import { Link } from "react-router-dom";
// import axios from 'axios';

//import imagenes
import decorationBacgroundUpper from "../../img/decorationBacgroundUpperContent.png";
import decorationBacgroundDown from "../../img/decorationDownContent.png";
import CoheteChooseContent from "../../img/CoheteChooseContent.png";
import FiguraChooseContent from "../../img/FiguraChooseContent.png";
import decorationBacground from "../../img/decorationBacground.png";
import chooseContentRecursos from "../../img/chooseContentRecursos.png";
import chooseContentReportes from "../../img/chooseContentReportes.png";
import logoMonitoreo from "../../img/logoMonitoreo.png";
import logoReportAgente from "../../img/logoReportAgente.png";
import logoAcompanamiento from "../../img/logoAcompanamiento.png";


// import { stringify } from "querystring";

export interface Props {
  history: any;
  rol: any;
}

export default class ChooseContent extends React.Component<
  Props & RouteProps,
  { textStart: any; name: any; pageReport: string }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      textStart: "",
      name: "",
      pageReport: "",
    };
  }

  componentWillMount() {
    // compare if user's role is secretaria, docente or Rect
    // if it's none, redirect to loginPage
    if (this.props.rol === "sec_ed" ||
      this.props.rol === "rec" ||
      this.props.rol === "dir_ge" ||
      this.props.rol === "inter_aco") {
      this.setState({ pageReport: "/selectreports" });
    } else {
      this.setState({ pageReport: "/report" });
    }

    if (
      this.props.rol === "sec_ed" ||
      this.props.rol === "doce" ||
      this.props.rol === "rec" ||
      this.props.rol === "dir_ge" ||
      this.props.rol === "inter_aco"
    ) {
    } else {
      this.props.history.push("/loginPage");
    }
    console.log(this.state.pageReport);
  }

  componentDidMount() {
    this.showStartText(this.props.rol);
  }

  //Muestra el texto de Bienvenida Dependiendo del rol del usuario
  showStartText(rol: any) {
    rol = this.props.rol;
    let newText;
    let newName;
    switch (rol) {
      case "doce":
        newText =
          "A continuación, podrás acceder a los contenidos y recursos para la enseñanza y el aprendizaje, organizados en las áreas básicas; así como también a los materiales educativos “Desafíos para Aprender” y a los reportes institucionales del acceso y uso de la plataforma por parte de la población de jóvenes extraedad y adultos beneficiarios, del acompañamiento a la implementación del Modelo Educativo Flexible Talentum, en el Valle del Cauca. \n Seleccione la sección a la que vas acceder:";
        newName = "BIENVENIDO(A) DOCENTE";
        break;
      case "rec":
        newText =
          "A continuación, tendrá acceso a contenidos y recursos para la enseñanza y aprendizaje, de las áreas básicas; así como también a los materiales educativos “Desafíos para Aprender”. Además, podrá visualizar reportes institucionales del acceso y uso de la plataforma, por parte de los docentes responsables del proceso formativo y los estudiantes jóvenes extraedad y adultos beneficiarios, del acompañamiento a la implementación del Modelo Educativo Flexible Talentum, en el Valle del Cauca. \n Seleccione la sección a la que va a acceder:";
        newName = "BIENVENIDO(A) RECTOR(A)";
        break;
      case "sec_ed":
        newText =
          "A continuación, tendrá acceso a contenidos y recursos para la enseñanza y aprendizaje, de las áreas básicas; así como también a los materiales educativos “Desafíos para Aprender”. Además, podrá visualizar el estado y progreso de las Instituciones Educativas, en relación con el acceso y uso de los contenidos y recursos disponibles en la plataforma, por parte de los docentes responsables del proceso formativo y los estudiantes jóvenes extraedad y adultos beneficiarios, del acompañamiento a la implementación del Modelo Educativo Flexible Talentum, en el Valle del Cauca. \n Seleccione la sección a la que va a acceder:";
        newName =
          "BIENVENIDO(A), DELEGADO(A) DE LA SECRETARÍA DE EDUCACIÓN DEPARTAMENTAL.";
        break;
      case "dir_ge":
        newText =
          "A continuación, tendrá acceso a contenidos y recursos para la enseñanza y aprendizaje, de las áreas básicas; así como también a los materiales educativos “Desafíos para Aprender”. Además, podrá visualizar el estado y progreso de las Instituciones Educativas, en relación con el acceso y uso de los contenidos y recursos disponibles en la plataforma, por parte de los docentes responsables del proceso formativo y los estudiantes jóvenes extraedad y adultos beneficiarios, del acompañamiento a la implementación del Modelo Educativo Flexible Talentum, en el Valle del Cauca. \n Seleccione la sección a la que va a acceder:";
        newName = "BIENVENIDO(A) DIRECTOR(A) GENERAL";
        break;
      case "inter_aco":
        newText =
          "A continuación, tendrás acceso a los contenidos y recursos para la enseñanza y aprendizaje de las áreas básicas, disponibles en la plataforma. También, podrás visualizar el estado y progreso de las Instituciones Educativas, en relación con el acceso y uso de los contenidos y recursos disponibles en la plataforma. \n Seleccione la sección a la que vas a acceder:";
        newName =
          "BIENVENIDO(A), INTEGRANTE DEL EQUIPO DE ACOMPAÑAMIENTO Y MONITOREO";
        break;
      default:
        newText = `A continuación encontrarás contenidos y/o recursos para el aprendizaje, organizados en las áreas básicas.\nPara ingresar a los contenidos y/o recursos, selecciona el área a la que vas a acceder:`;
        newName = "Bienvenido usuario";
    }
    this.setState({ textStart: newText, name: newName });
  }

  render() {
    const { textStart, name, pageReport } = this.state;

    return (
      <div>
        {/* <NavBar url="/chooseContent" name="Perfil" activeKey="4" auth={true}></NavBar> */}
        {this.props.rol !== "dir_ge" && (
          <div className="principal-page" style={{ marginBottom: "-160px" }}>
            <Row className="w-100 af-divIntro m-0 position-relative">
              <Image className="af-backImageUpper" src={decorationBacgroundUpper} />
              {/* Cohete a la izquierda */}
              <Image className="position-absolute" src={CoheteChooseContent} style={{ top: '75%', left: '5%', transform: 'translateY(-50%)', width: '15%', height: 'auto' }} />
              {/* Figura a la derecha */}
              <Image className="position-absolute" src={FiguraChooseContent} style={{ top: '72%', right: '2%', transform: 'translateY(-50%)', width: '15%', height: 'auto' }} />

              <Col className="m-0" md={12}>
                <Row className="justify-content-center align-self-center text-center">
                  <Col className="pt-5" md={9}>
                    <h2 className="af-tittleChooseConten">
                      <strong>{name}</strong>
                    </h2>
                    {textStart.split("\n").map((item: any, i: any) => (
                      <p key={i}>{item}</p>
                    ))}
                  </Col>
                </Row>
                <Row className="w-100 m-0 justify-content-center align-self-center text-center pb-3">
                  <Col md={4}>
                    <div className="af-lineOrangechoose"></div>
                  </Col>
                </Row>
                <Row className="justify-content-center align-self-center text-center pt-3">
                  <Col md={5} lg={4}>
                    <Row className="justify-content-center align-self-center text-center mb-3">
                      <Col md={6} xs={6} className="mb-3">
                        <div>
                          <Link to={pageReport}>
                            <Button className="af-chooseButton">
                              <Image className="w-50" src={chooseContentReportes} />
                              <p className="m-0">Reportes</p>
                            </Button>
                          </Link>
                        </div>
                      </Col>
                      <Col md={6} xs={6} className="mb-3">
                        <Link to="/introductionPage">
                          <Button className="af-chooseButton">
                            <Image className="w-50" src={chooseContentRecursos} />
                            <p className="m-0">Recursos</p>
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        )}

        {this.props.rol === "dir_ge" && (
          <div className="principal-page">
            <Row className="w-100 af-divIntro m-0">
              <Col className="m-0" md={12}>
                <Row className="justify-content-center align-self-center text-center">
                  <Col className="pt-5" md={9}>
                    <h2 className="af-tittleChooseConten">
                      <strong>{name}</strong>
                    </h2>
                    {textStart.split("\n").map((item: any, i: any) => (
                      <p key={i}>{item}</p>
                    ))}
                  </Col>
                </Row>
                <Row className="w-100 m-0 justify-content-center align-self-center text-center pb-3">
                  <Col md={4}>
                    <div className="af-lineOrangechoose"></div>
                  </Col>
                </Row>
                <Row className="justify-content-center align-self-center text-center mb-3">
                  <Col md={'auto'} xs={12}>
                    <Link to="/introductionPage">
                      <Button className="af-chooseButton mb-3">
                        <Image className="w-50" src={chooseContentRecursos} />
                        <p className="m-0">Recursos</p>
                      </Button>
                    </Link>
                  </Col>
                  <Col md={'auto'} xs={12}>
                    <div>
                      <Link to={pageReport}>
                        <Button className="af-chooseButton">
                          <Image className="w-50" src={chooseContentReportes} />
                          <p className="m-0">Reportes</p>
                        </Button>
                      </Link>
                    </div>
                  </Col>
                  <Col md={'auto'} xs={12}>
                    <div>
                      <Link to="/callcenterReport">
                        <Button className="af-chooseAButton">
                          <Image className="w-50" src={logoReportAgente} />
                          <p className="m-0">Reporte Gestor TIC</p>
                        </Button>
                      </Link>
                    </div>
                  </Col>
                  <Col md={"auto"} xs={12}>
                    <div>
                      <Link to="/selfManagementReport">
                        <Button className="af-chooseAButton">
                          <Image className="w-50" src={logoAcompanamiento} />
                          <p className="m-0" style={{ fontSize: 11 }}>
                            Reporte de Acompañamiento
                          </p>
                        </Button>
                      </Link>
                    </div>
                  </Col>
                  <Col md={'auto'} xs={12}>
                    <div>
                      <Link to="/finalSelfManagementReport">
                        <Button className="af-chooseButton">
                          <Image className="w-50" src={logoMonitoreo} />
                          <p className="m-0" style={{ fontSize: 11 }}>
                            Reporte Estado final de Acompañamiento
                          </p>
                        </Button>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        )}

        <Image className="af-backImageDown" src={decorationBacgroundDown} />
        <FootPages />
      </div>
    );
  }
}
