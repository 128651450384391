import React, { useEffect, useState } from "react";

import "./loginPage.scss";

// import funtions REST
import { POST_AXIOS, GET_AXIOS } from "../../components/funtionsREST";

//import components
import FootPages from "../../components/footPage/footPage";

//import elementos react
import { Button, Form, Row, Col, Image } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";

//Import imagenes
import decorationBacgroundUpper from "../../img/decorationBacgroundUpper.png"
import logoLogin from "../../img/logoLogin.png";
import boardLogin from "../../img/boardLogin.png";
import LoginCohete from "../../img/LoginCohete.png";
import LoginEstrellas from "../../img/LoginEstrellas.png";
import LoginFigura from "../../img/LoginFigura.png"
import LoadingPage from "../../components/spinner/loadingPage";

const LoginPage = ({ getDataUser }: any) => {
  //  variables to FORM user
  const [userName, setUserName] = useState<string>("");
  const [userPassword, setUserPassword] = useState<string>("");

  //  Variables to give feedbakck page
  const [showTextFeedbackLogin, setShowTextFeedbackLogin] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [loadPost, setLoadPost] = useState(false);

  //  Variable to redirect page
  const HISTORY = useHistory();
  // ------------------------------------- End variables
  // ------------------------------------- Hooks funtions
  useEffect(() => {
    /*
    get if user is loging or not
    if use is login redirect to next page
    */
    isUserLoging();
  }, []);

  // ------------------------------------- End Hooks funtions

  // ------------------------------------- global funtions components

  const isUserLoging = async () => {
    let response = await GET_AXIOS("users/profile");
    if (response !== false) {
      console.log("getIsUserLogout -> response", response);
      getDataUser(response.data);
      redirectPageAccordingRole(response.data.role, response.data.accompaniment);
    } else {
      setisLoading(false);
      redirectToLogin()
    }
    setisLoading(false);
  };

  const redirectPageAccordingRole = (role: String, accompaniment = true) => {
    console.log(role);
    if (role === "rec" || role === "doce" || role === "sec_ed" || role === "dir_ge" || role === "inter_aco") {
      // compare if role user is any of those
      if (role === "doce" && accompaniment === null) {
        return HISTORY.replace("/completeSignUp");
      }
      HISTORY.replace("/chooseContent");
    } else if (role === "ccenter") {
      HISTORY.replace("/callcenter"); //Link del call center 
    } else if (role === "coor_m") {
      HISTORY.replace("/chooseAgent"); //Link del call center 
    } else if (role === "coor_op") {
      HISTORY.replace("/coordinatorOp");
    } else {
      HISTORY.replace("/IntroductionPage");
    }
  };

  const redirectToLogin = async () => {
    let response = await GET_AXIOS(
      "https://apivalle.alternaticvas.com/loginsamlify"
    );
    console.log("asynchandleClickLogin -> response", response);
  }

  // ------------------------------------- END global funtions components

  // ------------------------------------- Form funtions

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoadPost(true);
    let responseLogin = await postUserDataForLogin();
    setLoadPost(false);
    setUserName("");
    setUserPassword("");

    if (responseLogin !== false) {
      getDataUser(responseLogin); // send data user to father component
      redirectPageAccordingRole(responseLogin.role, responseLogin.accompaniment);
    } else {
      setShowTextFeedbackLogin(true);
    }
  };

  const postUserDataForLogin = async () => {
    let jsonToPostLogin = {
      uid: userName,
      password: userPassword,
      remember: true,
    };

    let response = await POST_AXIOS("login", jsonToPostLogin);
    if (response !== false) {
      return response.data; // return data user
    } else {
      return response; // return false if data not correct
    }
  };
  // ------------------------------------- End Form funtions

  return (
    <div className="vh-100">
      {/* <NavBar url="/loginPage" activeKey="4" name="Ingresar"></NavBar> */}
      <LoadingPage>
        <>
          <div className="principal-page">
            <Row className="af-bodyLogin justify-content-center align-self-center">
              <Image className="af-backImageUpper" src={decorationBacgroundUpper} />
              <Col className="justify-content-center align-self-center text-center" md={6}>
                <Image className="" style={{ width: '60%', height: '90%', paddingTop: "5vh" }} src={logoLogin} />
                <Row className="justify-content-center align-self-center text-center">
                  <Col md={3}>
                  </Col>
                  <Col md={6}>
                    <Form className="form" onSubmit={handleSubmit}>
                      {showTextFeedbackLogin && (
                        <p className="text-danger text-justify af-error">
                          El usuario y la contraseña no coinciden con nuestros registros. Vuelva a intentarlo ó comuníquese con su Coordinador Municipal.
                        </p>
                      )}
                      <div className="form-container">
                        <Form.Group controlId="uid">
                          <Form.Control
                            className="custom-input text-center"
                            type="text"
                            placeholder="Usuario"
                            disabled={loadPost}
                            value={userName}
                            onChange={(e: any) => setUserName(e.target.value.trim())}
                          />
                        </Form.Group>
                        <Form.Group controlId="password">
                          <Form.Control
                            className="custom-input text-center"
                            type="password"
                            placeholder="Contraseña"
                            disabled={loadPost}
                            value={userPassword}
                            onChange={(e: any) => setUserPassword(e.target.value.trim())}
                          />
                        </Form.Group>
                      </div>
                      <Button className="af-buttonLogin w-50" type="submit">
                        {loadPost ? (
                          <React.Fragment>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span className="sr-only">Loading...</span>
                          </React.Fragment>
                        ) : (
                          "Ingresar"
                        )}
                      </Button>
                    </Form>
                  </Col>
                  <Col md={3}>
                    <Image className="d-none d-md-block af-astrosLogin" src={LoginCohete} />
                  </Col>
                </Row>

                {/* Nuevo div para controlar el desplazamiento del teclado y el mensaje */}
                <div className="additional-content">
                  <Image className="w-75 p-0 img-fluid af-boardLogin" src={boardLogin}></Image>
                  <Col md={12}>
                    {"Si eres docente o acudiente "}
                    <Link className="af-linkRegister" to="/register">
                      regístrate AQUÍ
                    </Link>
                  </Col>
                </div>
              </Col>
              <Image className="af-backImageForm d-flex align-items-end align-self-end align-bottom h-50 p3" src={LoginEstrellas} />
            </Row>
          </div>
          <Image className="d-none d-md-block af-astrosLogin2" src={LoginFigura} />
          <FootPages></FootPages>
        </>

      </LoadingPage>

    </div>
  );
};

export default LoginPage;
