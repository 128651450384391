import React, { useState, useEffect } from "react";
import "./coordMunReport.scss";
import { Row, Col, Button } from "react-bootstrap";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import filterFactory, {
  textFilter,
  selectFilter,
  Comparator,
} from "react-bootstrap-table2-filter";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import ToolkitProvider, { ColumnToggle } from "react-bootstrap-table2-toolkit";
import { TiHomeOutline } from "react-icons/ti";
import LoadingPage from "../../components/spinner/loadingPage";

import { Link } from "react-router-dom";
import {
  POST_AXIOS,
  GET_AXIOS,
  PUT_AXIOS,
} from "../../components/funtionsREST";

export default function CoordMunReport() {
  const { ToggleList } = ColumnToggle;
  const [townOptions, setTownOptions]: any = useState([
    { value: 0, label: "CALI" },
  ]);
  const [allInstitutions, setAllInstitutions]: any = useState([
    { value: 0, label: "IE ARTURO GOMEZ JARAMILLO" },
    { value: 1, label: "IE SAN JOSE" },
    { value: 3, label: "IE AGRICOLA CAMPOALEGRE" },
  ]);
  const [institutionOptions, setInstitutionOptions]: any = useState([]);
  // const [allHeadquarters, setAllHeadquarters]: any = useState([
  //   { value: 0, label: "ARTURO GOMEZ JARAMILLO" },
  // ]);
  // const [headquartersOptions, setHeadquartersOptions]: any = useState([]);
  // const [cyclesOptions, setCyclesOptions]: any = useState([
  //   { value: 0, label: "Ciclo 1" },
  // ]);
  const [coordMOptions, setCoordMOptions]: any = useState([
    { value: 0, label: "None" },
  ]);

  const [statusOptions, setStatusOptions]: any = useState([
    { value: 3, label: "NO TRATADO" },
    { value: 4, label: "EN TRÁMITE" },
    { value: 5, label: "FINALIZADO" },
  ]);

  const [products, setProducts]: any = useState([
    {
      serialCode: "000",
      dateTime: "01/01/00 12:00 AM",
      town_id: 0,
      institution_id: 1,
      headquarter_id: 0,
      grade_id: 0,
      process_desc: "No hay datos disponibles en este momento.",
      achievements: "No hay datos disponibles en este momento.",
      improvement: "No hay datos disponibles en este momento.",
      coord_m_id: 0,
      status:0,
    },
  ]);
  const [page, setPage]: any = useState(1);
  const [sizePerPage, setSizePerPage]: any = useState(10);
  const [totalSize, setTotalSize]: any = useState(products.length);
  const [hiddenColumns, setHiddenColumns]: any = useState([
    true,
    false,
    false,
    false,
    true,
    // true,
    // true, 
    true,
    true,
    true,
    false,
  ]);

  let columns = [
    {
      dataField: "status",
      text: "Estado",
      hidden: hiddenColumns[0],
      sort: true,
      formatter: (cell: number) =>
      statusOptions.find((opt: any) => opt.value === cell).label || "",
      filter: selectFilter({
        options: statusOptions,
      }),
    },
    {
      dataField: "serial_code",
      text: "Código serial",
      hidden: hiddenColumns[1],
      sort: true,
    },
    {
      dataField: "dateTime",
      text: "Fecha y hora",
      hidden: hiddenColumns[2],
      sort: true,
    },
    {
      dataField: "town_id",
      text: "Municipio",
      hidden: hiddenColumns[3],
      sort: true,
      formatter: (cell: number) =>
        townOptions.find((opt: any) => opt.value === cell).label || "",
      filter: selectFilter({
        options: townOptions,
      }),
    },
    {
      dataField: "institution_id",
      text: "Institución",
      hidden: hiddenColumns[4],
      sort: true,
      formatter: (cell: number) =>
        allInstitutions.find((opt: any) => opt.value === cell).label || "",
      filter: selectFilter({
        options: institutionOptions,
      }),
    },
    // {
    //   dataField: "headquarter_id",
    //   text: "Sede",
    //   hidden: hiddenColumns[4],
    //   sort: true,
    //   formatter: (cell: number) =>
    //     allHeadquarters.find((opt: any) => opt.value === cell).label || "",
    //   filter: selectFilter({
    //     options: headquartersOptions,
    //   }),
    // },
    // {
    //   dataField: "grade_id",
    //   text: "Ciclo",
    //   hidden: hiddenColumns[5],
    //   sort: true,
    //   formatter: (cell: number) =>
    //     cyclesOptions.find((opt: any) => opt.value === cell).label || "",
    //   filter: selectFilter({
    //     options: cyclesOptions,
    //   }),
    // },
    {
      dataField: "process_desc",
      text: "Descripción del proceso",
      hidden: hiddenColumns[5],
      sort: true,
    },
    {
      dataField: "achievements",
      text: "Logros",
      hidden: hiddenColumns[6],
      sort: true,
    },
    {
      dataField: "improvement",
      text: "Oportunidades de mejora",
      hidden: hiddenColumns[7],
      sort: true,
    },
    {
      dataField: "coord_m_id",
      text: "Coordinador municipal / Gestor TIC",
      hidden: hiddenColumns[8],
      sort: true,
      formatter: (cell: number) =>
        coordMOptions.find((opt: any) => opt.value === cell).label || "",
      filter: selectFilter({
        options: coordMOptions,
      }),
    },

  ];

  useEffect(() => {
    async function callData(){

     await GET_AXIOS("accompaniments/towns").then((res) => {
        setTownOptions(formatWords(res.data, "town_name"));
      });
      await GET_AXIOS("accompaniments/institutions").then((res) => {
        setAllInstitutions(formatWords(res.data, "inst_name"));
      });
      // GET_AXIOS("accompaniments/headquarters").then((res) => {
      //   setAllHeadquarters(formatWords(res.data, "hq_name"));
      // });
      // GET_AXIOS("grades").then((res) => {
      //   setCyclesOptions(formatWords(res.data, "grade_name"));
      // });
      await GET_AXIOS("accompaniments/users/coord_m").then((res) => {
        let data_ = res.data.filter((elem:any, index:any, self:any) => {
          console.log(res.data);
          return self.findIndex((t:any) => {
          
            return (t.id === elem.id && t.name === elem.name)
          }) === index});
          console.log(data_);
        data_.push({id: null, name:"NONE"});
          setCoordMOptions(formatWords(data_, "name"));
      });
      await GET_AXIOS(`accompaniments/3?perPage=${sizePerPage}`).then((res) => {
        const data = res.data.data.map((el: any) => {
          let dateEle = new Date(el.created_at);
          el.dateTime =
            dateEle.getFullYear().toString().substr(2) +
            "/" +
            dateEle.getMonth() +
            "/" +
            dateEle.getDate() +
            " " +
            dateEle.toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            });
          el.process_desc =
            el.process_desc &&
            el.process_desc.charAt(0).toUpperCase() + el.process_desc.slice(1);
          el.achievements =
            el.achievements &&
            el.achievements.charAt(0).toUpperCase() + el.achievements.slice(1);
          el.improvement =
            el.improvement &&
            el.improvement.charAt(0).toUpperCase() + el.improvement.slice(1);
          return el;
        });
        setProducts(data);
        setTotalSize(res.data.total);
      });
    }
    callData();
  }, []);

  const formatWords = (arr: Array<any>, keyInObj: String) => {
    const toReturn: any = [];
    arr.map((el: any) => {
      let word: string = "";
      el[`${keyInObj}`].split(" ").map((wr: any) => {
        if (wr) {
          word += wr.toUpperCase() + " ";
        }
      });
      word = word.trim();
      toReturn.push({ value: el.id, label: word });
    });
    return toReturn;
  };

   //Metodo de boton de descarga 
   const downloadbuttonReport = ()=>{
    console.log("entre")
    let typeRol = '3'; 
    let link = `${process.env.REACT_APP_API_ENDPOINT}accompaniments/download/${typeRol}`;
    window.open(link, "_blank");
  }
  //fin metodo boton de descarga

  const handleTableChange = (
    type: any,
    { page, sizePerPage, filters, sortField, sortOrder = "asc" }: any
  ) => {
    const {
      town_id,
      institution_id,
      headquarter_id,
      grade_id,
      coord_m_id,
      status,
    } = filters;
    console.log(status);
    if (town_id) {
      GET_AXIOS(`institutions/byTown/${town_id.filterVal}`).then((res) => {
        setInstitutionOptions(formatWords(res.data, "inst_name"));
      });
    } else {
      setInstitutionOptions([]);
    }
    // if (institution_id) {
    //   GET_AXIOS(`headquarters/byInstId/${institution_id.filterVal}`).then(
    //     (res) => {
    //       setHeadquartersOptions(formatWords(res.data, "hq_name"));
    //     }
    //   );
    // } else {
    //   setHeadquartersOptions([]);
    // }

    const jsonPOST = {
      town_id: town_id ? town_id.filterVal : false,
      institution_id: institution_id ? institution_id.filterVal : false,
      headquarter_id: headquarter_id ? headquarter_id.filterVal : false,
      grade_id: grade_id ? grade_id.filterVal : false,
      coord_m_id: coord_m_id ? coord_m_id.filterVal : false,
      page,
      quantPerPage: sizePerPage,
      columnOrder: sortField == "dateTime" ? "created_at" : "serial_code",
      orderType: sortOrder || "asc",
      status: status ? status.filterVal : false,
      initialStatus:3, 
    };

    POST_AXIOS("accompaniments/filterAndSort", jsonPOST).then((res) => {
      console.log(jsonPOST);
      console.log(res);
      const data = res.data.data.map((el: any) => {
        let dateEle = new Date(el.created_at);
        el.dateTime =
          dateEle.getFullYear().toString().substr(2) +
          "/" +
          dateEle.getMonth() +
          "/" +
          dateEle.getDate() +
          " " +
          dateEle.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          });
        el.process_desc =
          el.process_desc &&
          el.process_desc.charAt(0).toUpperCase() + el.process_desc.slice(1);
        el.achievements =
          el.achievements &&
          el.achievements.charAt(0).toUpperCase() + el.achievements.slice(1);
        el.improvement =
          el.improvement &&
          el.improvement.charAt(0).toUpperCase() + el.improvement.slice(1);
        return el;
      });
      setProducts(data);
      setPage(page);
      setTotalSize(res.data.total);
      setSizePerPage(sizePerPage);
    });
  };

  const handleSeeColumns = (field: any) => {
    const indexInColumns = columns.findIndex(
      (el: any) => el.dataField == field
    );
    const hiddenCols = hiddenColumns;
    let currentHidden = hiddenCols[indexInColumns];
    hiddenCols[indexInColumns] = !currentHidden;
    setHiddenColumns([...hiddenCols]);
  };

  const CustomToggleList = ({ columns, onColumnToggle, toggles }: any) => (
    <div
      className="list-altic mb-4 mt-2 btn-group btn-group-toggle w-100 mt-3"
      data-toggle="buttons"
    >
      {columns
        .map((column: any) => ({
          ...column,
          toggle: toggles[column.dataField],
        }))
        .map((column: any) => (
          <button
            type="button"
            key={column.dataField}
            className={`list-btn-custom-class btn btn-success ${
              column.toggle ? "active" : ""
            }`}
            data-toggle="button"
            aria-pressed={column.toggle ? "true" : "false"}
            onClick={() => handleSeeColumns(column.dataField)}
          >
            {column.text}
          </button>
        ))}
    </div>
  );

  return (
    <div >
        <div className="mt-2" style={{ position: "absolute", top: "82px" }}>
        <Link
          className="af-buttonHome d-flex justify-content-center p-2"
          to="/chooseContent"
        >
          <TiHomeOutline />
        </Link>
      </div>
      <div className="container-fluid px-4">
      <Row className="mt-5 pt-2">
        <Col className="justify-content-center align-self-center text-center py-3">
          <div className="af-lineOrange"></div>
        </Col>
        <Col
          className="justify-content-center align-self-center text-center"
          md={5}
        >
          <h3 className="af-titleOrange m-0">
            <strong>Reporte Coordinador Municipal</strong>
          </h3>
        </Col>
        <Col className="justify-content-center align-self-center text-center py-3">
          <div className="af-lineOrange"></div>
        </Col>
      </Row>
    
      {/* <BootstrapTable keyField='id' data={products} columns={columns} filter={filterFactory()} /> */}
      <ToolkitProvider
        keyField="id"
        data={products}
        columns={columns}
        // @ts-ignore: Unreachable code error
        columnToggle
      >
        {(props) => (
          <div>
            <span className="my-2">
              A continuación, podrás visualizar los reportes generados por los
              coordinadores municipales, en la atención realizada por los Gestores TIC.
            </span>
            <br></br>
            <span>Selecciona las columnas que desea consultar: </span>
            {/* <ToggleList
                            contextual="success"
                            className="list-altic mb-4 mt-2"
                            btnClassName="list-btn-custom-class"
                            {...props.columnToggleProps}
                            onColumnToggle={() => handleSeeColumns(props.columnToggleProps)}
                        /> */}
            <CustomToggleList
              contextual="success"
              {...props.columnToggleProps}
            />
            <hr />
            <BootstrapTable
              remote
              {...props.baseProps}
              filter={filterFactory()}
              // @ts-ignore: Unreachable code error
              noDataIndication="No hay Datos Aún"
              classes="table-altic"
              pagination={paginationFactory({ page, sizePerPage, totalSize })}
              onTableChange={handleTableChange}
            />
          </div>
        )}
      </ToolkitProvider>
      <div>
        <Button onClick={downloadbuttonReport} className="w-100 buttonDownloadReports">Descargar</Button>
      </div>
      </div>
    </div>
  );
}
