import axios from "axios";
import { trackPromise } from 'react-promise-tracker'

axios.defaults.withCredentials = true;

export const GET_AXIOS = async (url) => {
  try {
    let response = await trackPromise(axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}${url}`
    ))
    console.log("GET_AXIOS -> response", response);
    return response;
  } catch (error) {
    console.log("GET_AXIOS -> error", error);
    return false;
  }
};

export const GET_WORDPRESS = async (number) => {
  try {
    const response = await trackPromise(axios.get(
      `${process.env.REACT_APP_WORDPRESS_API_ENDPOINT}${number}`
    ))
    console.log("GET_AXIOS -> response", response);
    return response.data;
  } catch (error) {
    console.log("GET_AXIOS -> error", error);
    return false;
  }
};

export const POST_AXIOS = async (url, json) => {
  try {
    let response = await trackPromise(axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}${url}`,
      json
    ))
    console.log("POST_AXIOS -> response", response);
    return response;
  } catch (error) {
    return false;
  }
};

export const PUT_AXIOS = async (url, json) => {
  try {
    // axios.defaults.withCredentials = true;
    let response = await trackPromise(axios(
      `${process.env.REACT_APP_API_ENDPOINT}${url}`,
      {
        method: "put",
        data: json,
      },
      {
        withCredentials: true,
      }
    ))
    console.log("PUT_AXIOS -> response", response);
    return response;
  } catch (error) {
    return false;
  }
};

export const DELETE_AXIOS = async (url, json) => {
  try {
    let response = await trackPromise(axios(
      `${process.env.REACT_APP_API_ENDPOINT}${url}`,
      {
        method: "put",
        data: json,
      },
      {
        withCredentials: true,
      }
    ))
    console.log("PUT_AXIOS -> response", response);
    return response;
  } catch (error) {
    return false;
  }
};
