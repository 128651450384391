import React from "react";
import "./chooseCoordiOp.scss";

//import componentes y vistas
// import { NavBar } from "../../components/navBar/navBar";
import FootPages from "../../components/footPage/footPage";

//import elmentos de react
import { Row, Col, Button, Image } from "react-bootstrap";
import { RouteProps } from "react-router";
import { Link } from "react-router-dom";
// import axios from 'axios';

//import imagenes
import decorationBacgroundUpper from "../../img/decorationBacgroundUpperContent.png";
import decorationBacgroundDown from "../../img/decorationDownContent.png";
import chooseContentRecursos from "../../img/chooseContentRecursos.png";
import chooseContentReportes from "../../img/chooseContentReportes.png";
import logoReportAgente from "../../img/logoReportAgente.png";
import logoAcompanamiento from "../../img/logoAcompanamiento.png";

import logoMonitoreo from "../../img/logoMonitoreo.png";
// import { stringify } from "querystring";

export interface Props {
  history: any;
  rol: any;
}

export default class ChooseCoordiOp extends React.Component<
  Props & RouteProps,
  { textStart: any; name: any }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      textStart: "",
      name: "",
    };
  }

  componentWillMount() {
    // compare if user's role is secretaria, docente or Rect
    // if it's none, redirect to loginPage
    if (this.props.rol === "coor_op") {
    } else {
      this.props.history.push("/loginPage");
    }
  }

  componentDidMount() {
    this.showStartText(this.props.rol);
  }

  //Muestra el texto de Bienvenida Dependiendo del rol del usuario
  showStartText(rol: any) {
    rol = this.props.rol;
    let newText;
    let newName;
    switch (rol) {
      case "agent":
        newText =
          "A continuación, podrá acceder a los contenidos y recursos para la enseñanza y el aprendizaje, organizados en las áreas básicas, y a los reportes institucionales del acceso y uso de la plataforma por parte de los docentes y estudiantes en el marco de la implementación de las acciones pedagógicas de flexibilización y ajustes curriculares diseñados en el contexto de la emergencia sanitaria.";
        newName = "Bienvenido Asesor tecnopedagógico";
        break;
      case "coor_m":
        newText =
          "A continuación, tendrás acceso a los contenidos y recursos para la enseñanza y aprendizaje de las áreas básicas, disponibles en la plataforma.  \n Adicionalmente, encontrarás disponible el formato para el registro de las acciones implementadas que gestionarás y desarrollarás, en el marco de la atención educativa a los docentes responsables del proceso formativo y los estudiantes jóvenes extraedad y adultos beneficiarios, del acompañamiento a la implementación del Modelo Educativo Flexible Talentum, en el Valle del Cauca. \n Seleccione la sección a la que vas a acceder:";
        newName = "BIENVENIDO(A), COORDINADOR(A) DE MUNICIPIO - GESTOR(A) TIC";
        break;
      case "sec_ed":
        newText =
          "A continuación, podrá acceder a los contenidos y recursos para la enseñanza y el aprendizaje, organizados en áreas básicas, y a los reportes del acceso y uso de la plataforma por parte de las Instituciones Educativas, en el marco de la implementación de las acciones pedagógicas de flexibilización, y ajustes curriculares diseñados en el contexto de la emergencia sanitaria.";
        newName =
          "Bienvenido(a), Delegado(a) de la Secretaría de Educación Departamental";
        break;
      case "coor_op":
        newText =
          "A continuación, tendrás acceso a los reportes generados por los Gestores TIC. También, podrás visualizar el registro de las acciones de acompañamiento gestionadas y desarrolladas por los Coordinadores de Municipio. \n Adicionalmente, encontrarás disponible el formato para el reporte del estado final de las acciones de acompañamiento y monitoreo implementadas, en el marco de la atención educativa a los docentes responsables del proceso formativo y los estudiantes jóvenes extraedad y adultos beneficiarios, del acompañamiento a la implementación del Modelo Educativo Flexible Talentum, en el Valle del Cauca.\n Seleccione la sección a la que vas a acceder:";
        newName = "BIENVENIDO(A), COORDINADOR(A) OPERATIVO(A):";
        break;
      case "pta":
        newText = "Cambiar Texto!";
        newName = "Programa Todos Aprender";
        break;
      default:
        newText = `A continuación encontrarás contenidos y/o recursos para el aprendizaje, organizados en las áreas básicas.\nPara ingresar a los contenidos y/o recursos, selecciona el área a la que vas a acceder:`;
        newName = "Bienvenido usuario";
    }
    this.setState({ textStart: newText, name: newName });
  }

  render() {
    const { textStart, name } = this.state;

    return (
      <div>
        {/* <NavBar
          url="/chooseContent"
          name="Perfil"
          activeKey="4"
          auth={true}
        ></NavBar> */}
        <div className="principal-page">
          <Row className="w-100 af-divIntro m-0">
            <Image className="af-backImageUpper" src={decorationBacgroundUpper} style={{ position: "relative", zIndex: -1 }} />
            <Col className="m-0" md={12}>
              <Row className="justify-content-center align-self-center text-center">
                <Col className="pt-5" md={9}>
                  <h2 className="af-tittleChooseConten">
                    <strong>{name}</strong>
                  </h2>
                  {textStart.split("\n").map((item: any, i: any) => {
                    return <p key={i}>{item}</p>;
                  })}
                  {/* <p>{textStart}</p> */}
                </Col>
              </Row>
              <Row className="w-100 m-0 justify-content-center align-self-center text-center pb-3">
                <Col className="" md={4}>
                  <div className="af-lineOrangechoose"></div>
                </Col>
              </Row>

              <Row className="justify-content-center align-self-center text-center mb-5">
                {/* <Col className="p-2" md={"auto"} xs={12}>
                  <div>
                    <Link to="/callcenterReport">
                      <Button className="af-chooseAButton">
                        <Image className="w-50" src={logoReportAgente} />
                        <p className="m-0">Reporte Gestor TIC</p>
                      </Button>
                    </Link>
                  </div>
                </Col> */}
                {/* <Col className="p-2" md={"auto"} xs={12}>
                  <div>
                    <Link to="/coordinadorMunReport">
                      <Button className="af-chooseAButton">
                        <Image
                          className="w-50"
                          src={chooseContentReportes}
                        />
                        <p className="m-0">Reporte Coordinador</p>
                      </Button>
                    </Link>
                  </div>
                </Col> */}
                <Col className="p-2" md={"auto"} xs={12}>
                  <div>
                    <Link to="/report">
                      <Button className="af-chooseButton">
                        <Image
                          className="w-50"
                          src={chooseContentReportes}
                        />
                        <p className="m-0" style={{ fontSize: 11 }}>Reportes</p>
                      </Button>
                    </Link>
                  </div>
                </Col>
                <Col className="p-2" md={"auto"} xs={12}>
                  <div>
                    <Link to="/selfManagementReport">
                      <Button className="af-chooseAButton">
                        <Image className="w-50" src={logoAcompanamiento} />
                        <p className="m-0" style={{ fontSize: 11 }}>
                          Reporte de Acompañamiento
                        </p>
                      </Button>
                    </Link>
                  </div>
                </Col>
                {/* <Col className="p-2" md={"auto"} xs={12}>
                  <div>
                    <Link to="/coordinatorForm">
                      <Button className="af-chooseAButton mb-3">
                        <Image className="w-50" src={logoMonitoreo} />
                        <p className="m-0" style={{ fontSize: 11 }}>
                          Estado Final de Reporte Coordinador
                            </p>
                      </Button>
                    </Link>
                  </div>
                </Col> */}
                <Col className="p-2" md={"auto"} xs={12}>
                  <Link to="/finalSelfManagementForm">
                    <Button className="af-chooseAButton mb-3">
                      <Image className="w-50" src={logoMonitoreo} />
                      <p className="m-0" style={{ fontSize: 11 }}>
                        Estado Final de Reporte de Acompañamiento
                      </p>
                    </Button>
                  </Link>
                </Col>
                <Col className="p-2" md={"auto"} xs={12}>
                  <Link to="/introductionPage">
                    <Button className="af-chooseButton mb-3">
                      <Image
                        className="w-50"
                        src={chooseContentRecursos}
                      />
                      <p className="m-0">Recursos</p>
                    </Button>
                  </Link>
                </Col>
              </Row>
              <Col className="af-buttonList" md={12}></Col>
            </Col>
          </Row>
        </div>
        <Image className="af-backImageDown" src={decorationBacgroundDown} />
        <FootPages></FootPages>
      </div>
    );
  }
}
