import React from 'react';
import './resources.scss';

//impport elementos de react
import { Row, Col, Pagination } from 'react-bootstrap';

//import Imagenes
import recurAzul from '../../img/imgResources/recurAzul.png';
import recurAmar from '../../img/imgResources/recurAmar.png';
import recurAzulO from '../../img/imgResources/recurAzulO.png';
import recurNaran from '../../img/imgResources/recurNaran.png';
import recurVer from '../../img/imgResources/recurVer.png';

//import comoponentes y vistas
import SubComponent from '../subComponent/subComponent';
import { POST_AXIOS } from "../../components/funtionsREST";

export interface Props { Arrayresources: any, grade: any, subject: any }


export default class Resources extends React.Component<Props, { desc: string, resources: any, imagResourcesVideo: any, currentPage: any, elementPage: any, zoomVideo: boolean, grade: string }> {


    constructor(props: any) {
        super(props);
        this.state = {
            resources: this.props.Arrayresources,
            imagResourcesVideo: [recurAzul, recurAmar, recurAzulO, recurNaran, recurVer],
            currentPage: 1,
            elementPage: 6,
            zoomVideo: false,
            desc: '',
            grade: '',

        };
        this.handleClick = this.handleClick.bind(this);

    }
    componentDidMount() {
        console.log('entre  aa component Didmount')
        console.log(this.props.Arrayresources)
    }

    handleClick(event: any) {
        event.preventDefault();
        this.setState({ currentPage: Number(event.target.id) })
    }

    //funcion para post de conteo de progreso para video

    postProgressResource(resource_id: any) {
        let dataResource = {
            resource_id: resource_id,
        };
        POST_AXIOS("users/newResource", dataResource)
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    render() {
        const { resources, currentPage, elementPage, imagResourcesVideo } = this.state;

        //-----------------------------Paginación-----------------------------------
        const indexOfLastTodo = currentPage * elementPage;
        const indexOfFirstTodo = indexOfLastTodo - elementPage;
        const currentResources = resources.slice(indexOfFirstTodo, indexOfLastTodo);

        let active = currentPage;
        let numberPages = Math.ceil(this.state.resources.length / this.state.elementPage);
        let items = [];
        for (let number = 1; number <= numberPages; number++) {
            items.push(
                <Pagination.Item key={number} id={number} active={number === active} onClick={this.handleClick}>
                    {number}
                </Pagination.Item>,
            );
        }

        const paginationBasic = (
            <div> <Pagination>{items}</Pagination><br /></div>
        );
        //---------------------------------------------------------------------------------
        return (
            <div >
                <Row className="w-100 m-0 h-100 pt-5">
                    <Col className="justify-content-center align-self-center text-center p-3" md={4}>
                        <div className="af-lineOrange"></div>
                    </Col>
                    <Col md={4}>
                        <h2 className="af-tittleLesson">Recursos MEN</h2>
                    </Col>

                    <Col className="justify-content-center align-self-center text-center p-3" md={4}>
                        <div className="af-lineOrange"></div>
                    </Col>
                </Row>
                <Row>
                    <p className="af-textComponent p-4">Oferta de contenidos educativos digitales en todas las áreas de conocimiento, para que la comunidad educativa pueda complementar sus procesos de formación y aprendizaje, dispuestos desde el Portal Educativo Colombia Aprende. </p>

                    {currentResources.map((resour: any, i: any) => {
                        let indexImg = i >= 5 ? i - 5 : i;
                        return (
                            <Col key={i} md={4}>
                                {/* se adapta dependiendo del JSON de la base de datos para: url:resource_url, titulo:resource_name, desc:dba_name para src:src */}
                                <div onClick={() => this.postProgressResource(resour.id)}>
                                    <SubComponent tittle={`RM${(i+1)+indexOfFirstTodo}.`} url={resour.resource_url} src={imagResourcesVideo[indexImg]} desc={resour.dba_name}
                                    ></SubComponent>
                                </div>

                            </Col>
                        )
                    })}
                    {resources.length > 6 &&
                        < Row className="w-100 justify-content-center align-self-center text-center">
                            {paginationBasic}
                        </Row>
                    }
                </Row>


            </div >
        )
    }
}