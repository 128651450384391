import React from 'react';
import './TeacherGuides.scss';  // Estilos específicos para Guías Docentes

//impport elementos de react
import { Row, Col, Pagination } from 'react-bootstrap';

//import componentes y vistas
import SubComponent from '../subComponent/subComponent';

//import Imagenes
import guideAzul from '../../img/imgResources/guiaAzul.png';
import guideAmar from '../../img/imgResources/guiaAmar.png';
import guideRoj from '../../img/imgResources/guiaRoj.png';
import guideNaran from '../../img/imgResources/guiaNar.png';
import guideVer from '../../img/imgResources/guiaVer.png';

//importar la función POST_AXIOS para registrar el progreso
import { POST_AXIOS } from "../../components/funtionsREST";

export interface Props { 
    Arrayresources: any, 
    grade: any, 
    subject: any 
}

export default class TeacherGuides extends React.Component<Props, {
    imagResourcesGuide: any,
    resources: any,
    currentPage: any,
    elementPage: any
}> {

    constructor(props: any) {
        super(props);
        this.state = {
            imagResourcesGuide: [guideAzul, guideAmar, guideRoj, guideNaran, guideVer],
            resources: this.props.Arrayresources,
            currentPage: 1,
            elementPage: 6,
        };
        this.handleClick = this.handleClick.bind(this);
    }

    // Función que limpia el dominio incorrecto
    cleanUrl(url: string): string {
        const incorrectDomain = 'https://mefeivalle.universoeducaticvo.com/';
        if (url.startsWith(incorrectDomain)) {
            return url.replace(incorrectDomain, 'https://');
        }
        return url;
    }

    // Función para registrar el progreso del recurso
    postProgressResource(resource_id: any) {
        let dataResource = {
            resource_id: resource_id,
        };
        POST_AXIOS("users/newResource", dataResource)
            .then((res) => {
                console.log("Progreso registrado correctamente:", res);
            })
            .catch((err) => {
                console.log("Error al registrar progreso:", err);
            });
        console.log("Nombre del recurso: ", dataResource);
    }

    handleClick(event: any) {
        event.preventDefault();
        this.setState({ currentPage: Number(event.target.id) });
    }

    render() {
        const { resources, currentPage, elementPage, imagResourcesGuide } = this.state;

        //-----------------------------Paginación-----------------------------------
        const indexOfLastTodo = currentPage * elementPage;
        const indexOfFirstTodo = indexOfLastTodo - elementPage;
        const currentResources = resources.slice(indexOfFirstTodo, indexOfLastTodo);

        let active = currentPage;
        let numberPages = Math.ceil(resources.length / elementPage);
        let items = [];
        for (let number = 1; number <= numberPages; number++) {
            items.push(
                <Pagination.Item key={number} id={number} active={number === active} onClick={this.handleClick}>
                    {number}
                </Pagination.Item>,
            );
        }

        const paginationBasic = (
            <div><Pagination>{items}</Pagination><br /></div>
        );
        //---------------------------------------------------------------------------------
        return (
            <div>
                <Row className="w-100 m-0 h-100 pt-5">
                    <Col className="justify-content-center align-self-center text-center p-3" md={4}>
                        <div className="af-lineOrange"></div>
                    </Col>
                    <Col className="m-0 p-0" md={4}>
                        <h2 className="af-tittleLesson">Guías Docentes</h2>
                    </Col>
                    <Col className="justify-content-center align-self-center text-center p-3" md={4}>
                        <div className="af-lineOrange"></div>
                    </Col>
                </Row>

                <Row>
                    <p className="af-textComponent p-4">Recursos diseñados para apoyar a los docentes en la planificación, ejecución y evaluación de sus clases. Estas guías proporcionan estrategias pedagógicas y materiales de apoyo.</p>

                    {currentResources.map((resour: any, i: any) => {
                        let indexImg = i % 5;
                        let cleanResourceUrl = this.cleanUrl(resour.resource_url); // Limpiar la URL
                        return (
                            <Col key={i} md={4}>
                                {/* Llamada a la función de progreso cuando se clica el recurso */}
                                <div onClick={() => this.postProgressResource(resour.id)}>
                                    <SubComponent
                                        tittle={`TG${(i + 1) + indexOfFirstTodo}.`}
                                        url={cleanResourceUrl}
                                        src={imagResourcesGuide[indexImg]}
                                        desc={resour.dba_name}
                                    ></SubComponent>
                                </div>
                            </Col>
                        )
                    })}

                    {resources.length > elementPage &&
                        <Row className="w-100 justify-content-center align-self-center text-center">
                            {paginationBasic}
                        </Row>
                    }
                </Row>
            </div>
        )
    }
}
