import React, { useState, useEffect } from "react";

//import componentes y vistas
import ModalGeneral from "../modalGeneral/modalGeneral";
//import elmentos de react
import DatePicker from "react-datepicker";
import { Form, Row, Col, Button, FormGroup } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";

// import axios from 'axios';

//import imagenes
import { POST_AXIOS, GET_AXIOS } from "./../../components/funtionsREST";
import "./FormCallCenter.scss";

export interface Props {
  //   history: any;
  //   rol: any;
}

export default function CallCenter() {
  //  variable to validate form
  const [validated, setValidated] = useState(false);
  const [haveTelefonNumber, sethaveTelefonNumber] = useState(false);
  const [requests, setRequests] = useState([]);
  const [roles, setRoles] = useState([]);
  const [towns, setTowns] = useState([]);
  const [institutions, setInstitutions] = useState([]);
  const [phoneTypes, setPhoneTypes] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [codeForm, setCodeForm] = useState("");
  const [requestType, setRequestType] = useState("");
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const [dataModal, setDataModal] = useState({
    tittleModal: "",
    textModal: "",
    url: "",
  });

  // variable to Get data Form
  const [dataForm, setDataForm] = useState({ datetime: "" });

  useEffect(() => {
    GET_AXIOS("requests")
      .then((res) => {
        if (res.data) setRequests(capitalizeWords(res.data, "name"));
      })
      .catch((e) => {
        console.log("Error getting requests: " + e);
      });
    GET_AXIOS("accompaniments/roles")
      .then((res) => {
        //cambiar dirección
        if (res.data) setRoles(capitalizeWords(res.data, "name"));
      })
      .catch((e) => {
        console.log("Error getting roles: " + e);
      });
    GET_AXIOS("towns/byDepartment/1")
      .then((res) => {
        if (res.data) setTowns(capitalizeWords(res.data, "town_name"));
      })
      .catch((e) => {
        console.log("Error getting towns: " + e);
      });
    GET_AXIOS("phoneTypes")
      .then((res) => {
        if (res.data) setPhoneTypes(capitalizeWords(res.data, "name"));
      })
      .catch((e) => {
        console.log("Error getting phone types: " + e);
      });
  }, []);

  const capitalizeWords = (
    arr: Array<any>,
    keyInObj: String,
    all: boolean = false
  ) => {
    const toReturn: any = [];
    arr.map((el: any) => {
      let word: string = "";
      el[`${keyInObj}`].split(" ").map((wr: any, i: number) => {
        if (!all) {
          word += i === 0 ? wr[0].toUpperCase() + wr.slice(1) + " " : wr + " ";
        } else {
          word += wr[0].toUpperCase() + wr.slice(1) + " ";
        }
      });
      word = word.trim();
      toReturn.push({ id: el.id, [`${keyInObj}`]: word });
    });
    return toReturn;
  };
  const handleClose = () => setShow(false); //    Metodo para cerrar modal

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const form = event.currentTarget;
    const elements = form.elements;
    // let datetime = dataForm.datetime || "";
    // datetime = datetime.replace(/T/, " ").replace(/-/g, ":") + ":00";
    console.log(elements.callerName.value);
    let name = elements.callerName.value; //almacenar nombre de callcenter para formulario

    let jsonPost = {
      request_type: requestType,
      serial: null,
    };
    console.log(jsonPost);
    console.log(form.checkValidity());
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      //------------------------
    } else {
      POST_AXIOS("accompaniments/generateUniqueCode", jsonPost).then((res) => {
        console.log(res);
        if (res) {
          setCodeForm(res.data.serial_code);
          const jsonToPost = {
            serial_code: res.data.serial_code,
            caller_name: name.toUpperCase(), //poner nombre en mayuscula
            request_id: parseInt(elements.request_id.value),
            desc_request: elements.desc_request.value,
            role_id: parseInt(elements.role_id.value),
            town_id: parseInt(elements.town_id.value),
            status: 1,
            institution_id: parseInt(elements.institution_id.value),
            phone: elements.hasPhone.checked ? elements.phone.value : "",
            phone_type_id: elements.hasPhone.checked
              ? parseInt(elements.phone_type_id.value)
              : null,
            observations: elements.observations.value,
          };
          console.log(jsonToPost);

          postCallcenter(form, jsonToPost);
        } else {
          setCodeForm("null");
        }
      });
    }

    setValidated(true);
  };

  const postCallcenter = (form: any, jsonToPost: any) => {
    if (form.checkValidity() === true) {
      console.log(jsonToPost);
      POST_AXIOS("accompaniments/new", jsonToPost)
        .then((res) => {
          if (res.status === 200) {
            setShow(true);
            setDataModal({
              tittleModal: "Exitoso",
              textModal: "Registro guardado exitosamente.",
              url: "/callcenter",
            });
          }
        })
        .catch((e) => {
          setShow(true);
          setDataModal({
            tittleModal: "Lo sentimos",
            textModal: "Algo ha salido mal, por favor vuelve a intentarlo.",
            url: "/callcenter",
          });
        });
    }
    // setValidated(true);
  };

  const handleChange = (event: any) => {
    let value = event.target && event.target.value;

    if (event.target && event.target.id === "request_id") {
      //Funcion para conocer el id del tipo de inquietud
      setRequestType(event.target.value);
    }

    if (event.target && event.target.id === "town_id") {
      GET_AXIOS(`institutions/byTown/${value}`)
        .then((res) => {
          if (res.data)
            setInstitutions(capitalizeWords(res.data, "inst_name", true));
        })
        .catch((e) => {
          console.log("Error getting institutions: " + e);
        });
    }

    if (event.target) {
      setDataForm({
        ...dataForm,
        [event.target.id]: value,
      });
    }
  };

  return (
    <Row className="justify-content-center align-self-center text-center">
      <Col md={10}>
        <Form
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
          className="my-4 af-formCallcenter"
        >
          {/* Fecha y Hora */}
          <Row className="mb-2">
            <Col
              md={4}
              className="ml-auto d-flex justify-content-end pr-md-1 mb-2 mb-md-0 flex-column align-items-md-end"
            >
              <div className="d-flex align-items-start flex-column">
                <b>Fecha y hora</b>
                <DatePicker
                  selected={startDate}
                  // onChange={(e: any) => handleChange(e)}
                  onChange={(date: any) => handleChange(date)}
                  showTimeSelect
                  timeFormat="HH:mm"
                  id="datetime"
                  timeIntervals={15}
                  className="af-inputs px-3"
                  timeCaption="time"
                  dateFormat="yyyy/MM/dd h:mm aa"
                  disabled={true}
                />
              </div>
            </Col>
            <Col
              md={4}
              className="mr-auto d-flex justify-content-center justify-content-md-start pl-md-1 flex-column align-items-start"
            >
              <b>Código</b>
              <Form.Control
                id="code"
                className="af-inputs"
                placeholder="Código"
                disabled
                type="text"
                // value={codeForm}
                style={{ width: "214px" }}
              />
            </Col>
          </Row>

          {/* Nombre de quien llama */}
          <Form.Group className="text-left">
            <b>Nombre de quien llama</b>
            <Form.Control
              id="callerName"
              placeholder="Nombre de quien llama"
              className="af-inputs af-mayusName"
              onChange={(e: any) => handleChange(e)}
              required
              defaultValue={name}
              type="string"
            />
            <Form.Control.Feedback type="invalid">
              Debe llenar este campo
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="text-left">
            {/* Descripción de Inquietud */}
            <b>Descripción de la inquietud</b>
            <Form.Control
              id="desc_request"
              className="af-inputs py-3"
              placeholder="Descripción de la inquietud"
              onChange={(e: any) => handleChange(e)}
              required
              as="textarea"
              rows="3"
            />
            <Form.Control.Feedback type="invalid">
              Debe llenar este campo
            </Form.Control.Feedback>
          </Form.Group>
          <Row>
            <Col md={6}>
              <Row>
                <Col sm={12} className="pr-md-1 mb-2 text-left">
                  {/* Tipo de inquietud */}
                  <b>Tipo de inquietud</b>
                  <Form.Group>
                    <Form.Control
                      custom
                      id="request_id"
                      className="af-inputs af-select"
                      onChange={(e: any) => handleChange(e)}
                      required
                      as="select"
                      defaultValue=""
                    >
                      <option value="" disabled>
                        Seleccione un tipo de inquietud
                      </option>
                      {requests.map((rq: any, i: number) => (
                        <option value={rq.id} key={i}>
                          {rq.name}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Debe seleccionar una opción
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col sm={12} className="pr-md-1 mb-2 mb-md-0 text-left">
                  {/* Role */}
                  <FormGroup>
                    <b>Rol</b>
                    <Form.Control
                      id="role_id"
                      className="af-inputs af-select"
                      custom
                      onChange={(e: any) => handleChange(e)}
                      required
                      as="select"
                      defaultValue=""
                    >
                      <option value="" disabled>
                        Seleccione un rol
                      </option>
                      {roles.map((rl: any, i: number) => (
                        <option value={rl.id} key={i}>
                          {rl.name}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Debe seleccionar una opción
                    </Form.Control.Feedback>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <Col sm={12} className="pl-md-1 mb-2 text-left">
                  {/* Municipio */}
                  <b>Municipio</b>
                  <FormGroup>
                    <Form.Control
                      id="town_id"
                      className="af-inputs af-select"
                      custom
                      onChange={(e: any) => handleChange(e)}
                      required
                      as="select"
                      defaultValue=""
                    >
                      <option value="" disabled>
                        Seleccione un municipio
                      </option>
                      {towns.map((tw: any, i: number) => (
                        <option value={tw.id} key={i}>
                          {tw.town_name}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Debe seleccionar una opción
                    </Form.Control.Feedback>
                  </FormGroup>
                </Col>
                <Col sm={12} className="pl-md-1 mb-2 mb-md-0 text-left">
                  {/* Institución */}
                  <b>Institución</b>

                  <FormGroup>
                    <Form.Control
                      id="institution_id"
                      className="af-inputs af-select"
                      custom
                      onChange={(e: any) => handleChange(e)}
                      as="select"
                      defaultValue=""
                      required
                    >
                      <option value="" disabled>
                        Seleccione una institución
                      </option>
                      {institutions.map((inst: any, i: number) => (
                        <option value={inst.id} key={i}>
                          {inst.inst_name}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Debe seleccionar una opción
                    </Form.Control.Feedback>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>

          <Form.Group className="text-left">
            <Form.Check
              label="Tiene teléfono"
              id="hasPhone"
              className="ml-2 my-1"
              onClick={(e: any) => {
                sethaveTelefonNumber(!haveTelefonNumber);
              }}
            />
            <Form.Row>
              <Col md={6} className="mb-2 mb-md-0">
                <b>Teléfono</b>
                <Form.Control
                  id="phone"
                  className="af-inputs"
                  placeholder="Número de teléfono"
                  required={haveTelefonNumber}
                  disabled={!haveTelefonNumber}
                  onChange={(e: any) => handleChange(e)}
                  type="number"
                />
              </Col>
              <Col md={6}>
                <b>Tipo de teléfono</b>
                <Form.Control
                  id="phone_type_id"
                  className="af-inputs af-select"
                  custom
                  required={haveTelefonNumber}
                  disabled={!haveTelefonNumber}
                  onChange={(e: any) => handleChange(e)}
                  as="select"
                  defaultValue=""
                >
                  <option value="" disabled>
                    Seleccione un tipo de teléfono
                  </option>
                  {phoneTypes.map((pt: any, i: number) => (
                    <option value={pt.id} key={i}>
                      {pt.name}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Form.Row>
          </Form.Group>
          <Form.Group className="text-left">
            <Form.Row>
              <b>Observaciones</b>
              <Form.Control
                id="observations"
                className="af-inputs py-3"
                placeholder="Observaciones (opcional)"
                onChange={(e: any) => handleChange(e)}
                as="textarea"
                rows="3"
              />
            </Form.Row>
          </Form.Group>
          <Form.Row className="right-0 justify-content-center align-self-center text-center">
            <Button className="af-buttonCcenter w-25" type="submit">
              <h4 className="m-0">Enviar</h4>
            </Button>
          </Form.Row>
        </Form>
      </Col>
      {/* modal de confirmación */}
      {show && (
        <ModalGeneral
          show={show}
          tittle={dataModal.tittleModal}
          text={dataModal.textModal}
          handleClose={handleClose}
          url={dataModal.url}
        />
      )}
    </Row>
  );
}
