import React from "react";

import {  Button, Modal} from "react-bootstrap";


const ModalGeneral=(props:any) =>{
  
    return (
      <>
        <Modal show={props.show} onHide={props.handleClose}>
          <Modal.Header closeButton>
    <Modal.Title>{props.tittle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{props.text}</Modal.Body>
          <Modal.Footer>
            {/* <Button variant="secondary" onClick={props.handleClose}>
              Close
            </Button> */}
            <Button variant="primary" href={props.url} onClick={props.acceptModal}>
              Aceptar
            </Button>
          </Modal.Footer>
        </Modal>

      </>
    );
  }
  
  export default ModalGeneral;