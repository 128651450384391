import React from "react";
import "../reportTeacher.scss";

//impport elementos de react
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Title from "../../AdminUsers/Title/Title";
import DBA from "../../DBAReportTeach/DBAReportTeach";
import { GET_AXIOS } from "./../../funtionsREST";

//import imagenes
import IconInteractive from "../../../img/icon-interactive.svg";
import IconVideo from "../../../img/icon-video.svg";
import IconMen from "../../../img/icon-Men.svg";
import IconVirtual from "../../../img/icon-virtual.svg";
import IconButtonSend from "../../../img/icon-button-send.svg";
import IconMateEdu from "../../../img/icon-mateEdu.svg";

//import components and views
import LoadingPage from "../../spinner/loadingPage";


export interface Props {
  role: any;
  graphics: Array<string>;
  capitalize: any;
}

interface IState {
  grado: string;
  grupo: string;
  area: string;
  sede: string;
  institucion: string;
  municipio: string;
  grado2: string;
  grupo2: string;
  area2: string;
  sede2: string;
  institucion2: string;
  municipio2: string;
  showReportButton: boolean;
  showDiseableReportButton: boolean;
  showReport: boolean;
  towns: Array<object>;
  institutions: Array<object>;
  hqs: Array<object>;
  grades: Array<object>;
  groups: Array<object>;
  knowledgeAreas: Array<object>;
  showReportError: boolean;
  showNotGroup: boolean;
  disabledGrade:boolean;
  disabledArea:boolean;
  reports: Array<report>;
}

interface report {
  id: number;
  resource_type_name: string;
  totals: Array<object>;
}

export default class ReportTeachIntea extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    // No llames this.setState() aquí!
    this.state = {
      grado: "-1",
      grupo: "-1",
      area: "-1",
      sede: "-1",
      institucion: "-1",
      municipio: "-1",
      grado2: "",
      grupo2: "",
      area2: "",
      sede2: "",
      institucion2: "",
      municipio2: "",
      showReport: false,
      showReportError: false,
      showNotGroup: false,
      showDiseableReportButton: false,
      showReportButton: false,
      disabledGrade:false,
      disabledArea:false,
      towns: [],
      institutions: [],
      hqs: [],
      grades: [],
      groups: [],
      knowledgeAreas: [],
      reports: [],
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.GenerateReport = this.GenerateReport.bind(this);
    this.handleSelectInsti = this.handleSelectInsti.bind(this);
    this.handleSelectGrade = this.handleSelectGrade.bind(this);
  }

  handleInputChange(event: React.FormEvent<HTMLInputElement>) {
    const target = event.currentTarget;
    const value = target.value;
    const name = target.name;
    const me = this;
    console.log(value);

    this.setState({showReport:false, 
      showReportButton:false, 
      showDiseableReportButton:false,
      showReportError:false,
    });
    
    me.setState(
      (prevState) => ({
        ...prevState,
        [name]: value,
        showReportButton: true,
      }),
      () => {
        if (
          me.state.grado !== "-1" &&
          me.state.institucion !== "-1" &&
          me.state.area !== "-1"
        )
          me.setState({ showDiseableReportButton: true });
      }
    );
    console.log(name)

    if(name === "institucion"){
      let _institucion=this.state.institucion
      console.log(_institucion)
      console.log(value)
      GET_AXIOS(`grades/byInst/${value}`)
      .then((res) => {
        this.setState((prevState) => ({
          ...prevState,
          grado: "-1",
          grades: [],
        }));
        console.log(res);
        const _data: Array<object> = this.props.capitalize(
          res.data,
          "grade_name"
        );
        this.setState({ grades: _data });
      })
      .catch((e) => {
        console.log(e);
      });
    }
    
    if (name === "sede" || name === "grado") {
      let _hq = this.state.sede;
      let _grade = this.state.grado;
      if (name === "sede") _hq = value;
      if (name === "grado") _grade = value;
      if (this.state.sede !== "-1" || this.state.grado !== "-1") {
        GET_AXIOS(`getByheadquarterGrade/${_hq}/${_grade}`)
          .then((res) => {
            this.setState((prevState) => ({
              ...prevState,
              grupo: "-1",
              groups: [],
            }));
            const _data: Array<object> = res.data
              ? res.data.map((el: any) => ({ id: el, name: el }))
              : [];
            this.setState({ groups: _data, showNotGroup: false });
          })
          .catch((e) => {
            this.setState({ showNotGroup: true });
          });
      }
    }


    if(name === "grado"){
      console.log(value)
      this.getAreas(value);
    }
  }

  GenerateReport(event: React.FormEvent<HTMLFormElement>) {
    const jsonToReport = {
      institution: this.state.institucion,
      grade: this.state.grado,
      knowledge_area: this.state.area,
    };

    event.preventDefault();

    if (
      this.state.grado !== "-1" &&
      this.state.institucion !== "-1" &&
      this.state.area !== "-1"
    ) {
      GET_AXIOS(
        `stats/byAreaGradeTeachers/${jsonToReport.institution}/${jsonToReport.grade}/${jsonToReport.knowledge_area}`
      )
        .then((res) => {
          console.log(res.data);
          if (res.data !== undefined && res.data !== false) {
            this.setState({ reports: res.data }, () => {
              this.setState({
                grado2: jsonToReport.grade,
                area2: jsonToReport.knowledge_area,
                institucion2: jsonToReport.institution,
                showReport: true,
                showReportError: false,
              });
            });
          } else {
            this.setState({ showReport: false, showReportError: true });
          }
        })
        .catch((e) => {
          console.log(e);
          this.setState({
            showReport: false,
            showReportError: true,
          });
        });
    }
  }

  generatePDF() {
    const {institucion, grado, area } = this.state;

    window.open(
      `${process.env.REACT_APP_API_ENDPOINT}stats/generateReport?institution=${institucion}&grade=${grado}&knowledge_area=${area}`,
      "_blank"
    );
  }

  getValueFromArray(arr: Array<object>, idToSearch: string, toReturn: any) {
    const _ele: any = arr.find((el: any) => el.id == idToSearch);
    if (_ele) {
      return _ele[toReturn];
    }
  }

  componentDidMount() {
    GET_AXIOS("users/fisrtLocChildren")
      .then((res) => {
          console.log(res.data);
        const _data: Array<object> = this.props.capitalize(
          res.data,
          "inst_name"
        );
        this.setState({ institutions: _data });
      })
      .catch((e) => {
        console.log(e);
      });
 
  }

  handleSelectInsti(event: React.FormEvent<HTMLInputElement>){
    const target = event.currentTarget;
    const value = target.value;
    console.log(value)
    this.setState({
      showReport:false, 
      showReportButton:false, 
      showDiseableReportButton:false,
      showReportError:false,
      institucion:value,
    });

    if (value ==="-1"){
      this.setState({disabledArea:false, disabledGrade:false});
      this.setState((prevState) => ({
        ...prevState,
        grado:"-1",
        grades: [],
        area: "-1",
        knowledgeAreas: [],
      }));
    }
    if(value !== "-1"){
      GET_AXIOS(`grades/byInst/${value}`)
      .then((res) => {
        this.setState((prevState) => ({
          ...prevState,
          grado: "-1",
          grades: [],
          area: "-1",
        knowledgeAreas: [],
        }));
        console.log(res);
        const _data: Array<object> = this.props.capitalize(
          res.data,
          "grade_name"
        );
        this.setState({ grades: _data, disabledGrade: true });
      })
      .catch((e) => {
        console.log(e);
      });
    }

  }

  handleSelectGrade(event: React.FormEvent<HTMLInputElement>){

const target = event.currentTarget;
    const value = target.value;

    this.setState({showReport:false, 
      showReportButton:false, 
      showDiseableReportButton:false,
      showReportError:false,
      grado:value,
    });

    if(value === "-1"){
      this.setState({disabledArea:false});
      this.setState((prevState) => ({
        ...prevState,
        area: "-1",
        knowledgeAreas: [],
      }));
    }

    if(value !== "-1"){
      this.getAreas(value);
    }
  }

  getAreas(grades:any){
    GET_AXIOS(`users/knowledgeAreas/${grades}`)
      .then((res) => {
        this.setState((prevState) => ({
          ...prevState,
          area: "-1",
          knowledgeAreas: [],
        }));
        const _data: Array<object> = this.props.capitalize(
          res.data,
          "knowledge_area_name"
        );
        this.setState({ knowledgeAreas: _data, disabledArea:true });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  render() {
    let {
      institutions,
      grades,
      knowledgeAreas,
      disabledArea,
      disabledGrade,
    } = this.state;

    let title = "";

    switch (this.props.role) {
      case "sec_ed":
        title = "DELEGADO(A) DE LA SECRETARÍA DE EDUCACIÓN DEPARTAMENTAL";
        break;
      case "inter_aco":
        title = "INTEGRANTE DEL EQUIPO DE ACOMPAÑAMIENTO Y MONITOREO";
        break;
      case "dir_ge":
        title = "DIRECTOR(A) GENERAL";
        break;
      default:
        break;
    }
    return (
      <>
        <Row className="justify-content-center align-self-center text-center">
          <Row className="w-100  h-100 pt-5">
            <Col>
              <h2 className="title-report">ESTIMADO(A),</h2>
              <h3 className="title-report">{title}</h3>
            </Col>
          </Row>
          <Row className="justify-content-center align-self-center text-center w-100  h-100">
            <Col
              className="justify-content-center align-self-center text-center p-0"
              md={12}
            >
              <p>
                Para visualizar el estado y progreso de los Docentes, 
                en relación con el acceso y uso de los contenidos y
                recursos disponibles en la plataforma, seleccione la institución
                educativa, el grado y el área que desea consultar.
              </p>
            </Col>
          </Row>
          <Row className="w-100 mb-4">
            <Col className="d-flex justify-content-center">
              <div className="separator" />
            </Col>
          </Row>
          <Form className="container-fluid form-report-Infor" onSubmit={this.GenerateReport}>
            <Row className="justify-content-center align-self-center text-center">

              <Col
                className="justify-content-center align-self-center text-center"
                md={6}
              >
                <Form.Control
                  className="af-reportselect w-100 m-1"
                  name="institucion"
                  value={this.state.institucion}
                  defaultValue={"-1"}
                  onChange={this.handleSelectInsti}
                  as="select"
                  required
                >
                  <option value="-1">Escoge la institución educativa</option>
                  {institutions.map((inst: any, i: number) => (
                    <option value={inst.id} key={i}>
                      {inst.inst_name.toUpperCase()}
                    </option>
                  ))}
                </Form.Control>
              </Col>
              <Col
                className="justify-content-center align-self-center text-center"
                md={6}
              >
                <Form.Control
                  className="af-reportselect w-100 m-1"
                  name="grado"
                  value={this.state.grado}
                  defaultValue={"-1"}
                  onChange={this.handleSelectGrade}
                  as="select"
                  required
                  disabled={disabledGrade === false}
                >
                  <option value="-1">Escoge el grado</option>
                  {grades.map((grade: any, i: number) => (
                    <option value={grade.id} key={i}>
                      {grade.grade_name.toUpperCase()}
                    </option>
                  ))}
                </Form.Control>
              </Col>
        
              <Col
                className="justify-content-center align-self-center text-center"
                md={6}
              >
                <Form.Control
                  className="af-reportselect w-100 m-1"
                  name="area"
                  value={this.state.area}
                  defaultValue={"-1"}
                  onChange={this.handleInputChange}
                  as="select"
                  required
                  disabled={disabledArea === false}
                >
                  <option value="-1">Escoge el área</option>
                  {knowledgeAreas.map((ka: any, i: number) => (
                    <option value={ka.id} key={i}>
                      {ka.knowledge_area_name.toUpperCase()}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col className="d-flex align-items-center justify-content-center generate-report">
                {this.state.showReportButton &&
                  (this.state.showDiseableReportButton === true ? (
                    <Button
                      variant="primary"
                      type="submit"
                      className="generate-report-button d-flex justify-content-center align-items-center"
                      block
                    >
                      Generar Reporte
                      <img
                        src={IconButtonSend}
                        alt=""
                        className="ml-3 icon-generate-report"
                      />
                    </Button>
                  ) : (
                    <Button
                      variant="primary"
                      type="submit"
                      className="generate-report-button d-flex justify-content-center align-items-center"
                      block
                      disabled
                    >
                      Generar Reporte
                      <img
                        src={IconButtonSend}
                        alt=""
                        className="ml-3 icon-generate-report"
                      />
                    </Button>
                  ))}
              </Col>
            </Row>
          </Form>
          {this.state.showNotGroup && (
            <Container>
              <Row>
                <Col className="" md={12}>
                  <h4>No hay grupos disponibles en este momento.</h4>
                </Col>
              </Row>
            </Container>
          )}
          {this.state.showReport && (
          <LoadingPage>
            <Container fluid>
              <Row>
                <Col>
                  <Title>Informe</Title>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>
                    En el siguiente informe, usted encontrará el estado de
                    avance en relación con el acceso y uso de los contenidos y/o
                    recursos, según la institución educativa, el grado y el aréa 
                    consultados.
                  </p>
                </Col>
              </Row>
              <Row>
                {/* <Col md={6}>
                  <p className="text-center selected-type w-100 p-2">
                    Municipio:{" "}
                    {this.getValueFromArray(
                      this.state.towns,
                      this.state.municipio2,
                      "town_name"
                    )}
                  </p>
                </Col> */}
                <Col md={6}>
                  <p className="text-center selected-type w-100 p-2">
                    Institución:{" "}
                    {this.getValueFromArray(
                      this.state.institutions,
                      this.state.institucion2,
                      "inst_name"
                    )}
                  </p>
                </Col>
 
                <Col md={3}>
                  <p className="text-center selected-type w-100 p-2">
                    Grado:{" "}
                    {this.getValueFromArray(
                      this.state.grades,
                      this.state.grado2,
                      "grade_name"
                    )}
                  </p>
                </Col>
                <Col md={3}>
                  <p className="text-center selected-type w-100 p-2">
                    Área:{" "}
                    {this.getValueFromArray(
                      this.state.knowledgeAreas,
                      this.state.area2,
                      "knowledge_area_name"
                    )}
                  </p>
                </Col>
              </Row>
              {this.state.reports.filter((data: any) => data.id === 1)[0] &&
              <Row className="mt-4 mb-2">
                <Col className="d-flex subtitle-report">
                  <img src={IconVideo} alt="" className="icon-report" />
                  <h3>Videos:</h3>
                </Col>
              </Row>
              }
              {this.state.reports.filter((data: any) => data.id === 1)[0]
                ? this.state.reports
                    .filter((data: any) => data.id === 1)[0]
                    .totals.map((value: any) => {
                      return (
                        <DBA
                          atribute="videos"
                          dba={value.dba}
                          total={value.total}
                          student={value.users}
                          type={1}
                        ></DBA>
                      );
                    })
                : ""}
                {this.state.reports.filter((data: any) => data.id === 2)[0] &&
              <Row className="mt-4 mb-2">
                <Col className="d-flex subtitle-report">
                  <img src={IconInteractive} alt="" className="icon-report" />
                  <h3>Actividades interactivas:</h3>
                </Col>
              </Row>
              }
              {this.state.reports.filter((data: any) => data.id === 2)[0]
                ? this.state.reports
                    .filter((data: any) => data.id === 2)[0]
                    .totals.map((value: any) => {
                      return (
                        <DBA
                          atribute="actividades"
                          dba={value.dba}
                          total={value.total}
                          student={value.users}
                          type={1}
                        ></DBA>
                      );
                    })
                : ""}
                 {this.state.reports.filter((data: any) => data.id === 3)[0] &&
              <Row className="mt-4 mb-2">
                <Col className="d-flex subtitle-report">
                  <img src={IconMen} alt="" className="icon-report" />
                  <h3>Recursos MEN:</h3>
                </Col>
              </Row>
              }
              {this.state.reports.filter((data: any) => data.id === 3)[0]
                ? this.state.reports
                    .filter((data: any) => data.id === 3)[0]
                    .totals.map((value: any) => {
                      return (
                        <DBA
                          atribute="recursos"
                          dba={value.dba}
                          total={value.total}
                          student={value.users}
                          type={1}
                        ></DBA>
                      );
                    })
                : ""}
                {this.state.reports.filter((data: any) => data.id === 4)[0] &&
              <Row className="mt-4 mb-2">
                <Col className="d-flex subtitle-report">
                  <img src={IconVirtual} alt="" className="icon-report" />
                  <h3>Aula virtual:</h3>
                </Col>
              </Row>
              }
              {this.state.reports.filter((data: any) => data.id === 4)[0]
                ? this.state.reports
                    .filter((data: any) => data.id === 4)[0]
                    .totals.map((value: any) => {
                      return (
                        <DBA
                          atribute="contenidos"
                          dba={value.dba}
                          total={value.total}
                          student={value.users}
                          type={1}
                        ></DBA>
                      );
                    })
                : ""}
				{this.state.reports.filter((data: any) => data.id === 6)[0] &&
				  <Row className="mt-4 mb-2">
					<Col className="d-flex subtitle-report">
					  <img src={IconVirtual} alt="" className="icon-report" />
					  <h3>Modelos Test:</h3>
					</Col>
				  </Row>
				}
				{this.state.reports.filter((data: any) => data.id === 6)[0]
				  ? this.state.reports
					.filter((data: any) => data.id === 6)[0]
					.totals.map((value: any) => {
					  return (
						<DBA
						  atribute="contenidos"
						  dba={value.dba}
						  total={value.total}
						  student={value.users}
						  type={1}
						></DBA>
					  );
					})
				  : ""}

              {this.state.reports.filter((data: any) => data.id === 5)[0] &&
              <Row className="mt-4 mb-2">
                <Col className="d-flex subtitle-report">
                  <img src={IconMateEdu} alt="" className="icon-report" />
                  <h3>Materiales Educativos:</h3>
                </Col>
              </Row>
              }
              {this.state.reports.filter((data: any) => data.id === 5)[0]
                ? this.state.reports
                    .filter((data: any) => data.id === 5)[0]
                    .totals.map((value: any) => {
                      return (
                        <DBA
                          atribute="contenidos"
                          dba={value.dba}
                          total={value.total}
                          student={value.users}
                          type={1}
                        ></DBA>
                      );
                    })
                : ""}
              <Row className="my-4">
                <Col md={12}>
                  <Button
                    variant="primary"
                    type="button"
                    className="Butoon-generate-report"
                    block
                    onClick={() => this.generatePDF()}
                  >
                    Para generar el reporte detallado por docente haz clic
                    aquí.
                  </Button>
                </Col>
              </Row>
            </Container>
            </LoadingPage>
          )}
          {this.state.showReportError && (
            <Container className="mt-4" fluid>
              <Row>
                <Col className="" md={12}>
                  <h4>No hay reporte disponibles en este momento.</h4>
                </Col>
              </Row>
            </Container>
          )}
        </Row>
      </>
    );
  }
}
