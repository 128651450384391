import React, { useState, useEffect } from "react";
import "./footPage.scss";

import { Row } from "react-bootstrap";

function FootPages() {
  const [dateFoot, setDateFoot] = useState(Date);

  useEffect(() => {
    getDateFooter();
  });

  const getDateFooter = async () => {
    // let date= new Date();
    // setDateFoot(date);

    const date = new Date();
    var options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    let dateFoot = new Intl.DateTimeFormat("es", options).format(date);
    setDateFoot(dateFoot);
  };
  return (
    <div className="af-footer">
      <Row className="w-100 text-center justify-content-center align-self-center text-center af-rowFooter">
        <p className="w-75 p-md-3 text-white">
          <small>
            <a
              className="af-aPolityFooter"
              href="https://mefeivalle.universoeducaticvo.com/storage/policy.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Políticas de seguridad de la información y Política de tratamiento
              de Datos personales
            </a>
            <br /> Última fecha de actualización: viernes 29 de Mayo de 2020
            09:00 am <br /> {dateFoot}
            <br /> Todos los Derechos Reservados © 2020
          </small>
        </p>
      </Row>
    </div>
  );
}

export default FootPages;
