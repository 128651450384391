import React from "react";

//impport elementos de react
import { Container, Row, Col } from "react-bootstrap";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";

// Radial separators
import RadialSeparators from "./RadilSeparator";

import "./DBAReport.scss";
import "react-circular-progressbar/dist/styles.css";

interface Props {
  dba: string;
  atribute: string;
  total: number;
  student: number;
  type: number;
}

export default function DBAReport(props: Props) {
  let type: string = "";
  let type2: any = <p></p>;

  // Handling the text description for each report type
  switch (props.atribute) {
    case "videos":
      type2 = (
        <p>
          <b>{props.student}</b> videos visualizados de <b>{props.total}</b> videos.
        </p>
      );
      break;
    case "actividades":
      type2 = (
        <p>
          <b>{props.student}</b> actividades interactivas realizadas de <b>{props.total}</b> actividades interactivas.
        </p>
      );
      break;
    case "recursos":
      type2 = (
        <p>
          <b>{props.student}</b> recursos MEN visualizados de <b>{props.total}</b> recursos MEN.
        </p>
      );
      break;
    case "contenidos":
      type2 = (
        <p>
          <b>{props.student}</b> contenidos realizados de <b>{props.total}</b> contenidos.
        </p>
      );
      break;
    case "docente":
      type2 = (
        <p>
          <b>{props.student}</b> guías docentes visualizadas de <b>{props.total}</b> guías docentes.
        </p>
      );
      break;
    case "imprimibles":
      type2 = (
        <p>
          <b>{props.student}</b> actividades imprimibles visualizadas de <b>{props.total}</b> actividades imprimibles.
        </p>
      );
      break;
    default:
      type2 = "";
      break;
  }

  // Defining type for progress description (e.g., videos vs las actividades)
  if (
    props.atribute === "videos" ||
    props.atribute === "recursos" ||
    props.atribute === "contenidos"
  ) {
    type = `los ${props.atribute}`;
  } else {
    type = `las ${props.atribute}`;
  }

  // Calculating the correct progress percentage
  const value = Math.round((props.student / props.total) * 100);

  // Render the component, ensuring it shows all resources even if student progress is 0
  return (
    <Container className="mt-3">
      <Row>
        <Col>
          <p className="text-justify">
            <b>DBA:</b> {props.dba}
          </p>
        </Col>
      </Row>
      <Row>
        <Col
          className={`af-Progreesbar-${props.atribute} p-2`}
          xs={4}
          sm={4}
          md={2}
          lg={1}
          xl={2}
        >
          <CircularProgressbarWithChildren
            value={value}
            text={`${value}%`}
            strokeWidth={10}
            styles={buildStyles({
              strokeLinecap: "butt",
            })}
          >
            <RadialSeparators
              count={12}
              style={{
                background: "#fff",
                width: "2px",
                // This needs to be equal to props.strokeWidth
                height: `${10}%`,
              }}
            />
          </CircularProgressbarWithChildren>
        </Col>
        <Col
          className="align-middle text-justify mt-4"
          xs={12}
          sm={12}
          md={9}
          lg={11}
          xl={10}
        >
          {type2}
        </Col>
      </Row>
    </Container>
  );
}
