import React, { useState, useEffect, useRef } from "react";

//import componentes y vistas
import ModalGeneral from "../modalGeneral/modalGeneral";

//import elmentos de react
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { Form, Row, Col, Button } from "react-bootstrap";

// import axios from 'axios';

//import imagenes
import "./FormSelfManagement.scss";
import { GET_AXIOS, POST_AXIOS } from "../funtionsREST";
import Pdf from "react-to-pdf";

export interface Props {
  //   history: any;
  //   rol: any;
  refToPrint: React.RefObject<HTMLDivElement>;
}

export default function FormSelfManagement(props: Props) {
  const initialFetchedDataState = {
    serial_code: "",
    status: 0,
    town_id: "",
    institution_id: "",
    headquarter_id: "",
    teacher_national_id: "",
    role_id: "",
    teacher_name: "",
    grade_id: "",
    knowledge_area_id: "",
    accompaniment_type_id: "",
    accompaniment_description: "",
    accompaniment_approach: "",
    achievements: "",
    improvement_opportunities: "",
  };
  //  variable to validate form
  const [validated, setValidated] = useState(false);
  const [towns, setTowns] = useState([]);
  const [institutions, setInstitutions] = useState([]);
  const [sede, setSedes] = useState([]);
  const [roles, setRoles] = useState([]);
  const [role, setRole] = useState("");
  const [grades, setGrades] = useState([]);
  const [grade, setGrade] = useState("");
  const [areas, setAreas] = useState([]);
  const [code, setCode] = useState("");
  const [accompanimentType, setAccompanimentType] = useState([]);

  const [startDate, setStartDate] = useState(new Date());
  const [buttonState, setbuttonState] = useState(0);

  // datos para modal
  const [show, setShow] = useState(false);
  const [dataModal, setDataModal] = useState({
    tittleModal: "",
    textModal: "",
  });

  //FormData
  const [fetchedData, setfetchedData] = useState(initialFetchedDataState);
  const serialCodeRef = useRef("");
  let serialCode = "";

  //fix
  const [currentTown, setCurrentTown] = useState([]);
  const [institution, setInstitution] = useState([]);
  const [acType, setAcType] = useState([]);


  useEffect(() => {
    serialCodeRef.current = serialCode;
    setCode(serialCode);

  }, [serialCode]);
  useEffect(() => {
    GET_AXIOS("selfManagements/roles")
      .then(res => {
        if (res.data) setRoles(capitalizeWords(res.data, "name"));
      })
      .catch(e => {
        console.log("Error getting roles: " + e);
      })
    GET_AXIOS("grades")
      .then((res) => {
        if (res.data) setGrades(capitalizeWords(res.data, "grade_name"));
      })
      .catch((e) => {
        console.log("Error getting phone types: " + e);
      });
    // );
    GET_AXIOS("knowledgeAreas")
      .then((res) => {
        if (res.data)
          setAreas(capitalizeWords(res.data, "knowledge_area_name"));
      })
      .catch((e) => {
        console.log("Error getting phone types: " + e);
      });

    GET_AXIOS("towns/byDepartment/1")
      .then((res) => {
        if (res.data) setTowns(capitalizeWords(res.data, "town_name"));
      })
      .catch((e) => {
        console.log("Error getting phone types: " + e);
      });

    GET_AXIOS("accompanimentTypes")
      .then(res => {
        if (res.data) setAccompanimentType(capitalizeWords(res.data, "name"));
      })
      .catch(e => {
        console.log("Error getting accompaniment types: " + e);
      });
  }, []);


  const capitalizeWords = (
    arr: Array<any>,
    keyInObj: String,
    all: boolean = false
  ) => {
    const toReturn: any = [];
    arr.map((el: any) => {
      let word: string = "";
      el[`${keyInObj}`].split(" ").map((wr: any, i: number) => {
        if (!all) {
          word += i === 0 ? wr[0].toUpperCase() + wr.slice(1) + " " : wr + " ";
        } else {
          word += wr[0].toUpperCase() + wr.slice(1) + " ";
        }
      });
      word = word.trim();
      toReturn.push({ id: el.id, [`${keyInObj}`]: word });
    });
    return toReturn;
  };

  const handleClose = () => {
    setShow(false);

    //     setfetchedData({...initialFetchedDataState});
    //     setValidated(false);
    //     const initial = document.getElementsByTagName("INPUT")

    //     forEach(val =>{
    //       val.value = "";
    //     });
    //     setTowns([])
    // setInstitutions([])
    // setSedes([])
    // setGrades([])
    // setAreas([])
    // setCode("")
    // setAccompanimentType([])
    // setbuttonState(0);
    //     console.log(fetchedData);

  }; //    Metodo para cerrar modal

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const form = event.currentTarget;
    const elements = form.elements;
    console.log(buttonState);
    console.log(code);


    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      //------------------------
      console.log("entre al if");
    } else {
      GET_AXIOS("users/opCoords").then((res) => { });
      let jsonUniqueCode = {
        accompaniment_type: elements.Types_id.value,
        serial: null,
      };

      if (elements.code.value) {
        const jsonToPost = {
          serial_code: elements.code.value,
          status: elements.state.value,
          town_id: elements.town.value,
          institution_id: elements.institution.value,
          // headquarter_id: elements.town_id.value,
          teacher_national_id: elements.doc_teacher.value,
          teacher_name: elements.nombre.value.toUpperCase(),
          role_id: role,
          grade_id: grade,
          // knowledge_area_id: elements.knowledge_area_id.value,
          accompaniment_type_id: elements.Types_id.value,
          accompaniment_description: elements.desc.value,
          accompaniment_approach: elements.need.value,
          achievements: elements.achievements.value,
          improvement_opportunities: elements.improvement.value,
        };
        console.log("handleSubmit -> form", jsonToPost);
        const dataToPost = { ...fetchedData };
        dataToPost.serial_code = elements.code.value;
        setfetchedData(dataToPost);
        postSelfManagement(jsonToPost);
        if (buttonState == 3) {
          console.log("entre");
          console.log("entre al if");
          window.print();
        }
      } else {

        const res = await POST_AXIOS("selfManagement/generateUniqueCode", jsonUniqueCode);
        console.log(res);
        if (res.data) {
          const dataToPost = { ...fetchedData };
          dataToPost.serial_code = res.data.serial_code;
          await setfetchedData(dataToPost);
          console.log(dataToPost.serial_code);
          serialCode = res.data.serial_code;
          setCode(res.data.serial_code);
          // sendData(1);
          const jsonToPost = {
            serial_code: res.data.serial_code,
            status: elements.state.value,
            town_id: elements.town.value,
            institution_id: elements.institution.value,
            teacher_national_id: elements.doc_teacher.value,
            teacher_name: elements.nombre.value.toUpperCase(),
            role_id: role,
            grade_id: grade,
            accompaniment_type_id: elements.Types_id.value,
            accompaniment_description: elements.desc.value,
            accompaniment_approach: elements.need.value,
            achievements: elements.achievements.value,
            improvement_opportunities: elements.improvement.value,
          };
          console.log("handleSubmit -> form", jsonToPost);

          postSelfManagement(jsonToPost);
          if (buttonState == 3) {
            console.log("entre");
            console.log("entre al if");
            window.print();
          }
        } else {
        }

        // POST_AXIOS("selfManagement/generateUniqueCode", jsonUniqueCode).then(
        //   (res) => {
        //     console.log(res);
        //     if (res) {
        //       const dataToPost = { ...fetchedData };
        //       dataToPost.serial_code = res.data.serial_code;
        //       setfetchedData(dataToPost);
        //       console.log(dataToPost.serial_code);
        //       // sendData(1);
        //       const jsonToPost = {
        //         serial_code: res.data.serial_code,
        //         status: elements.state.value,
        //         town_id: elements.town.value,
        //         institution_id: elements.institution.value,
        //         teacher_national_id: elements.doc_teacher.value,
        //         teacher_name: elements.nombre.value.toUpperCase(),
        //         accompaniment_type_id: elements.Types_id.value,
        //         accompaniment_description: elements.desc.value,
        //         accompaniment_approach: elements.need.value,
        //         achievements: elements.achievements.value,
        //         improvement_opportunities: elements.improvement.value,
        //       };
        //       console.log("handleSubmit -> form", jsonToPost);

        //       postSelfManagement(jsonToPost);
        //       if(buttonState == 3){
        //         console.log("entre");
        //         console.log("entre al if");
        //         window.print();
        //       }
        //     } else {
        //     }
        //   }
        // );
      }
    }

    setValidated(true);
  };

  const postSelfManagement = (jsonToPost: any) => {
    console.log(jsonToPost);

    POST_AXIOS("selfManagement/new", jsonToPost)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          setShow(true);
          setDataModal({
            tittleModal: "Exitoso",
            textModal: `Registro guardado exitosamente, el codigo de su registro es ${jsonToPost.serial_code}`,
          });
        }
      })
      .catch((e) => {
        setShow(true);
        setDataModal({
          tittleModal: "Lo sentimos",
          textModal:
            "Algo ha salido mal, por favor vuelve a intentarlo o comuniquese con soporte técnico.",
        });
      });
  };

  const handleChange = (event: any) => {
    // console.log("handleChange -> event", event.target.value, event.target.id);
  };

  const enableButtons = (event: any) => {
    setbuttonState(event.target.value);
  };

  const handleCodeChange = async (event: any) => {
    const code = event.target.value;
    setCode(code);
    if (code.length >= 5) {
      const res = await GET_AXIOS("selfManagement/getCurrentInfo/" + code);
      console.log(res.data);
      if (res.data) {
        await setfetchedData(res.data);
        setRole(res.data.role_id)
        setGrade(res.data.grade_id)
        setAcType(res.data.accompaniment_type_id)
        setCurrentTown(res.data.town_id)
        const institutionRes = await GET_AXIOS(
          `institutions/byTown/${res.data.town_id}`
        );
        setInstitution(res.data.institution_id)
        const headquartersRes = await GET_AXIOS(
          "headquarters/byInstId/" + res.data.institution_id
        );
        console.log(institutionRes.data);
        if (institutionRes.data) {
          setInstitutions(capitalizeWords(institutionRes.data, "inst_name"));
        }
        if (headquartersRes.data) {
          await setSedes(capitalizeWords(headquartersRes.data, "hq_name"));
          setfetchedData(res.data);
        }
      } else {
        await setfetchedData(initialFetchedDataState);
        await setSedes([]);
        setCurrentTown([])
        setInstitution([])
        setRole("")
        setGrade("")
        setAcType([])

        setShow(true);
        setDataModal({
          tittleModal: "Lo sentimos",
          textModal:
            "El registro no aparece en nuestra base de datos, puede que ya se haya enviado al Coordinador Operativo, por favor comuniquese con soporte tecnico.",
        });
      }
    }
  };

  const handleTownChange = (event: any) => {
    const hq = event.target.value;
    const tmpFetchedData = { ...fetchedData };
    tmpFetchedData.town_id = hq;
    setCurrentTown(hq)
    setfetchedData(tmpFetchedData);

    let value = event.target && event.target.value;
    console.log(value);

    GET_AXIOS(`institutions/byTown/${value}`)
      .then((res) => {
        if (res.data)
          setInstitutions(capitalizeWords(res.data, "inst_name", true));
      })
      .catch((e) => {
        console.log("Error getting institutions: " + e);
      });
  };

  const handleInstitutionChange = (event: any) => {
    let value = event.target && event.target.value;
    const hq = event.target.value;
    const tmpFetchedData = { ...fetchedData };
    tmpFetchedData.institution_id = hq;
    setInstitution(hq)
    setfetchedData(tmpFetchedData);
    console.log(value);

    GET_AXIOS(`headquarters/byInstId/${value}`)
      .then((res) => {
        console.log(res);
        if (res.data) setSedes(capitalizeWords(res.data, "hq_name", true));
      })
      .catch((e) => {
        console.log("Error getting institutions: " + e);
      });
  };

  const handleHeadquarterChange = (event: any) => {
    const hq = event.target.value;
    const tmpFetchedData = { ...fetchedData };
    tmpFetchedData.headquarter_id = hq;
    setfetchedData(tmpFetchedData);
  };

  const handleAreaChange = (event: any) => {
    const hq = event.target.value;
    const tmpFetchedData = { ...fetchedData };
    tmpFetchedData.knowledge_area_id = hq;
    setfetchedData(tmpFetchedData);
  };

  const handleRoleChange = (event: any) => {
    const role = event.target.value;
    const tmpFetchedData = { ...fetchedData };
    tmpFetchedData.role_id = role;
    setRole(role);
    setfetchedData(tmpFetchedData);
  }

  const handleAccompanimentChange = (event: any) => {
    const hq = event.target.value;
    const tmpFetchedData = { ...fetchedData };
    tmpFetchedData.accompaniment_type_id = hq;
    setAcType(hq)
    setfetchedData(tmpFetchedData);
  };

  const handleGradeChange = (event: any) => {
    const grade = event.target.value;
    const tmpFetchedData = { ...fetchedData };
    tmpFetchedData.grade_id = grade;
    setGrade(grade);
    setfetchedData(tmpFetchedData);
  };

  const SendNprint = (printF: any) => {
    console.log(validated);
    if (validated === true) {
      console.log("entre al if");
      window.print();
    }
    setValidated(true);
  };

  return (
    <Row className="justify-content-center align-self-center text-center">
      <Col md={8}>
        <Form
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
          className="my-4 af-formSelfManagement"
        >
          <Row className="mb-2">
            {/* Date */}
            <Col
              md={4}
              className="ml-auto d-md-flex justify-content-end pr-md-1 mb-2 mb-md-0"
            >
              <div className="d-flex flex-column align-items-start">
                <b>Fecha y Hora</b>
                <DatePicker
                  selected={startDate}
                  // onChange={(e: any) => handleChange(e)}
                  onChange={(date: any) => handleChange(date)}
                  showTimeSelect
                  timeFormat="HH:mm"
                  id="datetime"
                  timeIntervals={15}
                  className="af-inputs px-3"
                  timeCaption="time"
                  dateFormat="yyyy/MM/dd h:mm aa"
                  disabled={true}
                />
              </div>
            </Col>
            <Col
              md={4}
              className="mr-auto d-flex justify-content-center justify-content-md-start pl-md-1 flex-column align-items-start"
            >
              {/* Code */}
              <b>Código</b>
              <Form.Control
                id="code"
                className="af-inputs"
                placeholder="Código"
                type="text"
                onChange={(e: any) => handleCodeChange(e)}
                style={{ width: "214px" }}
                value={code}
                // defaultValue={serialCode || ""}
                maxLength={5}
              />
            </Col>
          </Row>

          <Row className="mb-2">
            <Col md={6} className="pr-md-1 mb-2 mb-md-0 text-left">
              {/* Municipio */}
              <b>Municipio</b>
              <Form.Control
                custom
                id="town"
                className="af-inputs af-select"
                onChange={(e: any) => handleTownChange(e)}
                required
                as="select"
                // value={fetch
                value={currentTown}
              //defaultValue={fetchedData.town_id || ""}
              >
                <option value="" disabled>
                  Seleccione un Municipio
                </option>
                {towns.map((rq: any, i: number) => (
                  <option value={rq.id} key={i}>
                    {rq.town_name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Debe seleccionar un campo
              </Form.Control.Feedback>
            </Col>
            <Col md={6} className="pl-md-1 text-left">
              {/* Institución */}
              <b>Institución</b>
              <Form.Control
                custom
                id="institution"
                className="af-inputs af-select"
                onChange={(e: any) => handleInstitutionChange(e)}
                required
                as="select"
                value={institution}
              //defaultValue={fetchedData.institution_id || ""}
              >
                <option value="" disabled>
                  Seleccione una Institución
                </option>
                {institutions.map((rq: any, i: number) => (
                  <option value={rq.id} key={i}>
                    {rq.inst_name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Debe seleccionar un campo
              </Form.Control.Feedback>
            </Col>
          </Row>

          <Row className="mb-2">
            <Col md={6} className="pr-md-1 mb-2 mb-md-0 text-left">
              {/* Sede */}
              {/* <b>Sede</b>
              <Form.Control
                custom
                id="town_id"
                className="af-inputs af-select"
                onChange={(e: any) => handleHeadquarterChange(e)}
                required
                as="select"
                value={fetchedData.headquarter_id}
                defaultValue={fetchedData.headquarter_id || ""}
              >
                <option value="" disabled>
                  Seleccione una Sede
                </option>
                {sede.map((rq: any, i: number) => (
                  <option value={rq.id} key={i}>
                    {rq.hq_name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Debe seleccionar un campo
              </Form.Control.Feedback> */}
              {/* Nombre */}
              <b>Nombre completo</b>
              <Form.Control
                id="nombre"
                className="af-inputs af-mayusName"
                placeholder="Nombre completo"
                type="text"
                required
                defaultValue={fetchedData.teacher_name || ""}
              />
              <Form.Control.Feedback type="invalid">
                Debe llenar este campo
              </Form.Control.Feedback>
            </Col>
            <Col md={6} className="pl-md-1 text-left">
              <b>Documento de identidad</b>
              <Form.Control
                id="doc_teacher"
                className="af-inputs"
                placeholder="Documento de identidad"
                type="text"
                required
                defaultValue={fetchedData.teacher_national_id || ""}
              />
              <Form.Control.Feedback type="invalid">
                Debe llenar este campo
              </Form.Control.Feedback>
            </Col>
          </Row>
          {/* Grupo

          {/* Solución de Inquietud */}
          {/* <Row className="mb-2">
            
          </Row> */}
          {/* <Row className="mb-2">
            {/* Ciclo *

            {/* Teléfono *
            <Col md={6} className="pr-md-1 mb-2 mb-md-0 text-left">
              <b>Ciclo</b>
              <Form.Control
                custom
                id="grade_id"
                className="af-inputs af-select"
                onChange={(e: any) => handleGradeChange(e)}
                required
                as="select"
                value={fetchedData.grade_id}
                defaultValue={fetchedData.grade_id || ""}
              >
                <option value="" disabled>
                  Seleccione un Ciclo
                </option>
                {grades.map((rq: any, i: number) => (
                  <option value={rq.id} key={i}>
                    {rq.grade_name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Debe seleccionar un campo
              </Form.Control.Feedback>
            </Col>
            <Col md={6} className="pl-md-1 text-left">
              <b>Área</b>
              <Form.Control
                custom
                id="knowledge_area_id"
                className="af-inputs af-select"
                onChange={(e: any) => handleAreaChange(e)}
                required
                as="select"
                value={fetchedData.knowledge_area_id}
                defaultValue={fetchedData.knowledge_area_id || ""}
              >
                <option value="" disabled>
                  Seleccione el área
                </option>
                {areas.map((rq: any, i: number) => (
                  <option value={rq.id} key={rq.knowledge_area_name}>
                    {rq.knowledge_area_name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Debe seleccionar un campo
              </Form.Control.Feedback>
            </Col>
          </Row> */}
          <Row className="mb-2">
            <Col md={6} className="pr-md-1 mb-2 mb-md-0 text-left">
              {/* Rol */}
              <b>Rol</b>
              <Form.Control
                custom
                id="role"
                className="af-inputs af-select"
                onChange={(e: any) => handleRoleChange(e)}
                required
                as="select"
                // value={fetch
                value={role}
              //defaultValue={fetchedData.town_id || ""}
              >
                <option value="" disabled>
                  Seleccione un rol
                </option>
                {roles.map((rl: any, i: number) => (
                  <option value={rl.id} key={i}>
                    {rl.name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Debe seleccionar un campo
              </Form.Control.Feedback>
            </Col>
            <Col md={6} className="pl-md-1 text-left">
              <b>Tipo de acompañamiento</b>
              <Form.Control
                custom
                id="Types_id"
                className="af-inputs af-select af-12cols"
                onChange={(e: any) => handleAccompanimentChange(e)}
                required
                as="select"
                value={acType}
              //defaultValue={fetchedData.accompaniment_type_id || ""}
              >
                <option value="" disabled>
                  Tipo de acompañamiento
                </option>
                {accompanimentType.map((rq: any, i: number) => (
                  <option value={rq.id} key={rq.name}>
                    {rq.name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Debe seleccionar un campo
              </Form.Control.Feedback>
            </Col>
          </Row>
          {role == "1" &&
            <Row className="mb-2">
              {/* Ciclo */}
              <Col md={6} className="pr-md-1 mb-2 mb-md-0 text-left">
                <b>Ciclo</b>
                <Form.Control
                  custom
                  id="grade_id"
                  className="af-inputs af-select"
                  onChange={(e: any) => handleGradeChange(e)}
                  required
                  as="select"
                  value={grade}
                >
                  <option value="" disabled>
                    Seleccione un Ciclo
                  </option>
                  {grades.map((rq: any, i: number) => (
                    <option value={rq.id} key={i}>
                      {rq.grade_name}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  Debe seleccionar un campo
                </Form.Control.Feedback>
              </Col>
            </Row>
          }
          <Form.Group className="text-left">
            <b>Descripción de la necesidad de acompañamiento</b>

            <Form.Control
              id="desc"
              className="af-inputs py-3 my-2"
              placeholder="Descripción de la necesidad de acompañamiento"
              onChange={(e: any) => handleChange(e)}
              required
              as="textarea"
              rows={3}
              defaultValue={fetchedData.accompaniment_description || ""}
            />
            <Form.Control.Feedback type="invalid">
              Debe llenar este campo
            </Form.Control.Feedback>
            <b>
              Abordaje de la necesidad de acompañamiento (Incluir fechas y
              acciones realizadas)
            </b>
            <Form.Control
              id="need"
              className="af-inputs py-3 my-2"
              placeholder="Abordaje de la necesidad de acompañamiento (Incluir fechas y acciones realizadas)"
              onChange={(e: any) => handleChange(e)}
              required
              as="textarea"
              rows={3}
              defaultValue={fetchedData.accompaniment_approach || ""}
            />
            <Form.Control.Feedback type="invalid">
              Debe llenar este campo
            </Form.Control.Feedback>
            <b>Logros</b>
            <Form.Control
              id="achievements"
              className="af-inputs py-3 my-2"
              placeholder="Logros"
              onChange={(e: any) => handleChange(e)}
              required
              as="textarea"
              rows={3}
              defaultValue={fetchedData.achievements || ""}
            />
            <Form.Control.Feedback type="invalid">
              Debe llenar este campo
            </Form.Control.Feedback>
            <b>Oportunidades de mejora</b>
            <Form.Control
              id="improvement"
              className="af-inputs py-3 my-2"
              placeholder="Oportunidades de mejora"
              onChange={(e: any) => handleChange(e)}
              required
              as="textarea"
              rows={3}
              defaultValue={fetchedData.improvement_opportunities || ""}
            />
            <Form.Control.Feedback type="invalid">
              Debe llenar este campo
            </Form.Control.Feedback>
          </Form.Group>

          <Row>
            <Col md={6}>
              <Form.Control
                custom
                id="state"
                className="af-inputs af-select"
                onChange={(e: any) => enableButtons(e)}
                required
                as="select"
                defaultValue=""
              >
                <option value="" disabled>
                  Seleccione un Estado
                </option>
                <option value="2">En trámite</option>
                <option value="3">Finalizado</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Debe seleccionar un campo
              </Form.Control.Feedback>
            </Col>
          </Row>

          <Form.Row className="right-0 justify-content-center align-self-center text-center mt-4">
            <Button
              className="af-buttonCcenterG mx-2"
              type="submit"
              style={{ backgroundColor: "#2665B4" }}
              // eslint-disable-next-line eqeqeq
              disabled={buttonState != 2}
            >
              <h4 className="m-0">Guardar</h4>
            </Button>

            {/*
                 // @ts-ignore */}
            <Pdf targetRef={props.refToPrint} filename="div-blue.pdf">
              {/*
                 // @ts-ignore */}
              {({ toPdf }) => (
                <Button
                  className="af-buttonCcenter mx-2"
                  type="submit"
                  // onClick={() => SendNprint(toPdf)}
                  // eslint-disable-next-line eqeqeq
                  disabled={buttonState != 3}
                >
                  <h4 className="m-0"> Enviar y generar informe</h4>
                </Button>
              )}
            </Pdf>
          </Form.Row>
        </Form>
      </Col>
      {show && (
        <ModalGeneral
          show={show}
          tittle={dataModal.tittleModal}
          text={dataModal.textModal}
          handleClose={handleClose}
          acceptModal={handleClose}
        />
      )}
    </Row>
  );
}
