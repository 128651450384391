import React from "react";

//import componentes y vistas
import FormCallCenter from "../../components/formCallCenter/FormCallCenter";

//import elmentos de react
import { Row, Col } from "react-bootstrap";

// import axios from 'axios';

//import imagenes

export interface Props {
  //   history: any;
  rol: any;
}

export default function CallCenter() {
  return (
    <div className="container">
      <Row>
        {/* <Col
          className="justify-content-center align-self-center text-center py-3"
          md={3}
        >
          <div className="af-lineOrange"></div>
        </Col> */}
        <Col
          className="justify-content-center align-self-center text-center pt-5"
          md={12}
        >
          <h2 className="af-titleOrange">
            <strong>
              BIENVENIDO(A), GESTOR TIC:
            </strong>
          </h2>
        </Col>
        {/* <Col
          className="justify-content-center align-self-center text-center py-3"
          md={3}
        >
          <div className="af-lineOrange"></div>
        </Col> */}
      </Row>
      <Row>
        <p>
          A continuación, tendrás acceso al formato para el registro de las
          interacciones generadas por estudiantes, docentes, directivos
          docentes, delegados de la Secretaría de Educación Departamental y los
          integrantes del equipo de Gestores TIC, en
          el marco de la atención educativa a los docentes responsables del
          proceso formativo y los estudiantes jóvenes extraedad y adultos
          beneficiarios de la implementación del Modelo Educativo Flexible
          Talentum, en el Valle del Cauca.
        </p>
      </Row>
      <FormCallCenter />
    </div>
  );
}
