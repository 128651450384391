import React from "react";
import "./report.scss";

//impport elementos de react
import { Container, Image } from "react-bootstrap";
// import { FiPrinter } from "react-icons/fi";
import { Link } from "react-router-dom";
import { TiHomeOutline } from "react-icons/ti";

//import comoponentes y vistas
import decorationBacgroundUpper from "../../img/BackgroundUpperReports.png";
import decorationBacgroundDown from "../../img/decorationDownContent.png";
import LapizReport from "../../img/LapizReport.png";
import ReportEstrellasDerecha from "../../img/ReportEstrellasDerecha.png";
import ReportEstrellasIzquierda from "../../img/ReportEstrellasIzquierda.png";
import ReportAll from "./reports/reportAll";
import ReportDoc from "./reports/reportDoc";
import ReportRect from "./reports/reportRect";
import ReportIntea from "./reports/reportIntea";

// import ReportStudent from "./reports/reportStudent";
import Footer from "../footPage/footPage";
// import LoadingPage from "../spinner/loadingPage";

export interface Props {
  rol: string;
  graphics: Array<string>;
}

export default class Report extends React.Component<Props, { component: any }> {
  constructor(props: any) {
    super(props);
    this.state = {
      component: (
        <ReportAll
          role={this.props.rol}
          graphics={this.props.graphics}
          capitalize={this.capitalizeWords}
        />
      ),
    };
  }

  componentDidMount() {
    this.callComponent();
  }

  capitalizeWords = (arr: Array<any>, keyInObj: String) => {
    const toReturn: any = [];
    arr.map((el: any) => {
      let word: string = "";
      el[`${keyInObj}`]
        .trim()
        .split(" ")
        .map((wr: any) => {
          word += wr[0].toUpperCase() + wr.slice(1) + " ";
        });
      word = word.trim();
      toReturn.push({ id: el.id, [`${keyInObj}`]: word });
    });
    return toReturn;
  };

  callComponent() {
    console.log(this.props.rol);
    let newComponent;
    switch (this.props.rol) {
      case "doce":
        newComponent = (
          <ReportDoc
            graphics={this.props.graphics}
            capitalize={this.capitalizeWords}
          />
        );
        break;
      case "rec":
        newComponent = (
          <ReportRect
            graphics={this.props.graphics}
            capitalize={this.capitalizeWords}
          />
        );
        break;
      case "sec_ed":
        newComponent = (
          <ReportAll
            role="sec_ed"
            graphics={this.props.graphics}
            capitalize={this.capitalizeWords}
          />
        );
        break;
      case "inter_aco":
        newComponent = (
          <ReportIntea
            role="inter_aco"
            graphics={this.props.graphics}
            capitalize={this.capitalizeWords}
          />
        );
        break;
      case "dir_ge":
        newComponent = (
          <ReportAll
            role="dir_ge"
            graphics={this.props.graphics}
            capitalize={this.capitalizeWords}
          />
        );
        break;
      case "gagem":
        newComponent = (
          <ReportAll
            role="gagem"
            graphics={this.props.graphics}
            capitalize={this.capitalizeWords}
          />
        );
        break;
      case "coor_op":
        newComponent = (
          <ReportAll
            role="coor_op"
            graphics={this.props.graphics}
            capitalize={this.capitalizeWords}
          />
        );
        break;
      case "coor_m":
        newComponent = (
          <ReportAll
            role="coor_m"
            graphics={this.props.graphics}
            capitalize={this.capitalizeWords}
          />
        );
        break;
      case "pta":
        newComponent = (
          <ReportRect
            graphics={this.props.graphics}
            capitalize={this.capitalizeWords}
          />
        );
        break;

      default:
        newComponent = (
          <ReportDoc
            graphics={this.props.graphics}
            capitalize={this.capitalizeWords}
          />
        );
    }
    this.setState({ component: newComponent });
  }

  render() {
    return (
      <div className="report-container">
        <Image className="af-backImageUpperReport" src={decorationBacgroundUpper} />

        <div className="home-button-container">
          <Link
            className="af-buttonHomeIntroduction d-flex justify-content-center p-2"
            to="/chooseContent"
          >
            <TiHomeOutline />
          </Link>
        </div>

        <Container className="content-container mb-5 principal-page">
          {this.state.component}
        </Container>

        <div className="decorative-images" style={{ marginTop: "-100px" }}>
          <Image className="lapiz-report" src={LapizReport} />
          <Image className="report-estrellas-izquierda" src={ReportEstrellasIzquierda} />
          <Image className="report-estrellas-derecha" src={ReportEstrellasDerecha} />
        </div>

        <Image className="af-backImageDown" src={decorationBacgroundDown} />
        <Footer></Footer>
      </div>
    );
  }
}
