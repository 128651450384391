import React, { useState, useEffect } from "react";
// import './callCenterReport.scss'
import { Row, Col } from "react-bootstrap";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, selectFilter, Comparator } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import ToolkitProvider, { ColumnToggle } from 'react-bootstrap-table2-toolkit';
import { TiHomeOutline } from "react-icons/ti";
import { Link } from "react-router-dom";
import { POST_AXIOS, GET_AXIOS} from "../../components/funtionsREST";

export default function CoordinadorReport() {
    const { ToggleList } = ColumnToggle;
    const [inqOptions, setInqOptions]:any = useState([
        {value: 1, label: "TÉCNICO"},
        {value: 2, label: "CONTENIDO DE LA PLATAFORMA"},
        {value: 3, label: "INCONFORMIDAD GENERAL"},
        {value: 4, label: "GENERALIDADES DEL PROYECTO"}
    ]);
    const [roleOptions, setRoleOptions]:any = useState([
        {value: 1, label: "ESTUDIANTE"},
        {value: 2, label: "ACUDIENTE"},
        {value: 3, label: "DOCENTE"},
        {value: 4, label: "RECTOR"},
        {value: 5, label: "SECRETARÍA DE EDUCACIÓN"},
        {value: 6, label: "ADMINISTRADOR"},
        {value: 7, label: "CALL CENTER"}
    ]);
    const [townOptions, setTownOptions]:any = useState([
        {value: 0, label: 'CALI'},
    ]);
    const [allInstitutions, setAllInstitutions]: any = useState([
        {value: 0, label: 'IE ARTURO GOMEZ JARAMILLO'},
        {value: 1, label: 'IE SAN JOSE'},
        {value: 3, label: 'IE AGRICOLA CAMPOALEGRE'}
    ])
    const [institutionOptions, setInstitutionOptions]:any = useState([]);
    const [emailTypeOptions, setEmailTypeOptions]:any = useState([
        {value: 0, label: 'PROPIO'},
        {value: 1, label: 'FAMILIAR'},
        {value: 3, label: 'VECINO'},
        {value: 4, label: 'OTRO'}
    ]);
    const [agentOptions, setAgentOptions]:any = useState([
        {value: 0, label: 'None'}
    ]);
    const [products, setProducts]:any = useState([
        {
            sedeName: "sede",
            gradeName:"grado",
            groupName:"Grupo",
            desc_solution: "No hay datos disponibles en este momento.",
            email:"prueba@prueba.com",
            email_type_id:1,
            observations: "No hay datos disponibles en este momento.",
            user_id: 0
        }
    ]);
    const [page, setPage]:any = useState(1);
    const [sizePerPage, setSizePerPage]:any = useState(10);
    const [totalSize, setTotalSize]:any = useState(products.length);
    const [hiddenColumns, setHiddenColumns]:any = useState([
        false, false, true, true, true, true, true, true, true, true, false
    ])

    let columns = [
        // {
        //     dataField: 'dateTime',
        //     text: 'Fecha y Hora',
        //     hidden: hiddenColumns[0],
        //     sort: true,
        // },
        {
            dataField: 'sedeName',
            text: 'Nombre Sede',
            hidden: hiddenColumns[0],
            sort: true,
        },
        {
            dataField: 'gradeName',
            text: 'Grado',
            hidden: hiddenColumns[1],
            sort: true,
        },
        {
            dataField: 'groupName',
            text: 'Grupo',
            hidden: hiddenColumns[2],
            sort: true,
        },
        {
            dataField: 'desc_solution',
            text: 'Solución de la inquietud',
            hidden: hiddenColumns[3],
            sort: true
        },
        {
            dataField: 'email',
            text: 'Email',
            hidden: hiddenColumns[4],
            sort: true
        },
        {
            dataField: 'email_type_id',
            text: 'Tipo Email',
            hidden: hiddenColumns[5],
            sort: true,
            formatter: (cell: number) => emailTypeOptions.find((opt:any) => opt.value === cell).label || '',
            filter: selectFilter({
                options: emailTypeOptions
            })
        },
        {
            dataField: 'observations',
            text: 'Observaciones',
            hidden: hiddenColumns[6]
        },
        {
            dataField: 'user_id',
            text: 'Coordinador',
            hidden: hiddenColumns[10],
            sort: true,
            formatter: (cell: number) => agentOptions.find((opt:any) => opt.value === cell).label || '',
            filter: selectFilter({
                options: agentOptions
            })
        }
    ];

    useEffect(() => { }, [])

    const formatWords = (arr: Array<any>, keyInObj: String) => {
        const toReturn: any = [];
        if(arr){
            arr.map((el: any) => {
                let word: string = "";
                el[`${keyInObj}`].split(" ").map((wr: any) => {
                  if(wr){
                    word += wr.toUpperCase() + " ";
                  }
                });
                word = word.trim();
                toReturn.push({ value: el.id, label: word });
              });
        }
       
        return toReturn;
    };

    const handleTableChange = (type:any, { page, sizePerPage, filters, sortField, sortOrder = 'asc' }:any) => {
        const { email_type_id, 
            user_id } = filters;


        const jsonPOST = { 
            email_type_id: email_type_id ? email_type_id.filterVal : false,
            user_id: user_id ? user_id.filterVal : false,
            page, 
            quantPerPage: sizePerPage,
            // columnOrder: sortField || 'dateTime', 
            orderType: sortOrder || 'asc'
        }

        POST_AXIOS('callcenter/filterAndSort', jsonPOST).then(res => {
            const data = res.data.data.map((el:any) => {             
                el.sedeName = el.sedeName.toUpperCase();
                el.gradeName = el.gradeName.toUpperCase();
                el.groupName = el.groupName.toUpperCase();
                el.desc_solution = el.desc_solution && el.desc_solution.charAt(0).toUpperCase() + el.desc_solution.slice(1);
                el.observations = el.observations && el.observations.charAt(0).toUpperCase() + el.observations.slice(1);
                if (!el.email_type_id) el.email_type_id = 5;
                return el;
            });
            setProducts(data);
            setPage(page);
            setTotalSize(res.data.total);
            setSizePerPage(sizePerPage);
        })
    }

    const handleSeeColumns = (field:any) => {
        const indexInColumns = columns.findIndex((el:any) => el.dataField == field)
        const hiddenCols = hiddenColumns;
        let currentHidden = hiddenCols[indexInColumns];
        hiddenCols[indexInColumns] = !currentHidden;
        setHiddenColumns([...hiddenCols]);
    }

    const CustomToggleList = ({
        columns,
        onColumnToggle,
        toggles
    }:any) => (
        <div className="list-altic mb-4 mt-2 btn-group btn-group-toggle" data-toggle="buttons">
            {
            columns
                .map((column:any) => ({
                ...column,
                toggle: toggles[column.dataField]
                }))
                .map((column:any) => (
                <button
                    type="button"
                    key={ column.dataField }
                    className={ `list-btn-custom-class btn btn-success ${column.toggle ? 'active' : ''}` }
                    data-toggle="button"
                    aria-pressed={ column.toggle ? 'true' : 'false' }
                    onClick={ () => handleSeeColumns(column.dataField) }
                >
                    { column.text }
                </button>
                ))
            }
        </div>
    );

    return <div >

<div className="mt-5" style={{ position: "absolute", top: "82px", }}>
            <Link
                className="af-buttonHome d-flex justify-content-center p-2"
                to="/chooseContent"
            >
                <TiHomeOutline />
            </Link>
        </div>

        <div className='container-fluid px-4'>
        <Row>
            <Col
                className="justify-content-center align-self-center text-center py-3"
                md={4}
            >
                <div className="af-lineOrange"></div>
            </Col>
            <Col
                className="justify-content-center align-self-center text-center pt-5"
                md={4}
            >
                <h3 className="af-titleOrange">
                    <strong>Reporte Coordinadores</strong>
                </h3>
            </Col>
            <Col
                className="justify-content-center align-self-center text-center py-3"
                md={4}
            >
                <div className="af-lineOrange"></div>
            </Col>
        </Row>
    
        {/* <BootstrapTable keyField='id' data={products} columns={columns} filter={filterFactory()} /> */}
        <ToolkitProvider
            keyField="id"
            data={products}
            columns={columns}
            // @ts-ignore: Unreachable code error
            columnToggle
        >
            {
                props => (
                    <div>
                        <span className="mt-2">A continuación, podrás visualizar los reportes generados por los coordinadores de municipio.
                        </span><br></br><span>Selecciona las columnas que desea consultar: </span>
                        {/* <ToggleList
                            contextual="success"
                            className="list-altic mb-4 mt-2"
                            btnClassName="list-btn-custom-class"
                            {...props.columnToggleProps}
                            onColumnToggle={() => handleSeeColumns(props.columnToggleProps)}
                        /> */}
                        <CustomToggleList contextual="success" { ...props.columnToggleProps } />
                        <hr />
                        <BootstrapTable
                            remote
                            {...props.baseProps}
                            filter={filterFactory()}
                            // @ts-ignore: Unreachable code error
                            noDataIndication="No hay Datos Aún"
                            classes="table-altic"
                            pagination={paginationFactory({ page, sizePerPage, totalSize })}
                            onTableChange={handleTableChange}
                        />
                    </div>
                )
            }
        </ToolkitProvider>
        </div>
    </div >
}