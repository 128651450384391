import React from "react";
import "./selectReport.scss";

//import componentes y vistas
// import { NavBar } from "../../components/navBar/navBar";
import FootPages from "../../components/footPage/footPage";

//import elmentos de react
import { Row, Col, Button, Image } from "react-bootstrap";
import { RouteProps } from "react-router";
import { Link } from "react-router-dom";
import { TiHomeOutline } from "react-icons/ti";

// import axios from 'axios';

//import imagenes
import decorationBacgroundUpper from "../../img/BackgroundUpperReports.png";
import decorationBacgroundDown from "../../img/decorationDownContent.png";
import chooseContentReportes from "../../img/chooseContentReportes.png";
// import { stringify } from "querystring";

export interface Props {
  history: any;
  rol: any;
}

export default class SelecReport extends React.Component<
  Props,
  { textStart: any; name: any }
> {

  constructor(props: any) {
    super(props);
    this.state = {
      textStart: "",
      name: "",
    };
  }

  componentWillMount() {
    // compare if user's role is secretaria, docente or Rect
    // if it's none, redirect to loginPage
    console.log(this.props.rol);
    if (
      this.props.rol === "rec" ||
      this.props.rol === "sec_ed" ||
      this.props.rol === "dir_ge" ||
      this.props.rol === "inter_aco"
    ) {
    } else {
      this.props.history.push("/chooseContent");
    }
  }

  componentDidMount() {
    this.showStartText(this.props.rol);
  }

  //Muestra el texto de Bienvenida Dependiendo del rol del usuario
  showStartText(rol: any) {
    rol = this.props.rol;
    let newText;
    let newName;
    switch (rol) {
      case "inter_aco":
        newText =
          "A continuación encontrarás los Reportes de docentes y reportes de estudiantes";
        newName = "INTEGRANTE DEL EQUIPO DE ACOMPAÑAMIENTO Y MONITOREO";
        break;
      case "coor_m":
        newText =
          "A continuación, tendrás acceso a los contenidos y recursos para la enseñanza y aprendizaje de las áreas básicas, disponibles en la plataforma.  \n Adicionalmente, encontrarás disponible el formato para el registro de las acciones implementadas que gestionarás y desarrollarás, en el marco de la atención educativa a los docentes responsables del proceso formativo y los estudiantes jóvenes extraedad y adultos beneficiarios, del acompañamiento a la implementación del Modelo Educativo Flexible Talentum, en el Valle del Cauca. \n Seleccione la sección a la que vas a acceder:";
        newName = "COORDINADOR(A) DE MUNICIPIO";
        break;
      case "sec_ed":
        newText =
          "A continuación encontrarás los Reportes de docentes y reportes de estudiantes";
        newName =
          "DELEGADO(A) DE LA SECRETARÍA DE EDUCACIÓN DEPARTAMENTAL";
        break;
      case "dir_ge":
        newText = "A continuación encontrarás los Reportes de docentes y reportes de estudiantes";;
        newName = "DIRECTOR(A) GENERAL";
        break;
      case "pta":
        newText = "Cambiar Texto!";
        newName = "Programa Todos Aprender";
        break;
      case "rec":
        newText = "A continuación encontrará los reportes de docentes y de estudiantes de su institución educativa en relación con el acceso y uso de la plataforma. Seleccione el reporte al que va a acceder.";
        newName = "RECTOR(A)";
        break;
      default:
        newText = `A continuación encontrarás contenidos y/o recursos para el aprendizaje, organizados en las áreas básicas.\nPara ingresar a los contenidos y/o recursos, selecciona el área a la que vas a acceder:`;
        newName = "Bienvenido usuario";
    }
    this.setState({ textStart: newText, name: newName });
  }

  render() {
    const { textStart, name } = this.state;

    return (
      <div>
        {/* <NavBar
          url="/chooseContent"
          name="Perfil"
          activeKey="4"
          auth={true}
        ></NavBar> */}

        <div className="principal-page-report">

          <div className="mt-2">
            <Image className="af-imageUpper" src={decorationBacgroundUpper} />
            <Link
              className="af-buttonHomeIntroduction d-flex justify-content-center p-2"
              style={{ zIndex: 5 }}
              to="/chooseContent"
            >
              <TiHomeOutline />
            </Link>
          </div>

          <Row className="w-100 af-divIntro m-0">
            <Col className="m-0" md={12} >
              <Row className="justify-content-center align-self-center text-center" style={{ marginTop: "-10px" }}>
                <Col className="pt-5" md={9}>
                  <h2 className="af-tittleChooseConten" >
                    <strong>ESTIMADO(A) {name}</strong>
                  </h2>
                  {textStart.split("\n").map((item: any, i: any) => {
                    return <p key={i}>{item}</p>;
                  })}
                  {/* <p>{textStart}</p> */}
                </Col>
              </Row>
              <Row className="w-100 m-0 justify-content-center align-self-center text-center pb-3">
                <Col className="" md={4}>
                  <div className="af-lineOrangechoose"></div>
                </Col>
              </Row>

              <Row className="justify-content-center align-self-center text-center pt-3">
                <Col md={10} lg={8}>
                  <Row className="justify-content-center align-self-center text-center mb-5">
                    <Col className="p-2" md={3} xs={12}>
                      <div>
                        <Link to="/reportteacher">
                          <Button className="af-chooseAButton">
                            <Image
                              className="w-50"
                              src={chooseContentReportes}
                            />
                            <p className="m-0">Reporte Docente</p>
                          </Button>
                        </Link>
                      </div>
                    </Col>
                    <Col className="p-2" md={3} xs={12}>
                      <div>
                        <Link to="/report">
                          <Button className="af-chooseAButton">
                            <Image
                              className="w-50"
                              src={chooseContentReportes}
                            />
                            <p className="m-0">Reporte Estudiantes</p>
                          </Button>
                        </Link>

                      </div>
                    </Col>
                  </Row>
                  <Col className="af-buttonList" md={12}></Col>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <Image className="af-backImageDown" src={decorationBacgroundDown} />
        <FootPages></FootPages>
      </div>
    );
  }
}
