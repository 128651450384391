import React from "react";
import "../report.scss";

//impport elementos de react
import { Container, Row, Col } from "react-bootstrap";
import { POST_AXIOS, GET_AXIOS  } from "./../../funtionsREST";

//import components
import Title from "../../AdminUsers/Title/Title";
import DBA from "../../DBAReport/DBAReport";

//import imagenes
import IconInteractive from "../../../img/icon-interactive.svg";
import IconVideo from "../../../img/icon-video.svg";
import IconMen from "../../../img/icon-Men.svg";
import IconVirtual from "../../../img/icon-virtual.svg";

//import nuevos iconos para Guías Docente y Actividades Imprimibles
import GuideDocIcon from "../../../img/iconSvg/logoListaDocente.svg"; // Icono Guías Docente
import PrintableActIcon from "../../../img/iconSvg/logoListaAImprimibles.svg"; // Icono Actividades Imprimibles

//import components and views
import LoadingPage from "../../spinner/loadingPage";

export interface Props {
  graphics: Array<string>;
  area: number;
}

interface IState {
  grado: string;
  grupo: string;
  area: string;
  showReportButton: boolean;
  showDiseableReportButton: boolean;
  showReport: boolean;
  showReportError: boolean;
  reports: Array<report>;
}

interface report {
  done: number;
  total: string;
  dbas: Array<object>;
}

export default class ReportStudent extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    // No llames this.setState() aquí!
    this.state = {
      grado: "Escoge el grado",
      grupo: "Escoge el grupo",
      area: "Escoge el área",
      showReport: false,
      showReportError: false,
      showDiseableReportButton: false,
      showReportButton: false,
      reports: [],
    };

    this.GenerateReport = this.GenerateReport.bind(this);
  }

  componentWillMount() {
    POST_AXIOS(`stats/user`, { knowledge_area: this.props.area, grade: 0 })
      .then((res) => {
        console.log(res.data, this.props.area);
        if (
          res.data !== undefined &&
          res.data !== false &&
          res.data !== "no stats"
        ) {
          this.setState({ reports: res.data }, () => {
            this.setState({
              showReport: true,
            });
          });
        } else {
          this.setState({
            showReportError: true,
          });
        }
      })
      .catch((e) => {
        console.log(e);
        this.setState({
          showReport: false,
          showReportError: true,
        });
      });
  }

  GenerateReport(event: React.FormEvent<HTMLFormElement>) {
    console.log("hola");

    event.preventDefault();
    if (
      this.state.grado !== "Escoge el grado" &&
      this.state.grupo !== "Escoge el grupo" &&
      this.state.area !== "Escoge el área"
    ) {
      this.setState({ showReport: true });
    }
  }

  render() {
    // Filtramos por el ID correspondiente a cada tipo de recurso
    const byVideo = this.state.reports.filter((data: any) => data.id === 1)[0]; // Videos
    const byAI = this.state.reports.filter((data: any) => data.id === 2)[0]; // Actividades interactivas
    const byMen = this.state.reports.filter((data: any) => data.id === 3)[0]; // Recursos MEN
    const byAu = this.state.reports.filter((data: any) => data.id === 4)[0]; // Aula virtual
    const byGuideDoc = this.state.reports.filter((data: any) => data.id === 7)[0]; // Guías Docente
    const byPrintable = this.state.reports.filter((data: any) => data.id === 8)[0]; // Actividades Imprimibles

    return (
      <>
        <Row className="justify-content-center align-self-center text-center">
          {this.state.showReport && (
            <LoadingPage>
              <Container fluid>
                <Row>
                  <Col>
                    <Title>Informe</Title>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p>
                      En el siguiente informe encontrarás una descripción de tu
                      estado y progreso en relación con el acceso y uso de los
                      contenidos y/o recursos propios de esta área.
                    </p>
                  </Col>
                </Row>
                <Container fluid>
                  {/* Sección de Videos */}
                  <Row className="mt-2 mb-2">
                    <Col className="d-flex subtitle-report">
                      <img
                        src={IconVideo}
                        alt=""
                        className="af-iconReport-student mr-2"
                      />
                      <h4 className="mt-2 ml-1">
                        <strong>Videos:</strong>
                      </h4>
                    </Col>
                  </Row>
                  {byVideo ? (
                    byVideo.dbas.map((value: any) => (
                      <DBA
                        atribute="videos"
                        dba={value.dba_name}
                        total={value.total}
                        student={value.score}
                        type={0}
                      />
                    ))
                  ) : (
                    ""
                  )}

                  {/* Sección de Actividades interactivas */}
                  <Row className="mt-4 mb-2">
                    <Col className="d-flex subtitle-report">
                      <img
                        src={IconInteractive}
                        alt=""
                        className="icon-report"
                      />
                      <h4 className="mt-2 ml-1">
                        <strong>Actividades interactivas:</strong>
                      </h4>
                    </Col>
                  </Row>
                  {byAI ? (
                    byAI.dbas.map((value: any) => (
                      <DBA
                        atribute="actividades"
                        dba={value.dba_name}
                        total={value.total}
                        student={value.score}
                        type={0}
                      />
                    ))
                  ) : (
                    ""
                  )}

                  {/* Sección de Recursos MEN */}
                  <Row className="mt-4 mb-2">
                    <Col className="d-flex subtitle-report">
                      <img
                        src={IconMen}
                        alt=""
                        className="af-iconReport-student"
                      />
                      <h4 className="mt-2 ml-1">
                        <strong>Recursos MEN:</strong>
                      </h4>
                    </Col>
                  </Row>
                  {byMen ? (
                    byMen.dbas.map((value: any) => (
                      <DBA
                        atribute="recursos"
                        dba={value.dba_name}
                        total={value.total}
                        student={value.score}
                        type={0}
                      />
                    ))
                  ) : (
                    ""
                  )}

                  {/* Nueva Sección para Guías Docente */}
                  <Row className="mt-4 mb-2">
                    <Col className="d-flex subtitle-report">
                      <img
                        src={GuideDocIcon}
                        alt=""
                        className="af-iconReport-student"
                      />
                      <h4 className="mt-2 ml-1">
                        <strong>Guías Docente:</strong>
                      </h4>
                    </Col>
                  </Row>
                  {byGuideDoc ? (
                    byGuideDoc.dbas.map((value: any) => (
                      <DBA
                        atribute="docente"
                        dba={value.dba_name}
                        total={value.total}
                        student={value.score}
                        type={0}
                      />
                    ))
                  ) : (
                    ""
                  )}

                  {/* Nueva Sección para Actividades Imprimibles */}
                  <Row className="mt-4 mb-4">
                    <Col className="d-flex subtitle-report">
                      <img
                        src={PrintableActIcon}
                        alt=""
                        className="af-iconReport-student"
                      />
                      <h4 className="mt-3 ml-1">
                        <strong>Actividades Imprimibles:</strong>
                      </h4>
                    </Col>
                  </Row>
                  {byPrintable ? (
                    byPrintable.dbas.map((value: any) => (
                      <DBA
                        atribute="imprimibles"
                        dba={value.dba_name}
                        total={value.total}
                        student={value.score}
                        type={0}
                      />
                    ))
                  ) : (
                    ""
                  )}

                  {/* Sección de Aula virtual */}
                  <Row className="mt-4 mb-4">
                    <Col className="d-flex subtitle-report">
                      <img
                        src={IconVirtual}
                        alt=""
                        className="af-iconReport-student"
                      />
                      <h4 className="mt-3 ml-1">
                        <strong>Aula virtual:</strong>
                      </h4>
                    </Col>
                  </Row>
                </Container>
              </Container>
            </LoadingPage>
          )}
          {this.state.showReportError && (
            <Container className="mt-4 d-flex justify-content-center flex-column p-5 mt-2" fluid>
              <Row>
                <Col className="" md={12}>
                  <h4>No hay reporte disponibles en este momento.</h4>
                </Col>
              </Row>
            </Container>
          )}
          <br />
        </Row>
      </>
    );
  }
}
