import React from "react";
import { usePromiseTracker } from 'react-promise-tracker'

const  LoadingPage = ( props : any) => {
  const { promiseInProgress } = usePromiseTracker()

  return (
    <React.Fragment>
    {promiseInProgress ?
    <div className="h-100 w-100 position-absolute d-flex justify-content-center align-items-center">
      <div className="spinner-grow  text-secondary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
      <div className="spinner-grow  text-secondary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
      <div className="spinner-grow  text-secondary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
    :
    {...props.children}
  }
    </React.Fragment>
  );
}

export default LoadingPage;
