import React from 'react';
import './resources.scss';

//impport elementos de react
import { Row, Col, Pagination } from 'react-bootstrap';

import CICLO2_CIUDADANO from '../../img/materiales/PORTADA_CICLO_2_CIUDADANO.jpg';
import CICLO3_CIUDADANO from '../../img/materiales/PORTADA_CICLO_3_CIUDADANO.jpg';
import CICLO4_CIUDADANO from '../../img/materiales/PORTADA_CICLO_4_CIUDADANO.jpg';
import CICLO5_CIUDADANO from '../../img/materiales/PORTADA_CICLO_5_CIUDADANO.jpg';
import CICLO6_CIUDADANO from '../../img/materiales/PORTADA_CICLO_6_CIUDADANO.jpg';

import CICLO2_ECOSISTEMICO from '../../img/materiales/PORTADA_CICLO_2_ECOSISTEMICO.jpg';
import CICLO3_ECOSISTEMICO from '../../img/materiales/PORTADA_CICLO_3_ECOSISTÉMICO.jpg';
import CICLO4_ECOSISTEMICO from '../../img/materiales/PORTADA_CICLO_4_ECOSISTÉMICO.jpg';
import CICLO5_ECOSISTEMICO from '../../img/materiales/PORTADA_CICLO_5_ECOSISTEMICO.jpg';
import CICLO6_ECOSISTEMICO from '../../img/materiales/PORTADA_CICLO_6_ECOSISTEMICO.jpg';

import CICLO2_EMPRENDIMIENTO from '../../img/materiales/PORTADA_CICLO_2_EMPRENDIMIENTO.jpg';
import CICLO3_EMPRENDIMIENTO from '../../img/materiales/PORTADA_CICLO_3_EMPRENDIMIENTO.jpg';
import CICLO4_EMPRENDIMIENTO from '../../img/materiales/PORTADA_CICLO_4_EMPRENDIMIENTO.jpg';
import CICLO5_EMPRENDIMIENTO from '../../img/materiales/PORTADA_CICLO_5_EMPRENDIMIENTO.jpg';
import CICLO6_EMPRENDIMIENTO from '../../img/materiales/PORTADA_CICLO_6_EMPRENDIMIENTO.jpg';

import CICLO2_GENERO from '../../img/materiales/CICLO_2_GENERO_DIGITAL_2022.jpg';
import CICLO3_GENERO from '../../img/materiales/CICLO_3_GÉNERO_DIGITAL_2022.jpg';
import CICLO4_GENERO from '../../img/materiales/CICLO_4_GENERO_DIGITAL.jpg';
import CICLO5_GENERO from '../../img/materiales/CICLO_5_GENERO_DIGITAL.jpg';
import CICLO6_GENERO from '../../img/materiales/CICLO_6_GENERO_DIGITAL_2022.jpg';

import CICLO2_PAZ from '../../img/materiales/CICLO_2_PAZ_Y_COMUNIDAD_DIGITAL_DIGITAL_2022.jpg';
import CICLO3_PAZ from '../../img/materiales/CICLO_3_PAZ_Y_COMUNIDAD_DIGITAL_2022.jpg';
import CICLO4_PAZ from '../../img/materiales/CICLO_4_PAZ_Y_COMUNIDAD_DIGITAL_2022.jpg';
import CICLO5_PAZ from '../../img/materiales/CICLO_5_PAZ_Y_COMUNIDAD_DIGITAL_2022.jpg';
import CICLO6_PAZ from '../../img/materiales/CICLO_6_PAZ_Y_COMUNIDAD_DIGITAL_2022.jpg';

import CICLO2_PROSPERIDAD from '../../img/materiales/CICLO_2_PROSPERIDAD_DIGITAL_2022.jpg';
import CICLO3_PROSPERIDAD from '../../img/materiales/CICLO_3_PROSPERIDAD_DIGITAL_2022.jpg';
import CICLO4_PROSPERIDAD from '../../img/materiales/CICLO_4_PROSPERIDAD_DIGITAL_2022.jpg';
import CICLO5_PROSPERIDAD from '../../img/materiales/CICLO_5_PROSPERIDAD_DIGITAL_2022.jpg';
import CICLO6_PROSPERIDAD from '../../img/materiales/CICLO_6_PROSPERIDAD_DIGITAL_2022.jpg';

import CICLO2_TECNOLOGICO from '../../img/materiales/PORTADA_CICLO_2_TECNOLÓGICO.jpg';
import CICLO3_TECNOLOGICO from '../../img/materiales/PORTADA_CICLO_3_TECNOLÓGICO.jpg';
import CICLO4_TECNOLOGICO from '../../img/materiales/PORTADA_CICLO_4_TECNOLÓGICO.jpg';
import CICLO5_TECNOLOGICO from '../../img/materiales/PORTADA_CICLO_5_TECNOLÓGICO.jpg';
import CICLO6_TECNOLOGICO from '../../img/materiales/PORTADA_CICLO_6_TECNOLÓGICO.jpg';

//import comoponentes y vistas
import { POST_AXIOS } from "../funtionsREST";
import SubComponent2 from '../subComponent/subComponent2';

let CIUDADANO: any;
let ECOSISTEMICO: any;
let EMPRENDIMIENTO: any;
let GENERO: any;
let PAZ: any;
let PROSPERIDAD: any;
let TECNOLOGICO: any;

export interface Props { Arrayresources: any, grade: any, subject: any }


export default class Models extends React.Component<Props, { desc: string, resources: any, imagResourcesVideo: any, currentPage: any, elementPage: any, zoomVideo: boolean, grade: string }> {



	constructor(props: any) {


		super(props);

		switch (this.props.grade) {
			case "Ciclo 2":
				CIUDADANO = CICLO2_CIUDADANO;
				ECOSISTEMICO = CICLO2_ECOSISTEMICO;
				EMPRENDIMIENTO = CICLO2_EMPRENDIMIENTO;
				GENERO = CICLO2_GENERO;
				PAZ = CICLO2_PAZ;
				PROSPERIDAD = CICLO2_PROSPERIDAD;
				TECNOLOGICO = CICLO2_TECNOLOGICO;

				break;
			case "Ciclo 3":
				CIUDADANO = CICLO3_CIUDADANO;
				ECOSISTEMICO = CICLO3_ECOSISTEMICO;
				EMPRENDIMIENTO = CICLO3_EMPRENDIMIENTO;
				GENERO = CICLO3_GENERO;
				PAZ = CICLO3_PAZ;
				PROSPERIDAD = CICLO3_PROSPERIDAD;
				TECNOLOGICO = CICLO3_TECNOLOGICO;

				break;
			case "Ciclo 4":
				CIUDADANO = CICLO4_CIUDADANO;
				ECOSISTEMICO = CICLO4_ECOSISTEMICO;
				EMPRENDIMIENTO = CICLO4_EMPRENDIMIENTO;
				GENERO = CICLO4_GENERO;
				PAZ = CICLO4_PAZ;
				PROSPERIDAD = CICLO4_PROSPERIDAD;
				TECNOLOGICO = CICLO4_TECNOLOGICO;

				break;
			case "Ciclo 5":
				CIUDADANO = CICLO5_CIUDADANO;
				ECOSISTEMICO = CICLO5_ECOSISTEMICO;
				EMPRENDIMIENTO = CICLO5_EMPRENDIMIENTO;
				GENERO = CICLO5_GENERO;
				PAZ = CICLO5_PAZ;
				PROSPERIDAD = CICLO5_PROSPERIDAD;
				TECNOLOGICO = CICLO5_TECNOLOGICO;

				break;
			case "Ciclo 6":
				CIUDADANO = CICLO6_CIUDADANO;
				ECOSISTEMICO = CICLO6_ECOSISTEMICO;
				EMPRENDIMIENTO = CICLO6_EMPRENDIMIENTO;
				GENERO = CICLO6_GENERO;
				PAZ = CICLO6_PAZ;
				PROSPERIDAD = CICLO6_PROSPERIDAD;
				TECNOLOGICO = CICLO6_TECNOLOGICO;
				break;

			default:
				console.log("grade??: ", this.props.grade);

				break;
		}

		this.state = {
			resources: this.props.Arrayresources,
			imagResourcesVideo: [CIUDADANO, ECOSISTEMICO, EMPRENDIMIENTO, GENERO, PAZ, PROSPERIDAD, TECNOLOGICO],
			currentPage: 1,
			elementPage: 7,
			zoomVideo: false,
			desc: '',
			grade: this.props.grade,

		};

		this.handleClick = this.handleClick.bind(this);

	}
	componentDidMount() {
		console.log('entre  aa component Didmount')
		console.log(this.props.Arrayresources)
	}

	handleClick(event: any) {
		event.preventDefault();
		this.setState({ currentPage: Number(event.target.id) })
	}

	//funcion para post de conteo de progreso para video

	postProgressResource(resource_id: any) {
		let dataResource = {
			resource_id: resource_id,
		};
		POST_AXIOS("users/newResource", dataResource)
			.then((res) => {
				console.log(res);
			})
			.catch((err) => {
				console.log(err);
			});
	}

	render() {
		const { resources, currentPage, elementPage, imagResourcesVideo, grade } = this.state;

		//-----------------------------Paginación-----------------------------------
		const indexOfLastTodo = currentPage * elementPage;
		const indexOfFirstTodo = indexOfLastTodo - elementPage;
		const currentResources = resources.slice(indexOfFirstTodo, indexOfLastTodo);

		let active = currentPage;
		let numberPages = Math.ceil(this.state.resources.length / this.state.elementPage);
		let items = [];
		for (let number = 1; number <= numberPages; number++) {
			items.push(
				<Pagination.Item key={number} id={number} active={number === active} onClick={this.handleClick}>
					{number}
				</Pagination.Item>,
			);
		}

		const paginationBasic = (
			<div> <Pagination>{items}</Pagination><br /></div>
		);
		//---------------------------------------------------------------------------------
		return (
			<div >
				<Row className="w-100 m-0 h-100 pt-5">
					<Col className="justify-content-center align-self-center text-center p-3" md={4}>
						<div className="af-lineOrange"></div>
					</Col>
					<Col md={4}>
						<h2 className="af-tittleLesson">Materiales Educativos</h2>
					</Col>

					<Col className="justify-content-center align-self-center text-center p-3" md={4}>
						<div className="af-lineOrange"></div>
					</Col>
				</Row>
				<Row>
					<p className="af-textComponent p-4">DESAFÍOS PARA APRENDER. Material educativo que busca movilizar aprendizajes en contextos reales y cotidianos para los estudiantes, generar acciones pedagógicas pertinentes y con sentido, y consolidarse en una oportunidad para el aprendizaje de habilidades, el desarrollo de las capacidades y el mejoramiento de las actitudes de la población de jóvenes y adultos beneficiarios del Modelo.</p>

					{currentResources.map((resour: any, i: any) => {
						let indexImg = i >= 7 ? i - 7 : i;
						return (
							<Col key={i} md={4}>
								{/* se adapta dependiendo del JSON de la base de datos para: url:resource_url, titulo:resource_name, desc:dba_name para src:src */}
								<div onClick={() => this.postProgressResource(resour.id)}>
									<SubComponent2 tittle={`Cartilla ${(i + 1) + indexOfFirstTodo}.`} url={resour.resource_url} src={imagResourcesVideo[indexImg]} desc={resour.resource_name}
									></SubComponent2>
								</div>

							</Col>
						)
					})}
					{resources.length > 6 &&
						< Row className="w-100 justify-content-center align-self-center text-center">
							{paginationBasic}
						</Row>
					}
				</Row>


			</div >
		)
	}
}